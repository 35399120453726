import React, { useContext, useEffect, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import CircleLoader from '../UI/CircleLoader'
import { Col } from 'react-bootstrap'
import AccessContext from '../../context/AccessContext'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import { RulesById } from '../../axios/apis'

const ViewRuleModal = ({ open, onClose, title, btn, data }) => {

    const [loader, setLoader] = useState(false)
    const { orgzName } = useContext(AccessContext)

    const [ruleName, setRuleName] = useState("")
    const [departList, setDepartList] = useState([])
    const [userList, setUserList] = useState([])
    const [appliedDepart, setAppliedDepart] = useState([])
    const [appliedUser, setAppliedUser] = useState([])

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);

    const fetchRuleDetail = async () => {
        setLoader(true)
        try {
            const resRule = await RulesById(data.ruleSetId)
            // const response = await RulesList(orgzName.organizationName)
            // if (resRule.status === 200) {
            //     const isMatchRule = response.data.ruleSets.some((e) => e.ruleSetId === singleChart.departmentRuleSetId)
            //     setRuleName(isMatchRule ? singleChart.departmentRuleSetName : "Custom")
            // }
            setDepartList(resRule.data.departmentList)
            setUserList(resRule.data.userList)
            setAppliedDepart(resRule.data.ruleAppliedOn.departmentList)
            setAppliedUser(resRule.data.ruleAppliedOn.userList)
            setLoader(false)
        } catch (error) {
            unexpectedErrorNotification(error.response.data || "Unable to Load Details", addNotifications);
            // console.log(error)
        }
    }

    useEffect(() => {
        fetchRuleDetail()
    }, [])


    return (
        <PopupLayout open={open} onClose={onClose} title={title}>
            {
                loader ? <CircleLoader /> : <>

                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">Rule Name</label>
                            <div className="input-field">
                                <input value={data.ruleSetName} type="text" name="userName" readOnly />
                            </div>
                        </div>
                    </Col>
                    <div>
                        <h5 className='view-rule-head'>Rule Information</h5>
                        <Col lg={12}>
                            <div className="input-box">
                                <label className='input-label' htmlFor="">Group List</label>
                                <div className="show-field">
                                    {
                                        data.ruleSetName !== "Default" ? departList.map((el) => (
                                            <span>{el.departmentName}</span>
                                        )) : <span>All</span>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="input-box">
                                <label className='input-label' htmlFor="">User List</label>
                                <div className="show-field">
                                    {
                                        data.ruleSetName !== "Default" ? userList.map((el) => (
                                            <span>{el.userName}</span>
                                        )) : <span>All</span>
                                    }
                                </div>
                            </div>
                        </Col>
                    </div>
                    <div>
                        <h5 className='view-rule-head'>Rule Applied</h5>
                        <Col lg={12}>
                            <div className="input-box">
                                <label className='input-label' htmlFor="">Group List</label>
                                <div className="show-field">
                                    {
                                        appliedDepart.length !== 0 ? appliedDepart.map((el) => (
                                            <span>{el.departmentName}</span>
                                        )) : <>
                                            No Group
                                        </>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="input-box">
                                <label className='input-label' htmlFor="">User List</label>
                                <div className="show-field">
                                    {
                                        appliedUser.length !== 0 ? appliedUser.map((el) => (
                                            <span>{el.userName}</span>
                                        )) : <>
                                            No User
                                        </>
                                    }
                                </div>
                            </div>
                        </Col>
                    </div>

                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={onClose}>Close</button>
                    </div>
                </>
            }
        </PopupLayout>
    )
}

export default ViewRuleModal