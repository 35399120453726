import React, { createContext, useState } from "react";

const MessageContext = createContext();

const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState({});
  const [accepted, setAccepted] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [rejectType, setRejectType] = useState(null);
  const [incomingMessage, setIncomingMessage] = useState(false);
  const [isCallingType, setIsCallingType] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [currentConvo, setCurrentConvo] = useState(null);
  const [soundOn, setSoundOn] = useState(true);
  const [callSoundOn, setCallSoundOn] =useState(true);

  return (
    <MessageContext.Provider
      value={{
        message,
        setMessage,
        accepted,
        setAccepted,
        menuOpen,
        setMenuOpen,
        rejected,
        setRejected,
        rejectType,
        setRejectType,
        incomingMessage,
        setIncomingMessage,
        isRinging,
        setIsRinging,
        isCalling,
        setIsCalling,
        isCallingType,
        setIsCallingType,
        currentConvo,
        setCurrentConvo,
        soundOn,
        setSoundOn,
        callSoundOn,
        setCallSoundOn,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export { MessageContext, MessageProvider };
