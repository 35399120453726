import React, { useContext, useEffect, useState } from 'react'
import AppReadyContext from '../../context/AppReadyContext';
import { generateQueryParams } from '../../utils/helperPages';
import { RulesList, TotalOrgChart } from '../../axios/apis';
import UserAccessCheck from '../userAccess/userAccess';
import PageHeader from '../UI/PageHeader';
import Select from "react-select";
import ThemeBtn from '../UI/ThemeBtn';
import { Paper, TableContainer } from '@mui/material';
import Loader from '../UI/Loader';
import RulesTable from './RulesTable/RulesTable';
import AddRuleSet from './AddRuleSet';
import AccessContext from '../../context/AccessContext';
import CircleLoader from '../UI/CircleLoader';
import ReportContext from '../../context/ReportContext';

const RulesManagement = () => {

    const { setReportType } = useContext(ReportContext);
    setReportType("adminIcon");

    const { appReady } = useContext(AppReadyContext);
    const { setOrgzName, setSelectedDepartments, setSelectedUsers } = useContext(AccessContext);
    const [userRole, setUserRole] = useState(localStorage.getItem("user-role"))

    const [isAdd, setIsAdd] = useState(false)
    const [pageloader, setPageLoader] = useState(false)
    const [loading, setLoading] = useState(false);
    const [isReset, setIsReset] = useState(false)

    const [orgNameList, setOrgNameList] = useState([])

    const [rulesData, setRulesData] = useState([])
    const [isAddActive, setIsddActive] = useState(false)

    const [selectedOrg, setSelectedOrg] = useState({
        name: "",
        id: ""
    })
    const [debouncedOrg, setDebouncedOrg] = useState([])

    const [sortBy, setSortBy] = useState(""); // Column to sort by
    const [sortOrder, setSortOrder] = useState("desc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);

    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        const params = generateQueryParams(paramsObject)

        rulesCall(params)
    }


    const clearFilters = () => {
        setSelectedOrg({ name: "", id: "" });
        setRulesData([])
        setSelectedDepartments([])
        setSelectedUsers([])
    }

    const handleSearch = () => {
        setLoading(true)
        rulesCall()
    }


    const fetchRules = async (pageNumber, pageSize) => {
        setLoading(true)
        const paramsObject = {
            PageNumber: pageNumber,
            PageSize: pageSize,
            // sortingColumn: sortBy,
            // sortingOrder: sortOrder,
        }
        const params = generateQueryParams(paramsObject)
        rulesCall(params)
    }


    const rulesCall = async (params) => {
        try {
            if (userRole === "Customer Admin") {
                const response = await RulesList(params)
                setRulesData(response.data.ruleSets)
                setIsAdd(true)
            } else {
                const response = await RulesList(selectedOrg.name)
                setRulesData(response.data.ruleSets)
            }
            setIsAdd(true)
            setIsReset(false)
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    const fetchOrg = async () => {
        setOrgzName("")
        setSelectedDepartments([])
        setSelectedUsers([])
        setPageLoader(false)
        try {
            const response = await TotalOrgChart()
            setOrgNameList(response.data.completeOrgChart)
            if (userRole === "Customer Admin") {
                setOrgzName(response.data.completeOrgChart[0])
                setSelectedOrg({ name: response?.data?.completeOrgChart[0]?.organizationName, id: response?.data?.completeOrgChart[0]?.organizationId });
                rulesCall(response?.data?.completeOrgChart[0]?.organizationName)
                setPageLoader(true)
            } else {
                setPageLoader(true)
            }
        } catch (error) {
            // console.log(error)
        }
    }


    useEffect(() => {
        fetchOrg()
    }, [appReady])

    const handleChange = (e) => {
        if (e === null) {
            setSelectedOrg({ name: "", id: "" });
            setRulesData([])
        } else {
            setSelectedOrg({ name: e.label, id: e.value });
            setOrgzName(orgNameList.find((item) => item.organizationId === e.value))
            // const sample = options.find((item) => item.organizationId === e.value).orgChart
            // setOrgDetails(sample)
        }
        setIsAdd(false)
    }

    const labelConvert = (list) => {
        let arr = []
        list.map((item) => {
            arr.push({ label: item.organizationName, value: item.organizationId })
        })
        return arr
    }

    const handleAdd = () => {
        setSelectedDepartments([])
        setSelectedUsers([])
        setIsddActive(true)
    }


    return (
        <>
            {/* <UserAccessCheck pageName={"Admin"} subPage={"Rules Management"} /> */}
            {
                appReady && <>
                    {
                        !pageloader ? <CircleLoader full={true} /> : <>

                            <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                                <PageHeader name={"Admin"} subName={"Rules Management"} />

                                <div className="summary-filters align-items-center justify-content-end">
                                    {
                                        userRole !== "Customer Admin" && <div className="summary-filters">
                                            <div >
                                                {/* <div className="filter-label">Organization Name</div> */}
                                                <Select
                                                    className='select-custom search-width'
                                                    isClearable={true}
                                                    value={selectedOrg.id ? { label: selectedOrg.name, value: selectedOrg.id } : null}
                                                    options={labelConvert(orgNameList)}
                                                    onChange={handleChange}
                                                    placeholder="Select an organization"
                                                    required
                                                    components={{
                                                        DropdownIndicator: () => null,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                />
                                            </div>
                                            <ThemeBtn name="Search" handleClick={handleSearch} disabled={selectedOrg.name.trim() === ""} />

                                            <ThemeBtn name="Reset" handleClick={clearFilters} />
                                        </div>
                                    }

                                    {
                                        isAdd && <ThemeBtn name="Add Rule" handleClick={handleAdd} />
                                    }
                                </div>


                                {
                                    selectedOrg.id && isAdd ? <div className="summary-section-3">
                                        <TableContainer component={Paper}>
                                            {
                                                loading ? <Loader /> : <>
                                                    <RulesTable data={rulesData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                                </>
                                            }
                                            {/* <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchApproval} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
                                        </TableContainer>
                                    </div> : <>
                                        {
                                            loading ? <Loader /> : <>
                                                <h5 className='select-org-head'>
                                                    Please Select Organization First Name
                                                </h5>
                                            </>
                                        }

                                    </>
                                }


                            </div>
                            {
                                isAddActive && <AddRuleSet open={isAddActive} onClose={() => setIsddActive(false)} title={"Add Rule"} btn={"Add"} fetchDetails={fetchRules} />
                            }
                        </>
                    }
                </>
            }
        </>
    )
}

export default RulesManagement