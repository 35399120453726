import { Box, Button, Text, Truncate } from "@twilio-paste/core";
import { ProductAssetsIcon } from "@twilio-paste/icons/cjs/ProductAssetsIcon";
import { CloseIcon } from "@twilio-paste/icons/cjs/CloseIcon";
import { ReduxMedia } from "../../store/reducers/messageListReducer";
import CancelIcon from '@mui/icons-material/Cancel';
type MessageFileProps = {
  media: ReduxMedia | { filename: string; size: number };
  onRemove?: () => void;
};

const MessageFile: React.FC<MessageFileProps> = ({
  media,
  onRemove,
}: MessageFileProps) => {
  const { filename, size } = media;
  const name = filename ?? "";
  return (
    <Box
      style={{

        display: "flex",
        alignItems: "center",
        padding: "12px 16px",
        margin: "0px 10px 10px 0px",
        border: "1px solid #CACDD8",
        boxSizing: "border-box",
        borderRadius: "4px",
        width: "calc(25% - 20px)",
        maxWidth: "200px",
        minWidth: "150px",
        backgroundColor: "#fff",
        cursor: "default",
        position: "relative"
      }}
    >
      <Box
        style={{
          marginRight: "16px",
          alignItems: "start",
        }}
      >
        <ProductAssetsIcon
          decorative={false}
          title="Open File"
          size="sizeIcon60"
          color="colorTextLink"
          style={{
            fontWeight: "bold",
          }}
        />
      </Box>

      <Box
        style={{
          maxWidth: "calc(100% - 42px)",
        }}
      >
        <Text as="p" fontWeight="fontWeightMedium">
          <Truncate title={name}>{name}</Truncate>
        </Text>
        <Text as="p" color="colorTextInverseWeaker">
          {Math.round((size / Math.pow(2, 20)) * 100) / 100} MB
        </Text>
      </Box>

      {onRemove ? (
        <CancelIcon onClick={onRemove} className="close-icon" />

      ) : null}
    </Box>
  );
};

export default MessageFile;
