import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { extractNames, labelConvert } from "../../utils/helperPages";
import SearchIcon from "@mui/icons-material/Search";
import SearchContext from "../../context/SearchContext";
import AccessContext from "../../context/AccessContext";

const SearchName = ({ widthFull, selectedDropDown, setSelectedDropDown, options, title, type }) => {
  // Select Dropdown for Terminal which is going to use in whole project
  const { searchText, setSearchText } = useContext(SearchContext);
  const { orgChart, setSingleChart, singleChart } = useContext(AccessContext);

  const handleChange = (e) => {
    if (e === null) {
      setSelectedDropDown({ label: "", value: "" })
    } else {
      setSelectedDropDown(e)
    }
  };

  return (
    <>
      <div className="search-bar">
        <Select
          className={`select-custom ${widthFull ? "w-100" : "search-width"}`}
          isClearable={true}
          value={selectedDropDown.value ? selectedDropDown : null}
          options={extractNames(options)}
          onChange={handleChange}
          required
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </>
  );
};

export default SearchName;
