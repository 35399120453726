import React, { useContext, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import ThemeBtn from '../UI/ThemeBtn'
import { Col } from 'react-bootstrap'
import AccessContext from '../../context/AccessContext'
import { useDispatch } from 'react-redux'
import { actionCreators } from '../../store'
import { SendContactRequest } from '../../axios/apis'
import { bindActionCreators } from 'redux'
import { successNotification, unexpectedErrorNotification } from '../../helpers'

const FinalRequestModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const { singleChart } = useContext(AccessContext)
    const [submitloader, setSubmitLoader] = useState(false)

    const dispatch = useDispatch();
    const { updateCurrentConversation, addNotifications } = bindActionCreators(
        actionCreators,
        dispatch
    );

    const handleSend = async (type) => {
        setSubmitLoader(true)
        const payload = {
            userEmailId_From: localStorage.getItem('user'),
            userEmailId_To: singleChart.userEmailId,
            request_Type: type
        }
        try {
            const response = await SendContactRequest(payload)
            setSubmitLoader(false)
            onClose()
            successNotification({
                message: "Request Sent Successfully",
                addNotifications,
            });
        } catch (error) {
            setSubmitLoader(false)
            onClose()
            unexpectedErrorNotification(`Already have access to ${singleChart.userName}`, addNotifications)
            // console.log(error.response.data)
        }
    }

    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} >

                <Col lg={12}>
                    <div className="input-container">
                        <p>Are you sure you want to Send Request to <span style={{ color: "#961B13", fontWeight: 500 }}>{singleChart.userName}</span>.</p>
                    </div>
                </Col>

                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    <ThemeBtn name="Send Request" loader={submitloader} handleClick={() => handleSend("add")} />
                </div>

            </PopupLayout>
        </>
    )
}

export default FinalRequestModal