import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from "@mui/material"
import { tableHead, tableRow } from '../../../utils/helperInline'
import ApprovaltableRow from './ApprovaltableRow'


const ApprovalTable = ({ data, sortBy, sortOrder, page, rowsPerPage, handleSort }) => {

    const tableHeading = [
        { title: "REQUESTED BY", sort: "user_Id_From" },
        { title: "REQUESTER LEVEL", sort: "user_Id_From_Level" },
        { title: "REQUEST FOR", sort: "user_Id_To" },
        { title: "REQUESTEE LEVEL", sort: "user_Id_To_Level" },
        { title: "ORGANIZATION", sort: "customer" },
        { title: "REQUEST DATE", sort: "request_Date" },
    ]


    return (
        <Table>
            <TableHead sx={tableHead}>
                <TableRow>
                    {
                        tableHeading.map((item) => (
                            <TableCell>
                                <TableSortLabel
                                    active={sortBy === item.sort}
                                    direction={sortOrder}
                                    onClick={() => handleSort(item.sort)}
                                >
                                    {item.title}
                                </TableSortLabel>
                            </TableCell>
                        ))
                    }
                    <TableCell>
                        ACTION
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody className="tableBody" sx={tableRow}>
                {
                    data.length !== 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
                        <ApprovaltableRow data={data} handleSort={handleSort} />
                    )) : <>No Record Found</>
                }
            </TableBody>
        </Table>
    )
}

export default ApprovalTable