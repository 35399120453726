import React, { useContext, useEffect, useState } from 'react'
import SearchContext from '../../../context/SearchContext';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AccessContext from '../../../context/AccessContext';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import ToolTipHover from '../../UI/ToolTipHover';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuLayout from '../../UI/MenuLayout';
import GroupDropDown from './GroupDropDown';
import UserDropDown from './UserDropDown';

const Tree = ({ data = [], organizationName }) => {


    return (
        <div className="">
            <ul className="d-flex d-tree-container flex-column">
                {data.map((item, index) => (
                    <TreeNode node={item} parentIndex={index} totalNodes={data.length} organizationName={organizationName} />
                ))}
            </ul>
        </div>
    )
}

const TreeNode = ({ node, parentIndex, totalNodes, organizationName }) => {

    const [childVisible, setChildVisible] = useState(false);
    const userRole = localStorage.getItem('user-role')

    const hasChild = node.children ? true : false;

    const { searchText, setSearchText, findList, searchEmail } = useContext(SearchContext);
    const { setSingleChart, setAddUser, setAddHierachy, setIsActiveState,
        setIsDeleteUser, setIsDeleteHierachy, setSingleUser, setIsUpdateRole, setIsMoveUser, setOrgzName, setIsUpdateGroup } = useContext(AccessContext);

    const includesSearchTerm = (text) =>
        text.toLowerCase() === searchText.toLowerCase();

    useEffect(() => {
        findList.map((name) => {
            if (name === node.department) {
                setChildVisible(true);
            }
        });
    }, [searchText, searchEmail, findList, node]);


    useEffect(() => {
        if (node.treeLevel === 1) setChildVisible(true);

    }, []);

    const handleClick = (name, email) => {
        // setSearchText(name)
    }


    return (
        <>
            <li className="d-tree-node border-0">
                <div className="d-flex align-items-center">
                    <div onClick={() => setChildVisible(!childVisible)}>
                        {hasChild && (
                            <>
                                {childVisible ? <RemoveIcon className="d-tree-icon" /> : <AddIcon className="d-tree-icon" />}
                            </>
                        )}
                    </div>

                    <div className='user-head'>
                        <h4 className={`depart-head  ${includesSearchTerm(node.department) && "highlight"}`}>
                            {node.department}
                        </h4>
                        <div className='depart-icon'>
                            {/* <ToolTipHover title={"Create Group"} position={"top"}>
                                <AddBoxOutlinedIcon onClick={() => handleAdd("hierachy")} />
                            </ToolTipHover>
                            <ToolTipHover title={"Create User"} position={"top"}>
                                <PersonAddAltOutlinedIcon onClick={() => handleAdd("user")} />
                            </ToolTipHover>
                            <ToolTipHover title={"Delete Department"} position={"top"}>
                                <DeleteOutlineIcon onClick={handleDeleteDepart} />
                            </ToolTipHover> */}

                            <GroupDropDown node={node} organizationName={organizationName} />
                        </div>
                    </div>
                </div>



                {hasChild && childVisible && (
                    <div className="d-tree-content">
                        <div>
                            <ul className="d-tree-container">
                                {node.userContactList.map((item, index) => (
                                    <>
                                        {
                                            userRole === "Super Admin" ? <>
                                                <li className={`d-tree-people`}>
                                                    <div className="d-people-node">
                                                        <span
                                                            onClick={() => {
                                                                handleClick(item.userName, item.userEmailId)
                                                            }}
                                                            className={`people-head ${item.userEmailId === searchEmail && "highlight"
                                                                }`}
                                                        >
                                                            {item.userName}
                                                        </span>
                                                        <div className='user-icon'>
                                                            <UserDropDown item={item} organizationName={organizationName} />
                                                        </div>
                                                    </div>
                                                </li>
                                                {(index === node.userContactList.length - 1 && node.children.length !== 0) && <hr className="m-0" />}
                                            </> : <>
                                                {
                                                    item.userRole !== "Super Admin" && <>
                                                        <li className={`d-tree-people`}>
                                                            <div className="d-people-node">
                                                                <span
                                                                    onClick={() => {
                                                                        handleClick(item.userName, item.userEmailId)
                                                                    }}
                                                                    className={`people-head ${item.userEmailId === searchEmail && "highlight"
                                                                        }`}
                                                                >
                                                                    {item.userName}
                                                                </span>
                                                                <div className='user-icon'>
                                                                    <UserDropDown item={item} organizationName={organizationName} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {(index === node.userContactList.length - 1 && node.children.length !== 0) && <hr className="m-0" />}
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                ))}
                            </ul>
                        </div>
                        <ul className="d-flex d-tree-container flex-column">
                            <Tree data={node.children} organizationName={organizationName} />
                        </ul>
                    </div>
                )}
            </li>

            {parentIndex !== totalNodes - 1 && <hr className="m-0" />}

        </>
    );
};



export default Tree