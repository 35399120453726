import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewRuleModal from '../ViewRuleModal';
import UpdateRuleSet from '../UpdateRuleSet';
import DeleteRuleSet from '../DeleteRuleSet';
import DeleteEmptyRuleSet from '../DeleteEmptyRuleSet';


const RulestableRow = ({ data, ruleData, handleSort }) => {

    const { ruleSetName, userIdList, departmentIdList, ruleSetId } = data


    const [isDeleteActive, setIsDeleteActive] = useState(false)
    const [isEditActive, setIsEditActive] = useState(false)
    const [isShow, setIsShow] = useState(false)


    return (
        <>
            <TableRow className="tableRow" >
                <TableCell>{ruleSetName || "--"}</TableCell>
                <TableCell>{ruleSetName === "Default" ? "All" : departmentIdList.length || "--"}</TableCell>
                <TableCell>{ruleSetName === "Default" ? "All" : userIdList.length || "--"}</TableCell>
                <TableCell>
                    {
                        ruleSetName !== "Default" && <div className='dot-box'>
                            <VisibilityIcon onClick={() => setIsShow(true)} className='me-3' />
                            <EditIcon onClick={() => setIsEditActive(true)} className='me-3' />
                            <DeleteIcon onClick={() => setIsDeleteActive(true)} className='me-3' />
                        </div>
                    }
                </TableCell>
            </TableRow>
            {
                isShow && <ViewRuleModal open={isShow} onClose={() => setIsShow(false)} title={"Rule Details"} btn={"View"} data={data} />
            }
            {
                isEditActive && <UpdateRuleSet open={isEditActive} onClose={() => setIsEditActive(false)} title={"Update Rule"} btn={"Update"} data={data} fetchDetails={handleSort} />
            }
            {
                isDeleteActive && <DeleteRuleSet open={isDeleteActive} onClose={() => setIsDeleteActive(false)} title={"Delete Rule"} btn={"Delete"} data={data} fetchDetails={handleSort} ruleData={ruleData} />
            }
        </>
    )
}

export default RulestableRow