import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import { useContext, useEffect } from "react";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { MessageContext } from "../../../../../context/MessageContext";
import { sendMessage } from "../../../../../firebase-config";
import useParticipants from "../../../hooks/useParticipants/useParticipants";
import useRoomState from "../../../hooks/useRoomState/useRoomState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: "white",
      "&:hover": {
        background: "#600101",
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { message, setMessage, accepted, setAccepted, rejected, setRejected } = useContext(MessageContext);
  const roomState = useRoomState();
  const participants = useParticipants();


  const disconnectRoom = () => {
    if (room) {
      room?.disconnect();
      localStorage.removeItem("incomingCallId");
      localStorage.setItem("accepted", 'false');
    } else {
      console.log("no active room to disconnect");
    }
    setMessage({});
    setAccepted(false);
  };

  useEffect(() => {
    if (rejected) {
      disconnectRoom();
      localStorage.removeItem("incomingCallId");
      setReject();
    }
  }, [rejected]);

  const setReject = () => {
    setTimeout(() => {
      if (rejected) {
        setRejected(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (participants.length === 0 && roomState === "connected") {
      room!.disconnect();
      disconnectRoom()
    }
  }, [participants])


  return (
    <Button
      onClick={() => {
        room!.disconnect();
        disconnectRoom();
      }}
      className={clsx(classes.button, props.className)}
      data-cy-disconnect
    >
      Disconnect
    </Button>
  );
}
