import React, { useContext, useEffect, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import AccessContext from '../../context/AccessContext';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store';
import CircleLoader from '../UI/CircleLoader';
import { Col } from 'react-bootstrap';
import ThemeBtn from '../UI/ThemeBtn';
import RequiredAstrict from '../UI/RequiredAstrict';
import Select from "react-select";
import RulesToolTip from '../UI/RulesToolTip';
import { extractDepartmentName, findDepartmentById, findParentDepartment, labelConvertRules } from '../../utils/helperPages';
import { AssignRuleToExistingDepartment, RulesList } from '../../axios/apis';
import { successNotification, unexpectedErrorNotification } from '../../helpers';
import CreateCustomRule from './TreeView/CreateCustomRule';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { optionsStyles } from '../../utils/helperInline';

const UpdateGroup = ({ open, onClose, title, btn, data, profileList, fetchDetails }) => {

    const [formLoader, setFormLoader] = useState(false)
    const [submitloader, setSubmitLoader] = useState(false);
    const [showCustomForm, setShowCustomForm] = useState(false);
    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);

    const { orgChart, singleChart, setSelectedDepartments, setSelectedUsers, orgzName } = useContext(AccessContext);

    const [selectParent, setSelectParent] = useState({
        parentName: singleChart?.department,
        parentId: singleChart?.departmentId,
    });

    const [accessRule, setAccessRule] = useState({
        accessRuleId: null,
        accessRuleName: "",
    })

    const [levelDetail, setLevelDetail] = useState({
        departmentName: selectParent.parentName || "",
        departmentType: "asasas",
        departmentId: singleChart.departmentId,
        parentDepartmentId: selectParent ? selectParent.parentId : "",
    });


    const [accessRuleList, setAccessRuleList] = useState([])
    const [originalRules, setOriginalRules] = useState([])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLevelDetail({ ...levelDetail, [name]: value });
    };

    const handleSelect = (e) => {
        if (e === null) {
            setSelectParent(null);
        } else {
            setSelectParent({ parentName: e.label, parentId: e.value });
            setLevelDetail({ ...levelDetail, parentDepartmentId: e.value });
        }
    };


    const handleAccessRuleChange = (e) => {

        if (e === null) {
            setAccessRule({ accessRuleId: "", accessRuleName: "" });
        } else {
            setAccessRule({ accessRuleId: e.value, accessRuleName: e.label });
            if (e.value === "custom") {
                setSelectedDepartments([]);
                setSelectedUsers([]);
            }
        }
    };

    const handleNext = () => {
        // handleAccessRuleChange({ value: "custom" });
        setShowCustomForm(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (accessRule.accessRuleId === "custom") {
            handleNext()
        } else {
            setSubmitLoader(true);
            try {
                const response = await AssignRuleToExistingDepartment({ departmentId: levelDetail.departmentId, newDepartmentName: levelDetail.departmentName, ruleSetId: accessRule?.accessRuleId });
                if (response.status === 200) {
                    setSubmitLoader(false);
                    successNotification({
                        message: "Group Updated Successfully",
                        addNotifications,
                    });
                    onClose()
                    fetchDetails("");
                }
            } catch (error) {
                unexpectedErrorNotification(error.response.data || "Group Not Updated Successfully", addNotifications);
                setSubmitLoader(false);
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        setSelectParent({
            parentName: singleChart?.department,
            parentId: singleChart?.departmentId,
        });
    }, [singleChart]);




    const fetchRulesList = async () => {
        setFormLoader(false)
        try {
            const response = await RulesList(orgzName.organizationName)

            const isMatchRule = response.data.ruleSets.some((e) => e.ruleSetId === singleChart?.departmentRuleSetId)
            if (isMatchRule) {
                setAccessRule({ accessRuleId: singleChart?.departmentRuleSetId, accessRuleName: singleChart?.departmentRuleSetName })
            } else {
                setAccessRule({ accessRuleId: "custom", accessRuleName: "Custom" })
            }
            const customItem = { ruleSetId: "custom", ruleSetName: "Custom" };
            const currentRuleSets = response.data.ruleSets;
            let updatedRuleSets = [];
            const parentDepartment = findDepartmentById([orgzName.orgChart], singleChart.parentDepartmentId)

            if (parentDepartment) {
                const inheritItem = { ruleSetId: parentDepartment.departmentRuleSetId, ruleSetName: "Inherit from Parent" };

                // Find the index of the "default" item
                const defaultItemIndex = currentRuleSets.findIndex(item => item.ruleSetName === "Default");


                updatedRuleSets.push(inheritItem);

                // Insert the "custom" item after the "default" item if found
                if (defaultItemIndex !== -1) {
                    updatedRuleSets.push(currentRuleSets[defaultItemIndex]);
                    updatedRuleSets.push(customItem);
                    updatedRuleSets.push(...currentRuleSets.slice(defaultItemIndex + 1));
                } else {
                    // If "default" item not found, insert "custom" item at index 1
                    updatedRuleSets.push(customItem);
                    updatedRuleSets.push(...currentRuleSets);
                }
            } else {
                updatedRuleSets.push(customItem);
                updatedRuleSets.push(...currentRuleSets);
            }

            setAccessRuleList(updatedRuleSets);
            setOriginalRules(currentRuleSets);
            setFormLoader(true)
        } catch (error) {
            unexpectedErrorNotification({ message: "Rules List is Not loaded", addNotifications });
            // console.log(error)
        }
    }

    useEffect(() => {
        fetchRulesList()
    }, [])

    const handleBack = () => {
        setShowCustomForm(false);
        setAccessRule({ accessRuleId: null, accessRuleName: "" });
    }

    const customHeading = (
        <span>
            Update Group <ArrowForwardIcon className="head-arrow-icon-popup" /> Create Custom Rule
        </span>
    )


    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={!showCustomForm ? title : customHeading} handleSubmit={handleSubmit}>
                {
                    !formLoader ? <CircleLoader /> : <>
                        {
                            showCustomForm ? <CreateCustomRule open={showCustomForm} onBack={handleBack} data={levelDetail} fetchData={fetchDetails} modal="Department" type="Update" />
                                : <>
                                    <Col lg={12}>
                                        <div className="input-box">
                                            <label className="input-label" htmlFor="">
                                                Name <RequiredAstrict />
                                            </label>
                                            <div className="input-field parent-custom">
                                                <input value={levelDetail.departmentName} onChange={handleChange} type="text" name="departmentName" />
                                            </div>
                                        </div>
                                    </Col>


                                    <div className="input-box">
                                        <label className="input-label" htmlFor="">
                                            Access Rule <RequiredAstrict />
                                        </label>
                                        <Select
                                            className={`parent-custom`}
                                            isClearable={true}
                                            value={accessRule.accessRuleId ? { label: accessRule.accessRuleName, value: accessRule.accessRuleId } : null}
                                            options={labelConvertRules(accessRuleList)}
                                            onChange={handleAccessRuleChange}
                                            required
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            styles={optionsStyles(accessRule.accessRuleName, accessRule.accessRuleId)}
                                        />
                                        {accessRule.accessRuleName !== "Custom" && accessRule.accessRuleName &&
                                            <RulesToolTip list={originalRules} selectedId={accessRule.accessRuleId} type="update" />
                                        }
                                    </div>
                                    <Col lg={12}>
                                    </Col>

                                    <div className="action-btn">
                                        <button className="cancel-btn" onClick={onClose}>
                                            Cancel
                                        </button>
                                        <ThemeBtn name={accessRule.accessRuleId === "custom" ? "Next" : "Update"} loader={submitloader} type="submit" />
                                    </div>
                                </>
                        }
                    </>
                }
            </PopupLayout>
        </>
    )
}

export default UpdateGroup