import { createContext, useState } from "react";

const UpdateConvoNameContext = createContext();

export const UpdateConvoNameProvider = ({ children }) => {
  const [convoName, setConvoName] = useState(false);

  return (
    <UpdateConvoNameContext.Provider value={{ convoName, setConvoName }}>
      {children}
    </UpdateConvoNameContext.Provider>
  );
};

export default UpdateConvoNameContext;
