import React, { useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import { Col } from 'react-bootstrap'
import ThemeBtn from '../UI/ThemeBtn'
import { successNotification, unexpectedErrorNotification } from '../../helpers'
import { useDispatch } from 'react-redux'
import { actionCreators } from '../../store'
import { bindActionCreators } from 'redux'
import { DeleteEmptyRule } from '../../axios/apis'

const DeleteEmptyRuleSet = ({ onClose, data, fetchDetails, btn, ruleData }) => {

    const [submitloader, setSubmitLoader] = useState(false)

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(
        actionCreators,
        dispatch
    );

    const handleDelete = async () => {
        setSubmitLoader(true)
        try {
            const response = await DeleteEmptyRule({ ruleSetId: data.ruleSetId })
            if (response.status === 200) {
                successNotification({
                    message: `Rule Deleted Successfully`,
                    addNotifications,
                });
                fetchDetails("")
                setSubmitLoader(false)
                onClose()
            }
        } catch (error) {
            unexpectedErrorNotification(error.response?.data || "An error occurred while Deleting the Rule", addNotifications);
            setSubmitLoader(false)
        }
    }

    return (
        <>
            <Col lg={12}>
                <div className="input-container">
                    <p>To Delete <span style={{ color: "#961B13", fontWeight: 500 }}>{data.ruleSetName}</span> Rule
                        click on Delete Button.
                    </p>
                </div>
            </Col>

            <div className='d-flex justify-content-end gap-4'>
                <button className='cancel-btn' onClick={onClose}>Cancel</button>
                <ThemeBtn name={btn} loader={submitloader} type='button' handleClick={handleDelete} />
            </div>
        </>
    )
}

export default DeleteEmptyRuleSet