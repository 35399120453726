import React, { useState, useEffect, useContext } from "react";
import "./NotificationsPopup.css";
import axios from "axios";
import AppReadyContext from "../../../context/AppReadyContext";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import NotificationContext from "../../../context/NotificationContext";
import { NoEncryption } from "@mui/icons-material";
import { useSelector } from "react-redux";

const NotificationsPopup = () => {
  const { notification } = useContext(NotificationContext);
  const [notifications, setNotifications] = useState([]); // Manage your list of notifications here
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const unreadMessages = useSelector((state) => state.unreadMessages);
  const { appReady } = useContext(AppReadyContext);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    fetchNotifications(1);
    fetchUnreadNotificationCount();
    setPage(1);
  };

  const fetchUnreadNotificationCount = () => {
    const url =
      " https://draytalk-apim.azure-api.net/api/Notification/v1/NotificationsUnreadCount";

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        // Successfully fetched unread notification count
        setUnreadNotificationCount(response.data.unreadNotificationCount);
      })
      .catch((error) => {
        console.error("Error fetching unread notification count:", error);
      });
  };

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => {
        fetchUnreadNotificationCount();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [notification]);

  useEffect(() => {
    if (appReady) {
      fetchNotifications(page);
      fetchUnreadNotificationCount();
      const interval = setInterval(fetchUnreadNotificationCount, 5 * 60 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [appReady]);

  const fetchNotifications = (page) => {
    let apiUrl = ` https://draytalk-apim.azure-api.net/api/Notification/v1/ContactNotifications?PageNumber=${page}&PageSize=${pageSize}`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        // Process the response data
        setNotifications(response.data.notifications);
        setTotalRecords(response.data.pageDetails.totalRecords);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      fetchNotifications(page - 1);
      setPage(page - 1);
    }
  };
  const handleNextPage = () => {
    if (page < Math.ceil(totalRecords / pageSize)) {
      fetchNotifications(page + 1);
      setPage(page + 1);
    }
  };
  const getTimeDifference = (timestamp) => {
    const currentTime = new Date();
    const notificationTime = new Date(timestamp);

    const timeDifferenceInSeconds = Math.floor(
      (currentTime - notificationTime) / 1000
    );

    if (timeDifferenceInSeconds < 60) {
      return `${timeDifferenceInSeconds} seconds ago`;
    } else if (timeDifferenceInSeconds < 3600) {
      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < 86400) {
      const hours = Math.floor(timeDifferenceInSeconds / 3600);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else {
      const days = Math.floor(timeDifferenceInSeconds / 86400);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    }
  };

  const formatToLocalTime = (utcTimestamp) => {
    const utcTime = new Date(utcTimestamp);
    const localTime = new Date(
      utcTime.getTime() + utcTime.getTimezoneOffset() * 60000
    );

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return localTime.toLocaleDateString("en-US", options);
  };

  const toggleNotificationReadStatus = (
    notificationId,
    currentReadStatus,
    index
  ) => {
    const newReadStatus = currentReadStatus === "Read" ? "UnRead" : "Read";

    const url =
      " https://draytalk-apim.azure-api.net/api/Notification/v1/NotificationsReadStatus";
    const requestBody = {
      notificationId: notificationId,
      notificationReadStatus: newReadStatus,
    };

    axios
      .put(url, requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        // Successfully toggled read status
        const updatedNotifications = [...notifications];
        updatedNotifications[index].notificationReadStatus = newReadStatus;
        setNotifications(updatedNotifications);
        fetchUnreadNotificationCount();
      })
      .catch((error) => {
        console.error("Error toggling notification read status:", error);
      });
  };

  const clearNotification = (notificationId, index) => {
    const url =
      "https://draytalk-apim.azure-api.net/api/Notification/v1/NotificationsReadStatus";
    const requestBody = {
      notificationId: notificationId,
      notificationReadStatus: "Cleared",
    };

    axios
      .put(url, requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        // Successfully cleared the notification
        fetchNotifications(page); // Fetch notifications again with the current page
        fetchUnreadNotificationCount();
      })
      .catch((error) => {
        console.error("Error clearing notification:", error);
      });
  };

  const clearAllNotifications = () => {
    const url =
      " https://draytalk-apim.azure-api.net/api/Notification/v1/NotificationsClearAll";

    axios
      .put(url, null, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        // Successfully cleared all notifications
        fetchNotifications(1); // Fetch notifications again with the current page
      })
      .catch((error) => {
        console.error("Error clearing all notifications:", error);
      });
  };


  function sumNumericValues(obj) {
    let sum = 0;

    for (const key in obj) {
      if (typeof obj[key] === 'number' && obj[key] !== 0) {
        sum += obj[key];
      }
    }

    if (sum !== 0) {
      return sum
    } else {
      return 0
    }
  }


  return (
    <div className="notifications-popup">
      <div className="notifications-icon" onClick={toggleDrawer}>
        <Badge
          badgeContent={unreadNotificationCount}
          color="error"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          overlap="circular"
        >
          <NotificationsIcon htmlColor="#B29E7F" />
        </Badge>
      </div>
      <Drawer
        className="notification-pane"
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        {" "}
        <div className="notifications-container">
          <div className="notification-header">
            <h2 className="notification-header-text">Notifications</h2>
            <div className="clearAll-button">
              <InputAdornment position="end">
                <Tooltip
                  title={
                    <Typography variant="body2" className="tooltip-title">
                      Clear all Notifications
                    </Typography>
                  }
                  placement="left"
                >
                  <Button className="notification-button"
                    variant="text"
                    size="small"
                    onClick={clearAllNotifications}
                    disabled={notifications.length < 1}
                  >
                    Clear All
                  </Button>
                </Tooltip>
              </InputAdornment>
            </div>
          </div>
          <div className="pagination-buttons2">
            <Button className="notification-button"
              variant="text"
              size="small"
              onClick={handlePreviousPage}
              disabled={page === 1}
            >
              Previous
            </Button>
            <Button className="notification-button"
              variant="text"
              size="small"
              onClick={handleNextPage}
              disabled={page >= Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
          </div>

          <div className="spacer"></div>

          {notifications.length === 0 ? (
            <p></p>
          ) : (
            <>
              {notifications.map((notification, index) => (
                <>
                  <div
                    key={index}
                    className={`Notification-Item ${notification.notificationReadStatus === "Read"
                      ? "unread-notification"
                      : ""
                      }`}
                  >
                    <div>
                      <CheckCircleIcon
                        fontSize="small"
                        htmlColor="#27AE60"
                        className="notification-icon-img"
                      />
                    </div>
                    <div className="notification-content">
                      <div className="notification-top">
                        {notification.notificationType}
                      </div>
                      <div className="notification-top-row">
                        {notification.notificationMessage}
                      </div>
                      <div className="notification-bottom-row">
                        {formatToLocalTime(notification.notificationDateTime)}
                        <div className="notification-buttons">
                          {notification.notificationReadStatus === "UnRead" ? (
                            <InputAdornment position="end">
                              <Tooltip
                                title={
                                  <Typography
                                    variant="body2"
                                    className="tooltip-title"
                                  >
                                    Mark as Read
                                  </Typography>
                                }
                                placement="left"
                              >
                                <MarkChatUnreadIcon
                                  className="toggle-status"
                                  htmlColor="rgb(25, 118, 210)"
                                  onClick={() =>
                                    toggleNotificationReadStatus(
                                      notification.notificationId,
                                      "UnRead",
                                      index
                                    )
                                  }
                                />
                              </Tooltip>
                            </InputAdornment>
                          ) : (
                            <MarkChatReadIcon htmlColor="rgba(0, 0, 0, 0.6)" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <Typography
                              variant="body2"
                              className="tooltip-title"
                            >
                              Clear Notification
                            </Typography>
                          }
                          placement="left"
                        >
                          <CloseIcon
                            fontSize="small"
                            className="clear-notification"
                            onClick={() =>
                              clearNotification(
                                notification.notificationId,
                                index
                              )
                            }
                          />
                        </Tooltip>
                      </InputAdornment>
                    </div>
                    <div className="Notification-Icon">

                    </div>
                  </div>
                  <div className="spacer"></div>
                </>
              ))}
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default NotificationsPopup;
