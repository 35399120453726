import { createContext, useState } from "react";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {

    const [searchOrg, setSearchOrg] = useState({
        name: "",
        id: ""
    })
    const [orgDetails, setOrgDetails] = useState({})


    const [findList, setFindList] = useState([])
    const [searchText, setSearchText] = useState("");
    const [searchEmail, setSearchEmail] = useState("")

    const [popUpFindList, setPopUpFindList] = useState([])
    const [searchTextPopup, setSearchTextPopUp] = useState("")
    const [searchEmailPopup, setSearchEmailPopup] = useState("")

    return (
        <SearchContext.Provider value={{
            searchText, setSearchText, findList, setFindList, popUpFindList, setPopUpFindList,
            searchTextPopup, setSearchTextPopUp, searchEmail, setSearchEmail, searchEmailPopup, setSearchEmailPopup,
            searchOrg, setSearchOrg, setOrgDetails, orgDetails
        }}>
            {children}
        </SearchContext.Provider>
    );
};

export default SearchContext;