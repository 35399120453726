import React from 'react'
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material"

const ThemeBtn = ({ name, handleClick, disabled = false, loader = false, type = "button" }) => {

    const ColorButton = styled(Button)(({ theme }) => ({
        color: "white",
        size: "small",
        borderRadius: "5px",
        height: "40px",
        backgroundColor: "#961B13",
        fontSize: "14px",
        "&:hover": {
            backgroundColor: "#961B13",
        },
        "&:disabled": {
            backgroundColor: "#CCCCCC", // Button color when disabled
            opacity: 0.7, // Adjust the opacity as desired
            cursor: "not-allowed", // Show 'not-allowed' cursor when disabled
        },
        textTransform: "none",
    }));

 

    return (
        <>
            <ColorButton
                className="filter-button"
                variant="contained"
                onClick={handleClick}
                disabled={disabled}
                
                type={type}
            >
                {
                    loader ? <div class="spinner-border spinner-size" role="status" /> : <>
                        {name}
                    </>
                }
            </ColorButton>
        </>
    )
}

export default ThemeBtn