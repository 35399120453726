import { DialogContent, DialogActions, Button, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        width: "900px",
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        color: theme.palette.text.primary,
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        maxWidth: "900px", //Set Max Width of the Modal
        overflowY: "unset !important" //Handling the scroll for the popup
    },
}));

export const StyledDialogLoader = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        // width: "900px",
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        color: theme.palette.text.primary,
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        // maxWidth: "900px" //Set Max Width of the Modal
    },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.primary,
    padding: "25px !important",
    minWidth: "900px", // Set minimum width for content
    overflowY: "unset !important" //Handling the scroll for the popup
}));

export const StyledDialogContentLoader = styled(DialogContent)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.primary,
    padding: "25px !important",
    // minWidth: "900px", // Set minimum width for content
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.primary,
    justifyContent: "center",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#163E55",
    "&:hover": {
        backgroundColor: "#163E55",
    },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "space-between", // To align the button on the right
    alignItems: "center", // To vertically center the content
    minWidth: "800px", // Set minimum width for title
}));

export const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#163E55",
    "&:hover": {
        backgroundColor: "#163E55",
    },
    textTransform: "none",
}));