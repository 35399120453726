import React, { useState, createRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { Client } from "@twilio/conversations";

import SettingsMenu from "./SettingsMenu";
import ManageParticipantsModal from "../modals/manageParticipantsModal";
import { Content } from "../../types";
import {
  addChatParticipant,
  addNonChatParticipant,
  removeParticipant,
} from "../../api";
import AddChatParticipantModal from "../modals/addChatMemberModal";
import AddSMSParticipantModal from "../modals/addSMSParticipantModal";
import AddWhatsAppParticipantModal from "../modals/addWhatsAppParticipant";
import { actionCreators } from "../../store";
import ActionErrorModal from "../modals/ActionErrorModal";
import {
  CONVERSATION_MESSAGES,
  ERROR_MODAL_MESSAGES,
  SMS_PREFIX,
  WHATSAPP_PREFIX,
} from "../../constants";
import {
  successNotification,
  unexpectedErrorNotification,
} from "../../helpers";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import {
  getSdkConversationObject,
  getSdkParticipantObject,
} from "../../conversations-objects";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { isValidPhoneNumber } from "libphonenumber-js";
import UpdateConvoNameContext from "../../context/UpdateConvoName";
import { useContext } from "react";
import { updateConversation } from "../../store/action-creators";
interface SettingsProps {
  convoNameText: string
  participants: ReduxParticipant[];
  client?: Client;
  convo: ReduxConversation;
  isManageParticipantOpen: boolean;
  setIsManageParticipantOpen: (open: boolean) => void;
  accessData: string;
}

const invalidPhoneNumberErrorMessage = "Invalid phone number";
const Settings: React.FC<SettingsProps> = (props: SettingsProps) => {
  const handleParticipantClose = () => props.setIsManageParticipantOpen(false);

  const [isAddChatOpen, setIsAddChatOpen] = useState(false);
  // TODO: move to app loading state
  // const [isLoading, setLoading] = useState(false);
  const handleChatOpen = () => setIsAddChatOpen(true);
  const handleChatClose = () => setIsAddChatOpen(false);

  const [isAddSMSOpen, setIsAddSMSOpen] = useState(false);
  const handleSMSOpen = () => setIsAddSMSOpen(true);
  const handleSMSClose = () => setIsAddSMSOpen(false);

  const [isAddWhatsAppOpen, setIsAddWhatsAppOpen] = useState(false);
  const handleWhatsAppOpen = () => setIsAddWhatsAppOpen(true);
  const handleWhatsAppClose = () => setIsAddWhatsAppOpen(false);

  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const [nameProxy, setNameProxy] = useState("");
  const [errorProxy, setErrorProxy] = useState("");

  const [showError, setErrorToShow] = useState<
    | {
      title: string;
      description: string;
    }
    | false
  >();
  const [errorData, setErrorData] = useState<
    | {
      message: string;
      code: number;
    }
    | undefined
  >();

  const nameInputRef = createRef<HTMLInputElement>();

  const dispatch = useDispatch();
  const { updateCurrentConversation, addNotifications } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const { convoName, setConvoName } = useContext(UpdateConvoNameContext);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );

  function emptyData() {
    setName("");
    setNameProxy("");
    setError("");
    setErrorProxy("");
  }

  function setErrors(errorText: string) {
    setError(errorText);
    setErrorProxy(errorText);
  }


  function extractValidEmails(text: string): string[] {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    // Split text into segments that look like potential email addresses
    const potentialEmails = text.split(/(?=[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/);

    // Filter segments to ensure they match the email pattern exactly
    const emails = potentialEmails.filter(segment => emailPattern.test(segment));
    return emails;
  }

  // Utility function to check if a specific email is in the given string
  // function isEmailInString(emailString: string, emailToCheck: string): boolean {
  //   console.log(emailString, emailToCheck)
  //   const emailArray = extractValidEmails(emailString);
  //   return emailArray.includes(emailToCheck);
  // }

  const userEmail = localStorage.getItem('user');
  const emailToCheck = userEmail !== null ? userEmail : '';


  function extractEmails(text: string) {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    // Extract email addresses from the text using the pattern
    const extractedEmails = text.match(emailPattern) || [];
    return extractedEmails;
  }

  // function isEmailInString(emailToCheck: string): boolean {
  //   //   console.log(emailString, emailToCheck)
  //   const emailNames = extractEmails(props.convoNameText)
  //   emailNames.includes(emailToCheck)
  //   //   const emailArray = extractValidEmails(emailString);
  //   //   return emailArray.includes(emailToCheck);
  //   // }
  // }
  function isEmailInString(value: string) {
    return props.convoNameText.includes(value);
  }

  return (
    <>
      <SettingsMenu
        onParticipantListOpen={() => props.setIsManageParticipantOpen(true)}
        leaveConvo={async () => {
          try {
            await sdkConvo.leave();
            successNotification({
              message: CONVERSATION_MESSAGES.LEFT,
              addNotifications,
            });
            updateCurrentConversation("");
          } catch (e) {
            unexpectedErrorNotification(e.message, addNotifications);
          }
        }}
        deleteConvo={async () => {
          try {
            await sdkConvo.delete();
            successNotification({
              message: CONVERSATION_MESSAGES.LEFT,
              addNotifications,
            });
            updateCurrentConversation("");
          } catch (e) {
            unexpectedErrorNotification(e.message, addNotifications);
          }
        }}
        conversation={props.convo}
        addNotifications={addNotifications}
      />
      <ActionErrorModal
        errorText={showError || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME}
        isOpened={!!showError}
        onClose={() => {
          setErrorToShow(false);
          setErrorData(undefined);
        }}
        error={errorData}
      />
      {props.isManageParticipantOpen && (
        <ManageParticipantsModal
          convoSid={props.convo.sid}
          handleClose={handleParticipantClose}
          isModalOpen={props.isManageParticipantOpen}
          title="Manage Participants"
          participantsCount={props.participants.length}
          participantsList={props.participants}
          client={props.client}
          accessData={props.accessData}
          onClick={(content: Content) => {
            handleParticipantClose();
            switch (content) {
              case Content.AddSMS:
                handleSMSOpen();
                return null;
              case Content.AddWhatsApp:
                handleWhatsAppOpen();
                return null;
              case Content.AddChat:
                handleChatOpen();
                return null;
              default:
                return null;
            }
          }}
          onParticipantRemove={async (participant) => {
            await removeParticipant(
              sdkConvo,
              props.convo.sid,
              getSdkParticipantObject(participant),
              addNotifications
            );
            const randomValue = new Date().getTime();
            setConvoName(randomValue)
          }}
        />
      )}
      {isAddSMSOpen && (
        <AddSMSParticipantModal
          name={name}
          proxyName={nameProxy}
          isModalOpen={isAddSMSOpen}
          title="Manage Participants"
          setName={(name: string) => {
            setName(name);
            setError(
              !isValidPhoneNumber(`+${name}`)
                ? invalidPhoneNumberErrorMessage
                : ""
            );
          }}
          setProxyName={(name: string) => {
            setNameProxy(name);
            setErrorProxy(
              !isValidPhoneNumber(`+${name}`)
                ? invalidPhoneNumberErrorMessage
                : ""
            );
          }}
          error={error}
          errorProxy={errorProxy}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleSMSClose();
          }}
          onBack={() => {
            emptyData();
            handleSMSClose();
            props.setIsManageParticipantOpen(true);
          }}
          action={async () => {
            try {
              await addNonChatParticipant(
                SMS_PREFIX + name,
                SMS_PREFIX + nameProxy,
                sdkConvo,
                addNotifications
              );
              emptyData();
              handleSMSClose();
            } catch (e) {
              setErrorData(e.body);
              setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
            }
          }}
        />
      )}
      {isAddWhatsAppOpen && (
        <AddWhatsAppParticipantModal
          name={name}
          proxyName={nameProxy}
          isModalOpen={isAddWhatsAppOpen}
          title="Manage Participants"
          setName={(name: string) => {
            setName(name);
            setError(
              !isValidPhoneNumber(`+${name}`)
                ? invalidPhoneNumberErrorMessage
                : ""
            );
          }}
          setProxyName={(name: string) => {
            setNameProxy(name);
            setErrorProxy(
              !isValidPhoneNumber(`+${name}`)
                ? invalidPhoneNumberErrorMessage
                : ""
            );
          }}
          error={error}
          errorProxy={errorProxy}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleWhatsAppClose();
          }}
          onBack={() => {
            emptyData();
            handleWhatsAppClose();
            props.setIsManageParticipantOpen(true);
          }}
          action={async () => {
            try {
              await addNonChatParticipant(
                WHATSAPP_PREFIX + name,
                WHATSAPP_PREFIX + nameProxy,
                sdkConvo,
                addNotifications
              );
              emptyData();
              handleWhatsAppClose();
            } catch (e) {
              setErrorData(e.body);
              setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
            }
          }}
        />
      )}
      {isAddChatOpen && (
        <AddChatParticipantModal
          name={name}
          isModalOpen={isAddChatOpen}
          title="Manage Participants"
          setName={(name: string) => {
            setName(name);
            setErrors("");
          }}
          error={error}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleChatClose();
          }}
          onBack={() => {
            emptyData();
            handleChatClose();
            props.setIsManageParticipantOpen(true);
          }}
          action={async () => {
            console.log(name.trim())
            if (props.convoNameText !== "Group Chat" && isEmailInString(emailToCheck)) {
              sdkConvo
                ?.updateFriendlyName("Group Chat")
                .then((convo) => {
                  updateConversation(convo.sid, convo);
                })
                .catch((e) => {
                  setErrorData(e);
                  setErrorToShow(ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME);
                });
            }

            try {
              await addChatParticipant(name.trim(), sdkConvo, addNotifications);

              const randomValue = new Date().getTime();
              setConvoName(randomValue)
              emptyData();
              handleChatClose();
            } catch (e) {
              setErrorData(e.body);
              setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
            }
          }}
        />
      )}
      {/* {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          height="100%"
          width="100%"
        >
          <Spinner size="sizeIcon110" decorative={false} title="Loading" />
        </Box>
      ) : null} */}
    </>
  );
};

export default Settings;
