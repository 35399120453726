import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchContext from "../../../context/SearchContext";
import AccessContext from "../../../context/AccessContext";
import { doesConversationExistWithEmail, readUserProfile } from "../../../api";
import { doesConversationExistWithEmails } from "../../../api";
import { useClient } from "../../../context/ClientInfoContext";
import { actionCreators } from "../../../store";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Conversation } from "@twilio/conversations";
import { unexpectedErrorNotification } from "../../../helpers";
import { createConversation, createGroupConversation } from "../../../api";
import ToolTipHover from "../../UI/ToolTipHover";
import { Client } from "@twilio/conversations";

const Tree = ({ data }) => {
  return (
    <div className="">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((item, index) => (
          <>
            <TreeNode
              node={item}
              index={index}
              totalNodes={data.length}
              data={data}
            />
            {item.otherContactList && (
              <OtherContactTree
                node={item.otherContactList}
                parentIndex={index}
                totalNodes={item.otherContactList.length}
              />
            )}
          </>
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, index, totalNodes, data }) => {
  const [childVisible, setChildVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.token);
  const { updateCurrentConversation, addNotifications, updateParticipants } =
    bindActionCreators(actionCreators, dispatch);
  const { client, updateConnectionState, updateConversation } = useClient();
  const hasChild = node.children ? true : false;

  const { searchText, findList, searchEmail } = useContext(SearchContext);
  const {
    setSingleChart,
    setIsChatActive,
    selectedDepartments,
    setSelectedDepartments,
    selectedUsers,
    setSelectedUsers,
  } = useContext(AccessContext);

  const includesSearchTerm = (text) =>
    text.toLowerCase() === searchText.toLowerCase();
  const [checkedDepartments, setCheckedDepartments] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);

  // Function to handle department checkbox change
  const handleDepartmentCheckboxChange = (department) => {

    setSelectedDepartments((prevDepartments) => {
      const isDepartmentChecked = prevDepartments.includes(
        department.departmentId
      );

      const usersInDepartment = department.userContactList || [];

      if (isDepartmentChecked) {
        setSelectedUsers((prevUsers) =>
          prevUsers.filter(
            (user) => !usersInDepartment.map((u) => u.id).includes(user)
          )
        );
        return prevDepartments.filter((dep) => dep !== department.departmentId);
      } else {
        setSelectedUsers((prevUsers) =>
          prevUsers.filter(
            (user) => !usersInDepartment.map((u) => u.id).includes(user)
          )
        );
        // setSelectedUsers((prevUsers) => [
        //   ...prevUsers,
        //   ...usersInDepartment.map((u) => u.id),
        // ]);
        return [...prevDepartments, department.departmentId];
      }
    });
  };


  // Function to handle user checkbox change
  const handleUserCheckboxChange = (user) => {
    const userId = user.id;
    const department = user.userDepartmentId;
    let updatedSelectedUsers = []
    let usersInDepartment = (data.find((item) => item.departmentId === department) || {}).userContactList || [];

    if (selectedDepartments.includes(department)) {
      updatedSelectedUsers = selectedUsers.includes(userId) ? selectedUsers.filter((u) => u !== userId) :
        [...selectedUsers, ...usersInDepartment.map((dep) => dep.id).filter((id) => id !== userId)];
    } else {
      updatedSelectedUsers = selectedUsers.includes(userId) ? selectedUsers.filter((u) => u !== userId) : [...selectedUsers, userId];
    }

    const areAllUsersChecked = usersInDepartment.every((u) => updatedSelectedUsers.map((selectedUser) => selectedUser).includes(u.id));


    if (areAllUsersChecked) {
      // If all users are checked, add the departmentId if not already added and remove the user 
      setSelectedDepartments((prevDepartments) =>
        prevDepartments.includes(department)
          ? prevDepartments
          : [...prevDepartments, department]
      );
      setSelectedUsers(updatedSelectedUsers.filter((u) => !usersInDepartment.map((dep) => dep.id).includes(u)))
    } else {
      setSelectedDepartments((prevDepartments) => prevDepartments.filter((dep) => dep !== department));

      setSelectedUsers(updatedSelectedUsers)
    }
  };



  // useEffect(() => {
  //   console.log("Updated checkedUsers:", selectedUsers);
  //   console.log("Updated Departments:", selectedDepartments);
  // }, [selectedUsers, selectedDepartments]);

  useEffect(() => {
    // const matchedTitles = searchTitle(searchText)
    findList.map((name) => {
      if (name === node.department) {
        setChildVisible(true);
      }
    });
  }, [findList, node]);


  useEffect(() => {
    if (node.treeLevel === 1 || selectedDepartments.includes(node.departmentId)) setChildVisible(true);
    // node.userContactList.map((el) => {
    //   if (el.parentId === 0) setChildVisible(true);
    // });
  }, []);


  const someFunction = async (email) => {
    // const client = new Client(token);
    // try {
    //   const user = await readUserProfile(email, client);
    //   if (user) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };


  const verifyUserChecked = (userId) => {
    if (selectedUsers.includes(userId)) {
      return true
    } else if (selectedDepartments.includes(node.departmentId)) {
      return true
    } else {
      return false
    }
  }


  return (
    <>
      <li className="d-tree-node border-0">
        <div
          className="d-flex align-items-center"
        // onClick={() => setChildVisible(!childVisible)}
        >
          {hasChild && (
            <>
              {childVisible ? (
                <RemoveIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              ) : (
                <AddIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              )}
            </>
          )}

          <div>
            <h4
              style={{ cursor: "pointer" }}
              className={`depart-head ${includesSearchTerm(node.department) && "highlight"
                }`}
            >
              {/* Checkbox for department */}
              <input
                className="checkbox-dept"
                type="checkbox"
                checked={selectedDepartments.includes(node.departmentId) ? true : false}
                onChange={() => handleDepartmentCheckboxChange(node)}
              />
              <div>{node.department}</div>
            </h4>
          </div>
        </div>

        {hasChild && childVisible && (
          <div className="d-tree-content">
            <div>
              <ul className="d-tree-container">
                {node.userContactList.map((item) => (
                  <li className={`d-tree-people`}>
                    <div className="d-people-node">
                      <span
                        className={`people-head ${item.userEmailId === searchEmail && "highlight"
                          } ${!someFunction(item.userEmailId) && "disable-user"}`}
                      >
                        <input
                          className="checkbox-dept"
                          type="checkbox"
                          checked={verifyUserChecked(item.id)}
                          onChange={() => handleUserCheckboxChange(item)}
                        />
                        {item.userName}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <ul className="d-flex d-tree-container flex-column">
              <Tree data={node.children} />
            </ul>
          </div>
        )}
      </li>

      {index !== totalNodes - 1 && <hr className="m-0" />}
    </>
  );
};

const OtherContactTree = ({ node, parentIndex, totalNodes }) => {
  const [childVisible, setChildVisible] = useState(false);
  const hasChild = node ? true : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.token);
  const { updateCurrentConversation, addNotifications, updateParticipants } =
    bindActionCreators(actionCreators, dispatch);
  const { client, updateConnectionState, updateConversation } = useClient();

  const { searchText, findList, searchEmail } = useContext(SearchContext);
  const { setSingleChart } = useContext(AccessContext);

  // const includesSearchTerm = (text) =>
  //   text.toLowerCase() === searchText.toLowerCase();

  useEffect(() => {
    // const matchedTitles = searchTitle(searchText)
    node.map((item) => {
      if (item.userName === searchText) {
        setChildVisible(true);
      }
    });
  }, [searchText]);

  const someFunction = async (email) => {
    // const client = new Client(token);
    // try {
    //   const user = await readUserProfile(email, client);
    //   if (user) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  return (
    <>
      <li className="d-tree-node border-0">
        <div
          className="d-flex align-items-center"
        // onClick={() => setChildVisible(!childVisible)}
        >
          {hasChild && (
            <>
              {childVisible ? (
                <RemoveIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              ) : (
                <AddIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              )}
            </>
          )}

          <div>
            <h4 style={{ cursor: "pointer" }} className={`depart-head`}>
              <div>
                <ToolTipHover
                  userExist={true}
                  title={"Click to Start a Group Chat"}
                  position={"right"}
                >
                  Other Contact List
                </ToolTipHover>
              </div>
            </h4>
          </div>
        </div>

        {hasChild && childVisible && (
          <div className="d-tree-content">
            <div>
              <ul className="d-tree-container">
                {node.map((item) => (
                  <li className={`d-tree-people`}>
                    <div className="d-people-node">
                      <span
                        className={`people-head ${item.userEmailId === searchEmail && "highlight"
                          } ${!someFunction(item.userEmailId) && "disable-user"}`}
                      >
                        <ToolTipHover
                          userExist={someFunction(item.userEmailId)}
                          title={`Click to Chat with ${item.userName}`}
                          position={"right"}
                        >
                          {item.userName}
                        </ToolTipHover>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* <ul className="d-flex d-tree-container flex-column">
              <Tree data={node.children} />
            </ul> */}
          </div>
        )}
      </li>

      {/* {parentIndex !== totalNodes - 1 && <hr className="m-0" />} */}
    </>
  );
};

export default Tree;
