import React, { useContext, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import AccessContext from '../../context/AccessContext'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import { extractDepartmentName, findDepartmentById } from '../../utils/helperPages'
import Select from "react-select";
import ThemeBtn from '../UI/ThemeBtn'
import { Col } from 'react-bootstrap'
import { AssignRuleToExistingUser, MoveUserLevel } from '../../axios/apis'
import { successNotification, unexpectedErrorNotification } from '../../helpers'

const MoveUserModal = ({ open, onClose, title, btn, fetchDetails }) => {

    const [submitloader, setSubmitLoader] = useState(false)
    const [isFinal, setIsFinal] = useState(false)

    const { singleUser, orgzName } = useContext(AccessContext)
    const [changeGroup, setChangeGroup] = useState({})

    const [userDetail, setUserDetail] = useState({
        name: singleUser.userName,
        email: singleUser.userEmailId,
        department: singleUser.userDepartment,
        newDepartmentId: ""
    })

    const [newDepart, setNewDepart] = useState(null)

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(
        actionCreators,
        dispatch
    );

    // console.log(singleUser)

    const handleDepartment = (e) => {
        if (e === null) {
            setNewDepart(null)
            setUserDetail({ ...userDetail, newDepartmentId: null })
        } else {
            setNewDepart(e)
            setUserDetail({ ...userDetail, newDepartmentId: e.value })
            setChangeGroup(findDepartmentById([orgzName.orgChart], e.value))
        }
    }



    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        try {
            const response = await MoveUserLevel({ userEmailId: userDetail.email, newDepartmentId: userDetail.newDepartmentId })
            if (response.status === 200) {
                const assignRule = await AssignRuleToExistingUser({ userEmailId: userDetail.email, ruleSetId: changeGroup?.departmentRuleSetId })
                if (assignRule.status === 200) {
                    successNotification({
                        message: `"${userDetail.name}" User is Successfully Moved under "${newDepart.label}".`,
                        addNotifications,
                    });
                    fetchDetails("")
                    setSubmitLoader(false)
                    onClose()
                }
            }
        } catch (error) {
            setSubmitLoader(false)
            unexpectedErrorNotification(error.response.data || "User not Modified Successfully", addNotifications);
            // console.log(error)
        }
    }

    const handleFinal = (e) => {
        e.preventDefault()
        setIsFinal(true)
    }


    return (
        <>
            {
                !isFinal ? <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleFinal} >
                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">Contact Name</label>
                            <div className="input-field">
                                <input value={userDetail.name} type="text" name="userName" required />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">Contact Email</label>
                            <div className="input-field">
                                <input type="text" value={userDetail.email} name="userEmailId" placeholder='user@gmail.com' pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" required />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">Current Group</label>
                            <div className="input-field">
                                <input type="text" value={userDetail.department} required />
                            </div>
                        </div>
                    </Col>

                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">Group</label>
                            <Select
                                className={`parent-custom`}
                                isClearable={true}
                                value={newDepart || null}
                                options={extractDepartmentName([orgzName.orgChart])}
                                onChange={handleDepartment}
                                required
                                // maxMenuHeight={'200px'}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </Col>

                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={onClose}>Cancel</button>
                        <ThemeBtn name={btn} type='submit' />
                    </div>
                </PopupLayout> : <PopupLayout open={open} onClose={onClose} title={"Move User Confirmation"} handleSubmit={handleSubmit} >
                    <Col lg={12}>
                        <div className="input-container">
                            <p><span style={{ color: "#961B13", fontWeight: 500 }}>{newDepart.label}</span> Group
                                rule will be applied by default on this user. Do you wish to continue ?
                            </p>
                        </div>
                    </Col>

                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={() => setIsFinal(false)}>Cancel</button>
                        <ThemeBtn name={"Continue"} type='submit' loader={submitloader} />
                    </div>
                </PopupLayout>
            }

        </>
    )
}

export default MoveUserModal