import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import AppReadyContext from "../../../context/AppReadyContext";
import MenuItems from "./MenuItems";
import { useNavigate } from "react-router-dom";
import { UNINav } from "../../../axios/apis";

const Navbar = () => {
  const [menus, setMenus] = useState([]);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const { setAppReady } = useContext(AppReadyContext);
  let ref = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchNavigationData(localStorage.getItem("accessToken"));
    }, 100); // Adjust the timeout duration as needed (in milliseconds)

    return () => {
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the effect re-runs
    };
  }, [accessToken]);

  const fetchNavigationData = async (accessToken) => {
    try {
      let response = await UNINav();
      localStorage.setItem("Navigation", JSON.stringify(response.data));

      if (localStorage.getItem("userType") === "DrayTalk-DrayTab") {
        const updatedResponse = response.data.map((menuItem) => {
          if (menuItem.name === "Chat") {
            return { ...menuItem, path: "/draytab/chat" };
          } else if (menuItem.name === "Contacts") {
            return { ...menuItem, path: "/draytab/contacts" };
          } else {
            return menuItem;
          }
        });
        response.data = updatedResponse; // Update the response data with the modified paths
      }
      if (response.data.length > 0) {
        const randomValue = new Date().getTime();
        setAppReady(randomValue);
      } else {
        navigate("/unauthorized");
      }
      const transformedMenus = transformMenuData(response.data);
      setMenus(transformedMenus);
    } catch (error) {
      console.error("Error fetching menu:", error);
    }
  };

  const transformMenuData = (data) => {
    return data.map((menuItem) => {
      const transformedItem = {
        title: menuItem.name,
        url: menuItem.subMenu ? null : menuItem.path,
        icon: menuItem.icon,
        submenu: null,
      };

      if (menuItem.subMenu && menuItem.subMenu.length > 0) {
        transformedItem.submenu = transformSubMenuData(menuItem.subMenu);
      }

      return transformedItem;
    });
  };

  const transformSubMenuData = (subMenu) => {
    return subMenu.map((subMenuItem) => {
      const transformedSubItem = {
        title: subMenuItem.name,
        url: subMenuItem.path || null,
        submenu: null,
      };

      if (subMenuItem.subMenu && subMenuItem.subMenu.length > 0) {
        transformedSubItem.submenu = transformSubMenuData(subMenuItem.subMenu);
      }

      return transformedSubItem;
    });
  };

  return (
    <nav className="top-nav-menu">
      <ul className="menus">
        {menus.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
