import React from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import { StyledDialog, StyledDialogContent, StyledDialogContentLoader, StyledDialogLoader } from '../UI/Popup/popupstyled'
import logo from "../../assets/logo-black.png"

const ChatLoaderModal = ({ open, onClose }) => {

    return (
        <>
            <StyledDialogLoader open={open} onClose={onClose}>
                <StyledDialogContentLoader className="chat-loader">
                    <div className="d-flex flex-column align-items-center">
                        <div>
                            <img src={logo} height={80} alt="Logo" />
                        </div>
                        <h3>Initiating the Chat...</h3>
                    </div>
                </StyledDialogContentLoader>
            </StyledDialogLoader>
        </>
    )
}

export default ChatLoaderModal