import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import logo from "../../assets/logo-black.png";
import { useNavigate } from "react-router-dom";

const LoginButton = () => {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    if (accounts.length > 0) {
      
    } else {
      const handleLogin = async () => {

        try {
          // Clear local storage
          // localStorage.clear();

          // Get the user's email from some source (e.g., a prompt)
          const userEmail = true;
          const loginResponse = null;
          // Initiate login
          if (userEmail) {
            // Initiate login with loginHint
            loginResponse = await instance.loginRedirect({
              loginHint: "",
            });
          }
        } catch (error) {
          console.error("Login error:", error);
        }
      };

      const timeoutId = setTimeout(() => {
        handleLogin();
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [instance]);

  return (
    <>
      <div className="loginWindow">
        <div>
          <img src={logo} height={80} alt="Logo" />
        </div>
        <h3>Redirecting to Login</h3>
        {/* <LoginButton /> */}
      </div>
    </>
  );
};

export default LoginButton;
