import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router";
import AppReadyContext from "../../context/AppReadyContext";
import { MessageContext } from "../../context/MessageContext";

const RedirectPage = () => {
  let { correlationId, notificationId } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { appReady, setAppReady } = useContext(AppReadyContext);
  const {
    message,
    setMessage,
    rejected,
    setRejected,
    rejectType,
    setRejectType,
    incomingMessage,
    setIncomingMessage,
  } = useContext(MessageContext);
  localStorage.clear;
  localStorage.setItem("correlationId", correlationId);
  useEffect(() => {
    const getToken = async (correlationId) => {
      try {
        const response = await axios.post(
          "https://draytalk-apim.azure-api.net/api/UserManagement/v1/VerifyUser",
          {
            correlationId: correlationId,
          }
        );
        localStorage.setItem('incomingCallId', notificationId);


        const token = response.data.access_token;
        localStorage.setItem("accessToken", token);
        localStorage.setItem("uuid", response.data.deviceId);
        const randomValue = new Date().getTime();
        setAppReady(randomValue);
        // Decode the token
        const decodedToken = jwtDecode(token);
        if (decodedToken) {
          const user = decodedToken.upn;
          localStorage.setItem("user", user);
          localStorage.setItem("userType", decodedToken.app_displayname);
        }
        // Check if the token is valid and active
        if (decodedToken && decodedToken.exp * 1000 > Date.now()) {
          // Redirect to /drayTab/chat page
          // navigate("/draytab/chat");
          location.replace("/draytab/chat");
        } else {
          // Handle invalid or expired token
          console.error("Invalid or expired token");
        }
        setLoading(false); // Set loading to false when token retrieval is complete
      } catch (error) {
        console.error("Error fetching token:", error);
        // Handle error condition here
      }

      // try {
      //   const response = await axios.get(
      //     `https://draytalk-apim.azure-api.net/FireBaseNotification/get-notification-id?notificationId=${notificationId}`
      //   );
      //   window.alert("Notification Info1");
      //   console.log("Notification Info: ", response.data);
      //   setMessage(response.data);
      //   window.alert("Notification Info2");
      // } catch (error) {
      //   console.error("Error fetching notification info:", error);
      // }
    };

    getToken(correlationId);
  }, [correlationId]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {loading ? <Spinner animation="border" role="status" /> : null}
    </div>
  );
};

export default RedirectPage;
