import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../context/SearchContext";
import AccessContext from "../../../context/AccessContext";
import Tree from "./Tree";
import { TreeData } from "../../../axios/apis";
import TreeRight from "./TreeRight";
import AppReadyContext from "../../../context/AppReadyContext";
import { ContactDetailsById } from '../../../axios/apis';

const TreeViewRight = () => {

    const { searchText, setFindList } = useContext(SearchContext);
    const { singleChart, isRefreshTree } = useContext(AccessContext);
    const [singleList, setSingleList] = useState([])

    const fetchSingleRecord = async () => {
        try {
            const res = await ContactDetailsById(singleChart)
            setSingleList([res.data])
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        fetchSingleRecord()
        // setFindList(findPathForName(sampleData, searchText))
    }, [singleChart, isRefreshTree])

    return (
        <div className='tree-container'>
            <div className="tree-box">
                <h3 className="tree-heading">
                    Contacts
                </h3>
                <div className="tree-box-inside">
                    <TreeRight searchTerm={searchText} data={singleList} />
                </div>
            </div>
        </div>
    )
}

export default TreeViewRight;
