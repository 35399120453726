import React from "react";
import { Box, Stack, Tooltip } from "@twilio-paste/core";
import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { useTheme } from "@twilio-paste/theme";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import styles from "../../styles";
import AvatarGroup from "../AvatarGroup";

const DEFAULT_MAX_DISPLAYED_PARTICIPANTS = 5;
const MAX_HIDDEN_PARTICIPANTS = 50;

interface ParticipantsViewProps {
  participants: ReduxParticipant[];
  onParticipantListOpen: () => void;
  maxDisplayedParticipants?: number;
}

function fetchName(participant: ReduxParticipant): string {
  let name: string = participant.identity ?? "unknown";
  if (participant.attributes != null) {
    const friendlyName: string | null =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      participant.attributes["friendlyName"];
    if (friendlyName != null) {
      name = friendlyName;
    }
  }
  return name;
}

const ParticipantsView: React.FC<ParticipantsViewProps> = ({
  participants,
  onParticipantListOpen,
  maxDisplayedParticipants = DEFAULT_MAX_DISPLAYED_PARTICIPANTS,
}: ParticipantsViewProps) => {
  const theme = useTheme();
  const displayedParticipants = participants.slice(0, maxDisplayedParticipants).map(fetchName);
  const hiddenParticipants = participants.slice(maxDisplayedParticipants).map(fetchName);

  return (
    <Stack orientation={["vertical", "horizontal", "horizontal"]} spacing="space30">
      <Button variant={"reset"} onClick={onParticipantListOpen}>
        <AvatarGroup names={displayedParticipants} />
      </Button>
      {hiddenParticipants.length > 0 ? (
        <Tooltip text={hiddenParticipants.join(", ")} placement={"bottom-start"}>
          <span
            style={{
              verticalAlign: "top",
              paddingRight: 10,
              color: theme.textColors.colorText,
              fontWeight: theme.fontWeights.fontWeightSemibold,
            }}
          >
            and {hiddenParticipants.length} other Participants
          </span>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

export default ParticipantsView;
