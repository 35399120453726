import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useContext, useEffect, useMemo, useState } from "react";

import { Client } from "@twilio/conversations";
import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import { actionCreators, AppState } from "../../store";
import ConversationDetails from "./ConversationDetails";
import MessagesBox from "../message/MessagesBox";
import MessageInputField from "../message/MessageInputField";
import styles from "../../styles";
import { ReduxConversation } from "../../store/reducers/convoReducer";

import { getSdkConversationObject } from "../../conversations-objects";
import { successNotification } from "../../helpers";
import { CONVERSATION_MESSAGES, ERROR_MODAL_MESSAGES } from "../../constants";
import ActionErrorModal from "../modals/ActionErrorModal";
import { AccessFlags, ContactDetailsById } from "../../axios/apis";
import CircleLoader from "../UI/CircleLoader";
import { extractNames } from "../../utils/helperPages";
import AccessContext from "../../context/AccessContext";

interface ConvoContainerProps {
  conversation?: ReduxConversation;
  client?: Client;
}

const ConversationContainer: React.FC<ConvoContainerProps> = (
  props: ConvoContainerProps
) => {
  const theme = useTheme();

  const { isChatAccess, setIsChatAccess } = useContext(AccessContext);
  const sid = useSelector((state: AppState) => state.sid);
  const loadingStatus = useSelector((state: AppState) => state.loadingStatus);
  const participants =
    useSelector((state: AppState) => state.participants)[sid] ?? [];
  const messages = useSelector((state: AppState) => state.messages);
  const typingData =
    useSelector((state: AppState) => state.typingData)[sid] ?? [];
  const lastReadIndex = useSelector((state: AppState) => state.lastReadIndex);
  const use24hTimeFormat = useSelector(
    (state: AppState) => state.use24hTimeFormat
  );
  const [accessData, setAccessData] = useState("");

  const [isAccess, setIsAccess] = useState<boolean>(false);

  const [pageloader, setPageloader] = useState(true);
  const dispatch = useDispatch();
  const { pushMessages, updateConversation, addNotifications } =
    bindActionCreators(actionCreators, dispatch);

  const [accessFlag, setAccessFlag] = useState(false);

  const [showError, setErrorToShow] = useState<
    | {
      title: string;
      description: string;
    }
    | false
  >();
  const [errorData, setErrorData] = useState<
    | {
      message: string;
      code: number;
    }
    | undefined
  >();
  const sdkConvo = useMemo(() => {
    if (props.conversation) {
      const conversation = getSdkConversationObject(props.conversation);
      if (conversation) {
        return conversation;
      }
    }
    return;
  }, [props.conversation?.sid]);

  useEffect(() => {
    const fetchConvoData = async () => {
      if (sid) {
        const response = await AccessFlags(sid);
        const myParticipantAccess = response.data.access.find(
          (access: {
            participantEmailId: string;
            participantAccess: boolean;
          }) => access.participantEmailId === localStorage.getItem("user")
        )?.participantAccess;
        setAccessFlag(myParticipantAccess);
        const participantId = response.data.access.find(
          (access: {
            participantEmailId: string;
            participantAccess: boolean;
          }) => access.participantEmailId !== localStorage.getItem("user")
        )?.participantEmailId;
        if (response.status === 200 && response.data.access.length === 2) {
          const contactDetails = await ContactDetailsById(
            localStorage.getItem("user")
          );
          const list = extractNames([
            contactDetails.data.completeOrgChart[0].orgChart,
          ]);
          // console.log(list.some((item) => item.value === participantId));
          setIsChatAccess(list.some((item) => item.value === participantId));
          setIsAccess(list.some((item) => item.value === participantId));
        }
        setAccessData(response.data.createdBy);
        setPageloader(false);
      }
    };
    setPageloader(true);
    fetchConvoData();

    return () => {
      setIsChatAccess(true);
    };
  }, [sid, props.conversation?.sid, props.conversation?.friendlyName]);

  return (
    <>
      <Box style={styles.convosWrapperBox}>
        <ActionErrorModal
          errorText={showError || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME}
          isOpened={!!showError}
          onClose={() => {
            setErrorToShow(false);
            setErrorData(undefined);
          }}
          error={errorData}
        />
        {sid && props.conversation && props.client ? (
          <>
            {pageloader ? (
              <CircleLoader full={true} />
            ) : (
              <>
                <ConversationDetails
                  convoSid={sid}
                  convo={props.conversation}
                  participants={participants}
                  client={props.client}
                  accessData={accessData}
                  isChatAccess={isChatAccess}
                  updateConvoName={(val: string) => {
                    sdkConvo
                      ?.updateFriendlyName(val)
                      .then((convo) => {
                        updateConversation(convo.sid, convo);
                        successNotification({
                          message: CONVERSATION_MESSAGES.NAME_CHANGED,
                          addNotifications,
                        });
                      })
                      .catch((e) => {
                        setErrorData(e);
                        setErrorToShow(
                          ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME
                        );
                      });
                  }}
                />

                <MessagesBox
                  key={sid}
                  convoSid={sid}
                  convo={props.conversation}
                  upsertMessage={pushMessages}
                  client={props.client}
                  messages={messages[sid]}
                  loadingState={loadingStatus}
                  participants={participants}
                  lastReadIndex={lastReadIndex}
                  use24hTimeFormat={use24hTimeFormat}
                />

                {accessFlag ? ( // Display MessageInputField only if accessFlag is true
                  <>
                    {isChatAccess ?
                      <MessageInputField
                        convoSid={sid}
                        client={props.client}
                        messages={messages[sid]}
                        convo={props.conversation}
                        typingData={typingData}
                        participants={participants}
                      />
                      : <>
                        <div className="no-access-bar">
                          Your access to this conversation has been removed. 
                        </div>
                      </>
                    }
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          <>
            <Box
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                fontSize: theme.fontSizes.fontSize30,
                fontWeight: theme.fontWeights.fontWeightNormal,
                lineHeight: "20px",
                color: theme.textColors.colorTextIcon,
              }}
            >
              Select a conversation on the left to get started.
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ConversationContainer;
