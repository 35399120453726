import React, { useContext, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import AccessContext from '../../../context/AccessContext';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store';
import { unexpectedErrorNotification } from '../../../helpers';

const UserDropDown = ({ item, organizationName }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);

    const { setSingleUser, setIsActiveState, setOrgzName } = useContext(AccessContext);

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDelete = () => {
        handleClose()
        setIsActiveState("DeleteUser")
        setSingleUser(item)
        setOrgzName(organizationName)
    }

    const handleModify = () => {
        handleClose()
        setIsActiveState("ModifyUser")
        setSingleUser(item)
        setOrgzName(organizationName)
    }

    const handleMove = () => {
        handleClose()
        setIsActiveState("MoveUser")
        setSingleUser(item)
        setOrgzName(organizationName)
    }

    const handleShow = () => {
        handleClose()
        if (item.userRuleSetId) {
            setIsActiveState("ShowRules")
            setSingleUser(item)
            setOrgzName(organizationName)
        } else {
            unexpectedErrorNotification("User Rules not exist", addNotifications);
        }
    }

    return (
        <>
            <MoreVertIcon onClick={(e) => setAnchorEl(e.currentTarget)} />
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className='depart-icon'
            >
                <MenuItem onClick={handleShow}>
                    <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                    User Info
                </MenuItem>
                <MenuItem onClick={handleModify}>
                    <ListItemIcon><DriveFileRenameOutlineOutlinedIcon /></ListItemIcon>
                    Modify User
                </MenuItem>
                <MenuItem onClick={handleMove}>
                    <ListItemIcon><RepeatOutlinedIcon /></ListItemIcon>
                    Move User
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
                    Delete User
                </MenuItem>
            </Menu>
        </>
    )
}

export default UserDropDown