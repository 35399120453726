import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-black.png";
import "./Logout.css";

const PostLogoutScreen = () => {
  let path = "/";
  const userType = localStorage.getItem("userType");
  if (userType === "DrayTalk-DrayTab") {
    path = `/redirect/${localStorage.getItem("correlationId")}`;
  }

  useEffect(() => {
    const checkElementExists = () => {
      const signOutStatusMessage = document.getElementsByClassName('nav-area');
      if (signOutStatusMessage.length>0) {
        location.reload();
      }
    };
  
    // Check if the element exists when the component mounts
    checkElementExists();
  
    // Check periodically if the element exists
    const intervalId = setInterval(checkElementExists, 1000); // Check every second
  
    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);
  

  return (
    <div>
      <div className="background" role="presentation"></div>
      <div className="outer">
        <div className="middle">
          <div className="inner ">
            <img
              className="banner-logo"
              role="presentation"
              src={logo}
              width={100}
              height={50}
            />
            <div role="alert" aria-live="assertive">
              <div className="row text-title">
                <div id="SignOutStatusMessage">
                  You signed out of your account
                </div>
              </div>
              <div className="row text-body text-block-body">
                <div id="SignOutStatusMessage2">
                  <p>
                    Click <Link to={path}>here</Link> to log in again.
                  </p>
                </div>
              </div>
            </div>
            <div
              id="InitiatorRedirectOption"
              className="row text-body text-block-body"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostLogoutScreen;
