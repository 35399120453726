import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Logout from '@mui/icons-material/Logout';
import ToolTipHover from './ToolTipHover';
import LogoutButton from '../logout/LogoutButton';
import { MessageContext } from '../../context/MessageContext';
import { useContext } from 'react';

const UserProfileMenu = ({ name, orgName, email }) => {
    const [isLogout, setIsLogout] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { soundOn, setSoundOn } = useContext(MessageContext);
    const { callSoundOn, setCallSoundOn } = useContext(MessageContext);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSoundToggle = () => {
        setSoundOn((prev) => !prev);
    };
    const handleSoundToggle2 = () => {
        setCallSoundOn((prev) => !prev);
    };

    const menuSx = {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    };

    const menuItemSx = {
        cursor: "auto",
        "& .MuiTypography-root": {
            userSelect: "text",
            cursor: "auto",
        },
    };

    const handleTextSelection = (event) => {
        event.stopPropagation();
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <ToolTipHover title="Account Details" position={"bottom"}>
                    <AccountCircleIcon onClick={handleClick} style={{ fontSize: "35px", color: "white" }} />
                </ToolTipHover>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: menuSx,
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem sx={menuItemSx} onClick={handleTextSelection} disableRipple>
                    <div sx={{ textAlign: "center" }}>
                        <Typography className='name-head' variant="h6">{name}</Typography>
                        {orgName && <Typography className='orgname-head' sx={{ color: "text.secondary" }}>{orgName}</Typography>}
                    </div>
                </MenuItem>
                {localStorage.getItem("userType") !== "DrayTalk-DrayTab" &&
                    <MenuItem sx={menuItemSx} onClick={handleTextSelection} disableRipple>
                        <div sx={{ textAlign: "center" }}>
                            <Typography className='orgname-email' sx={{ color: "text.secondary" }}>{email}</Typography>
                        </div>
                    </MenuItem>
                }
                <Divider />
                <MenuItem onClick={handleSoundToggle}>
                    <ListItemIcon>
                        {soundOn ? <VolumeUpIcon fontSize="small" /> : <VolumeOffIcon fontSize="small" />}
                    </ListItemIcon>
                    {soundOn ? "Message Sound On" : "Message Sound Off"}
                </MenuItem>
                <MenuItem onClick={handleSoundToggle2}>
                    <ListItemIcon>
                        {callSoundOn ? <VolumeUpIcon fontSize="small" /> : <VolumeOffIcon fontSize="small" />}
                    </ListItemIcon>
                    {callSoundOn ? "Incoming Call Sound On" : "Incoming Call Sound Off"}
                </MenuItem>
                <MenuItem onClick={() => setIsLogout(true)}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <LogoutButton isLogout={isLogout} profile={true} />
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserProfileMenu;
