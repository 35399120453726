import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dailyIcon from "../../../assets/daily.png";
import operationsIcon from "../../../assets/operations.png";
import financeIcon from "../../../assets/finance.png";
import onDemandIcon from "../../../assets/ondemand.png";
import dashboardIcon from "../../../assets/dashboard.png";
import containerIcon from "../../../assets/container_1.svg";
import containerManagementIcon from "../../../assets/import.png";
import exceptionsIcon from "../../../assets/exceptions.png";
import adminPortalIcon from "../../../assets/admin-portal.png";
import contactsIcon from "../../../assets/contacts.png"
import chatIcon from "../../../assets/chat.png";
import "./customStyles.css";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();

  //For Nav mouse events
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };
  const getIconForMenuItem = (iconName) => {
    switch (iconName) {
      case "chatIcon":
        return chatIcon;
      case "contactsIcon":
        return contactsIcon;
      case "containerManagementIcon":
        return containerManagementIcon;
      case "exceptionsIcon":
        return exceptionsIcon;
      case "adminIcon":
        return operationsIcon;
      case "accessManagementIcon":
        return financeIcon;
      case "userManagementIcon":
        return dailyIcon;
      default:
        return null;
    }
  };

  return (
    <li
      className={`custom-menu-items ${items.icon}`}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {/* <img height={24} src={dailyIcon} alt={items.icon} /> */}
      {/* {depthLevel === 0 && (
        <img
          height={24}
          src={getIconForMenuItem(items.icon)}
          alt={items.icon}
        />
      )} */}
      {items.url && items.submenu ? (
        <>
          <button
            className="nav-button"
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {window.innerWidth < 960 && depthLevel === 0 ? (
              items.title
            ) : (
              <Link to={items.url}>{items.title}</Link>
            )}

            {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel >
                0 && window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              // <KeyboardArrowDownRounded
              //   className="sub-menu-indicator"
              //   style={{ fontSize: 14 }}
              // />
              <ArrowDropDownIcon
                className="sub-menu-indicator"
                style={{ fontSize: 18 }}
              />
              // <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <div className="">
            {depthLevel === 0 && (
              <img
                height={24}
                src={getIconForMenuItem(items.icon)}
                alt={items.icon}
                className="img-size"
              />
            )}
            <button
              className="nav-button"
              type="button"
              aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              onClick={() => setDropdown((prev) => !prev)}
            >
              {items.title}{" "}
              {depthLevel > 0 ? (
                <span>&raquo;</span>
              ) : (
                <span className="arrow" />
              )}
            </button>
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
            />
          </div>
        </>
      ) : (
        <Link to={items.url}>
          <div className="d-flex flex-column">
            {depthLevel === 0 && (
              <img
                height={24}
                src={getIconForMenuItem(items.icon)}
                alt={items.icon}
                className="img-size"
              />
            )}
            <span>{items.title}</span>
          </div>
        </Link>
      )}
    </li>
  );
};

export default MenuItems;
