import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import { unexpectedErrorNotification } from '../../helpers'
import { RulesById, RulesList } from '../../axios/apis'
import PopupLayout from '../UI/Popup/PopupLayout'
import CircleLoader from '../UI/CircleLoader'
import { Col } from 'react-bootstrap'
import AccessContext from '../../context/AccessContext'

const ShowGroupRules = ({ open, onClose, title, btn, fetchDetails }) => {

    const [loader, setLoader] = useState(false)

    const { singleChart, orgzName } = useContext(AccessContext)

    const [ruleName, setRuleName] = useState("")
    const [departList, setDepartList] = useState([])
    const [userList, setUserList] = useState([])

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);

    const fetchRuleDetail = async () => {
        setLoader(true)
        try {
            const resRule = await RulesById(singleChart.departmentRuleSetId)
            const response = await RulesList(orgzName.organizationName)
            if (resRule.status === 200) {
                const isMatchRule = response.data.ruleSets.some((e) => e.ruleSetId === singleChart.departmentRuleSetId)
                setRuleName(isMatchRule ? singleChart.departmentRuleSetName : "Custom")
                setDepartList(resRule.data.departmentList)
                setUserList(resRule.data.userList)
                setLoader(false)
            }
        } catch (error) {
            unexpectedErrorNotification(error.response.data || "Unable to Load Details", addNotifications);
            console.log(error)
        }
    }

    useEffect(() => {
        fetchRuleDetail()
    }, [])

    return (
        <PopupLayout open={open} onClose={onClose} title={title} >
            {
                loader ? <CircleLoader /> : <>
                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">Name</label>
                            <div className="input-field">
                                <input value={singleChart.department} type="text" name="userName" readOnly />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">Rule Name</label>
                            <div className="input-field">
                                <input value={ruleName} type="text" name="userName" readOnly />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">Group List</label>
                            <div className="show-field">
                                {
                                    singleChart.departmentRuleSetName !== "Default" ? departList.map((el) => (
                                        <span>{el.departmentName}</span>
                                    )) : <span>All</span>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="input-box">
                            <label className='input-label' htmlFor="">User List</label>
                            <div className="show-field">
                                {
                                    singleChart.departmentRuleSetName !== "Default" ? userList.map((el) => (
                                        <span>{el.userName}</span>
                                    )) : <span>All</span>
                                }
                            </div>
                        </div>
                    </Col>

                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={onClose}>Close</button>
                    </div>
                </>
            }
        </PopupLayout>
    )
}

export default ShowGroupRules