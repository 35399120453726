import { createContext, useState } from "react";

const AccessContext = createContext();

export const AccessProvider = ({ children }) => {

  const [isChatActive, setIsChatActive] = useState(false)

  const [orgChart, setOrgChart] = useState([]);
  const [orgzName, setOrgzName] = useState("")

  const [isActiveState, setIsActiveState] = useState("")

  const [totalOrgChart, setTotalOrgChart] = useState([])

  const [singleChart, setSingleChart] = useState(null);
  const [singleUser, setSingleUser] = useState({})

  const [isRequest, setIsResuest] = useState(false)
  const [isFinalRequest, setIsFinalRequest] = useState(false)

  const [isRefreshTree, setIsRefreshTree] = useState(false)

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [userListLoader, setUserListLoader] = useState(true)

  const [isChatAccess, setIsChatAccess] = useState(true)


  return (
    <AccessContext.Provider
      value={{
        orgChart, setOrgChart, singleChart, setSingleChart, isRequest, setIsResuest, isFinalRequest, setIsFinalRequest,
        singleUser, setSingleUser, isChatActive, setIsChatActive, isRefreshTree, setIsRefreshTree,
        selectedDepartments, setSelectedDepartments, selectedUsers, setSelectedUsers,
        totalOrgChart, setTotalOrgChart, orgzName, setOrgzName, isActiveState, setIsActiveState, userListLoader, setUserListLoader, isChatAccess, setIsChatAccess
      }}
    >
      {children}
    </AccessContext.Provider>
  );
};

export default AccessContext;
