import React from "react";
import "./footer.css";
import logo from "../../assets/footer-logo.png"

const Footer = () => {
  return (
    <div className="footer">
      <span className="footer-text">
        <span className="powered">Powered by</span>{" "}
        <img className="footer-logo" src={logo} alt="" />
      </span>
    </div>
  );
};

export default Footer;
