import React, { useContext, useEffect, useState } from "react";
import UserAccessCheck from "../userAccess/userAccess";
import AppReadyContext from "../../context/AppReadyContext";
import PageHeader from "../UI/PageHeader";
import "./accessmang.css";
import TreeView from "./TreeView/TreeView";
import SearchNameDropDown from "../UI/SearchNameDropDown";
import { Container, Row, Col } from "react-bootstrap";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchContext from "../../context/SearchContext";
import { OrgChart, TreeData } from "../../axios/apis";
import AccessContext from "../../context/AccessContext";
import TreeViewRight from "./TreeView/TreeViewRight";
import AddContactModal from "./AddContactModal";
import RemoveContactModal from "./RemoveContactModal";

const AccessManagement = () => {

  const { appReady } = useContext(AppReadyContext);
  const { searchText, setSearchText, setSearchEmail } = useContext(SearchContext);
  const [orgList, setOrgList] = useState([]);
  const [isShow, setIsShow] = useState(false);

  const { singleChart, setOrgChart, setSingleChart, orgChart, isAddContact, setIsAddContact, isRemoveContact, setIsRemoveContact } = useContext(AccessContext);


  const fetchData = async () => {
    setSingleChart(null);
    setSearchText("");
    setSearchEmail("")
    setIsAddContact(false)
    try {
      const res1 = await OrgChart();
      setOrgList([res1.data]);
      setOrgChart([res1.data]);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [appReady]);

  useEffect(() => {
    if (singleChart !== null && searchText) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [singleChart, searchText]);

  return (
    <>
      <UserAccessCheck pageName={"Admin"} subPage={"Access Management"} />{" "}
      {appReady && (
        <>
          <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
            <PageHeader name={"Admin"} subName={"Access Management"} />
            <div className="search-box my-4">
              <label>
                Select the contact or level to add / modify the access:
              </label>
              <SearchNameDropDown
                title={"Enter Profile Name"}
                type={"search"}
                options={orgChart}
              />
            </div>
            <Container fluid className="p-0 mt-4">
              <Row>
                <Col lg={6}>
                  <TreeView list={orgList} />
                </Col>
                {isShow && (
                  <>
                    <Col lg={6}>
                      <TreeViewRight />
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </div>

          {
            isAddContact && <AddContactModal open={isAddContact} onClose={() => setIsAddContact(false)} title={"Add Contact"} btn={"Add"} fetchDetails={fetchData} />
          }
          {
            isRemoveContact && <RemoveContactModal open={isRemoveContact} onClose={() => setIsRemoveContact(false)} title={"Remove Contact"} btn={"Remove"} fetchDetails={fetchData} />
          }
        </>
      )}
    </>
  );
};

export default AccessManagement;
