import React from 'react'
import { IconButton } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { Container, Row } from 'react-bootstrap';
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from './popupstyled';
import "./popup.css"

const PopupLayout = ({ open, onClose, title, children, handleSubmit }) => {
    return (
        <>
            <StyledDialog open={open} onClose={onClose}>
                <StyledDialogTitle className="edit-title">
                    {title}
                    <IconButton edge="end" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </StyledDialogTitle>
                <StyledDialogContent className="edit-container">
                    <form onSubmit={handleSubmit}>
                        <Container fluid className="p-0">
                            <Row>
                                {children}
                            </Row>
                        </Container>
                    </form>
                </StyledDialogContent>
            </StyledDialog>
        </>
    )
}

export default PopupLayout