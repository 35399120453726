import React, { createContext, useContext, ReactNode, useState } from "react";
import { Client, ConnectionState, Conversation } from "@twilio/conversations";

type ClientContextProps = {
  client: Client | null;
  connectionState: ConnectionState | null;
  currentConversation: Conversation | null;
  updateClient: (client: Client) => void;
  updateConnectionState: (state: ConnectionState) => void;
  updateConversation: (conversation: Conversation | null) => void;
};

const ClientContext = createContext<ClientContextProps | undefined>(undefined);

export const ClientProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [client, setClient] = useState<Client | null>(null);
  const [connectionState, setConnectionState] =
    useState<ConnectionState | null>(null);
  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(null);

  const updateClient = (newClient: Client) => {
    setClient(newClient);
  };
  const updateConnectionState = (state: ConnectionState) => {
    setConnectionState(state);
  };
  const updateConversation = (conversation: Conversation | null) => {
    setCurrentConversation(conversation);
  };

  const value = {
    client,
    connectionState,
    currentConversation,
    updateClient,
    updateConnectionState,
    updateConversation,
  };

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export const useClient = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClient must be used within a ClientProvider");
  }
  return context;
};
