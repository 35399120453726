import React, { useContext, useEffect, useState } from "react";
import ThemeBtn from "../UI/ThemeBtn";
import PopupLayout from "../UI/Popup/PopupLayout";
import { Col } from "react-bootstrap";
import AccessContext from "../../context/AccessContext";
import Select from "react-select";
import { AssignRuleToExistingUser, RulesList, UpdateRole } from "../../axios/apis";
import { successNotification, unexpectedErrorNotification } from "../../helpers";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../store";
import { bindActionCreators } from "redux";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tree from "./TreeView/Tree-Custom";
import CreateCustomRule from "./TreeView/CreateCustomRule";
import RequiredAstrict from "../UI/RequiredAstrict";
import { findDepartmentById, labelConvert, labelConvertRules } from "../../utils/helperPages";
import RulesToolTip from "../UI/RulesToolTip";
import CircleLoader from "../UI/CircleLoader";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { optionsStyles } from "../../utils/helperInline";

const UpdateUserRole = ({ open, onClose, title, btn, data, profileList, fetchDetails }) => {

  const [formLoader, setFormLoader] = useState(false)
  const [submitloader, setSubmitLoader] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const userRole = localStorage.getItem("user-role")
  const userEmail = localStorage.getItem("user")

  const [accessRuleList, setAccessRuleList] = useState([]);
  const [originalRules, setOriginalRules] = useState([])


  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);

  const { singleUser, singleChart, setSelectedDepartments, setSelectedUsers, orgzName } = useContext(AccessContext);


  const [accessChanged, setAccessChanged] = useState(false)
  const [accessRule, setAccessRule] = useState({
    accessRuleId: singleUser?.userRuleSetId || null,
    accessRuleName: singleUser?.userRuleSetName || "",
  })

  const [userDetail, setUserDetail] = useState({
    name: singleUser?.userName,
    email: singleUser?.userEmailId,
    userRole: singleUser.userRole,
  });


  const handleAccessRuleChange = (e) => {

    if (e === null) {
      setAccessRule({ accessRuleId: "", accessRuleName: "" });
    } else {
      setAccessRule({ accessRuleId: e.value, accessRuleName: e.label });
      if (e.value === "custom") {
        setSelectedDepartments([]);
        setSelectedUsers([]);
      }
      setAccessChanged(true)
    }
  };

  const handleProfile = (e) => {
    if (e === null) {
      setUserDetail({ ...userDetail, userRole: "" });
    } else {
      setUserDetail({ ...userDetail, userRole: e.value });
    }
  };

  const handleChangeRole = () => {
    if (userEmail === userDetail.email) {
      window.location.reload();
      // localStorage.setItem("user-role", userDetail.userRole)
    } else {
      return
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (accessRule.accessRuleId === "custom") {
      setShowCustomForm(true);
    } else {
      if (singleUser.userRole !== userDetail.userRole && accessChanged) {
        setSubmitLoader(true);
        const payload = {
          userEmailId: userDetail.email,
          newRole: userDetail.userRole,
        };
        try {
          const response = await UpdateRole(payload);
          if (response.status === 200) {
            handleChangeRole()
            try {
              const resAssignRule = await AssignRuleToExistingUser({ userEmailId: userDetail.email, ruleSetId: accessRule.accessRuleId })
              if (resAssignRule.status === 200) {
                successNotification({
                  message: `User Profile Updated Successfully`,
                  addNotifications,
                });
                fetchDetails("");
                setSubmitLoader(false);
                onClose();
              }
            } catch (error) {
              unexpectedErrorNotification(error.response.data || "User Not Updated Successfully", addNotifications);
              setSubmitLoader(false);
            }
          }
        } catch (error) {
          unexpectedErrorNotification(error.response.data || "User Not Updated Successfully", addNotifications);
          setSubmitLoader(false);
        }
      } else if (singleUser.userRole !== userDetail.userRole) {
        const payload = {
          userEmailId: userDetail.email,
          newRole: userDetail.userRole,
        };
        try {
          const response = await UpdateRole(payload);
          if (response.status === 200) {
            handleChangeRole()
            successNotification({
              message: `User Profile Updated Successfully`,
              addNotifications,
            });
            fetchDetails("");
            setSubmitLoader(false);
            onClose();
          }
        } catch (error) {
          unexpectedErrorNotification(error.response.data || "User Not Updated Successfully", addNotifications);
          setSubmitLoader(false);
        }
      } else if (accessChanged) {
        try {
          const resAssignRule = await AssignRuleToExistingUser({ userEmailId: userDetail.email, ruleSetId: accessRule.accessRuleId })
          if (resAssignRule.status === 200) {
            successNotification({
              message: `User Profile Updated Successfully`,
              addNotifications,
            });
            fetchDetails("");
            setSubmitLoader(false);
            onClose();
          }
        } catch (error) {
          unexpectedErrorNotification(error.response.data || "User Not Updated Successfully", addNotifications);
          setSubmitLoader(false);
        }

      }
    }
  };



  const fetchRulesList = async () => {
    setFormLoader(false)
    try {
      const response = await RulesList(orgzName.organizationName)
      const isMatchRule = response.data.ruleSets.some((e) => e.ruleSetId === singleUser?.userRuleSetId)
      if (isMatchRule) {
        setAccessRule({ accessRuleId: singleUser?.userRuleSetId, accessRuleName: singleUser?.userRuleSetName })
      } else {
        setAccessRule({ accessRuleId: "custom", accessRuleName: "Custom" })
        setSelectedDepartments([]);
        setSelectedUsers([]);
      }
      const parentDepartment = findDepartmentById([orgzName.orgChart], singleUser.userDepartmentId)
      const inheritItem = { ruleSetId: parentDepartment.departmentRuleSetId, ruleSetName: "Inherit from Parent" };
      const customItem = { ruleSetId: "custom", ruleSetName: "Custom" };
      const currentRuleSets = response.data.ruleSets;

      // Find the index of the "default" item
      const defaultItemIndex = currentRuleSets.findIndex(item => item.ruleSetName === "Default");

      let updatedRuleSets = [];
      updatedRuleSets.push(inheritItem);

      // Insert the "custom" item after the "default" item if found
      if (defaultItemIndex !== -1) {
        updatedRuleSets.push(currentRuleSets[defaultItemIndex]);
        updatedRuleSets.push(customItem);
        updatedRuleSets.push(...currentRuleSets.slice(defaultItemIndex + 1));
      } else {
        // If "default" item not found, insert "custom" item at index 1
        updatedRuleSets.push(customItem);
        updatedRuleSets.push(...currentRuleSets);
      }

      setAccessRuleList(updatedRuleSets);
      setOriginalRules(currentRuleSets);
      setFormLoader(true)
    } catch (error) {
      setFormLoader(true)
      unexpectedErrorNotification({ message: "Rules List is Not loaded", addNotifications });
      // console.log(error)
    }
  }



  useEffect(() => {
    fetchRulesList()
  }, [])


  const handleBack = () => {
    setShowCustomForm(false);
    setAccessRule({ accessRuleId: null, accessRuleName: "" });
  }

  const customHeading = (
    <span>
      Update User <ArrowForwardIcon className="head-arrow-icon-popup" /> Create Custom Rule
    </span>
  )


  return (
    <>
      <PopupLayout open={open} onClose={onClose} title={!showCustomForm ? title : customHeading} handleSubmit={handleSubmit}>
        {
          !formLoader ? <CircleLoader /> : <>
            {
              showCustomForm ? <CreateCustomRule open={showCustomForm} onBack={handleBack} data={userDetail} fetchData={fetchDetails} modal="User" type="Update" />
                : <>

                  <Col lg={12}>
                    <div className="input-box">
                      <label className="input-label" htmlFor="">
                        Contact Name
                      </label>
                      <div className="input-field">
                        <input value={userDetail.name} readOnly type="text" name="userName" required />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="input-box">
                      <label className="input-label" htmlFor="">
                        Contact Email
                      </label>
                      <div className="input-field">
                        <input type="text" value={userDetail.email} readOnly name="userEmailId" placeholder="user@gmail.com" pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" required />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="input-box">
                      <label className="input-label" htmlFor="">
                        User Role
                      </label>
                      <Select
                        className={`parent-custom`}
                        isClearable={true}
                        value={
                          userDetail.userRole
                            ? {
                              label: userDetail.userRole,
                              value: userDetail.userRole,
                            }
                            : null
                        }
                        options={labelConvert(profileList)}
                        onChange={handleProfile}
                        maxMenuHeight={"200px"}
                        // required
                        components={{
                          IndicatorSeparator: () => null,
                        }} />

                    </div>
                  </Col>

                  <div className="input-box">
                    <label className="input-label" htmlFor="">
                      Access Rule
                    </label>
                    <Select
                      className={`parent-custom`}
                      isClearable={true}
                      value={accessRule.accessRuleId ? { label: accessRule.accessRuleName, value: accessRule.accessRuleId } : null}
                      options={labelConvertRules(accessRuleList)}
                      onChange={handleAccessRuleChange}
                      required
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={optionsStyles(accessRule.accessRuleName, accessRule.accessRuleId)}
                    />
                    {accessRule.accessRuleName !== "Custom" && accessRule.accessRuleName &&
                      <RulesToolTip list={originalRules} selectedId={accessRule.accessRuleId} selectedName={accessRule.accessRuleName} type="update" />
                    }

                  </div>

                  <div className="action-btn">
                    <button className="cancel-btn" onClick={onClose}>
                      Cancel
                    </button>
                    <ThemeBtn name={accessRule.accessRuleId === "custom" ? "Next" : "Submit"} loader={submitloader} type="submit" />
                  </div>

                </>
            }
          </>

        }
      </PopupLayout>
    </>
  );
};

export default UpdateUserRole;
