import React, { useContext, useEffect, useState } from "react";
import "./view.css";
import Tree from "./Tree";
import SearchContext from "../../context/SearchContext";
import { TreeData } from "../../axios/apis";
import { findPathForName } from "../../utils/helperPages";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const TreeView = ({ singleList }) => {
  const { searchText, setFindList, searchEmail, orgDetails } = useContext(SearchContext);


  useEffect(() => {
    if (searchText && searchEmail) {
      setFindList(findPathForName([orgDetails], searchEmail))
    } else {
      setFindList(findPathForName([orgDetails], searchText))
    }
  }, [searchText, searchEmail])

  return (
    <div className="tree-container">
      <div className="tree-box">
        <h3 className="tree-heading">Contacts List</h3>
        <div className="tree-box-inside">
          <ul className="d-flex d-tree-container flex-column">
            {
              singleList.map((item) => (
                <TreeOrg searchTerm={searchText} node={item} />
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

const TreeOrg = ({ node }) => {

  const [childVisible, setChildVisible] = useState(true);

  const hasChild = node.orgChart ? true : false;

  return (
    <>
      <li className="d-tree-node border-0">
        <div className="d-flex align-items-center">
          <div onClick={() => setChildVisible(!childVisible)}>
            {hasChild && (
              <>
                {childVisible ? (
                  <RemoveIcon className="d-tree-icon" />
                ) : (
                  <AddIcon className="d-tree-icon" />
                )}
              </>
            )}
          </div>

          <div className='user-head'>
            <h4 className={`depart-head`}>
              {node.organizationName}
            </h4>
          </div>

        </div>
        {hasChild && childVisible && <>
          <div className="tree-box-inside">
            <Tree data={[node.orgChart]} organizationName={node} />
          </div>
        </>
        }
      </li>
    </>
  )
}

export default TreeView;
