import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./usermang.css"

import UserAccessCheck from '../userAccess/userAccess'
import AppReadyContext from '../../context/AppReadyContext';
import AccessContext from '../../context/AccessContext';
import SearchContext from '../../context/SearchContext';

import PageHeader from '../UI/PageHeader';
import SearchNameDropDown from '../UI/SearchNameDropDown';
import CircleLoader from '../UI/CircleLoader';

import PageModalsComp from './PageModalsComp';
import TreeView from './TreeView/TreeView';
import AddUser from './AddUser';
import AddHierachy from './AddHierachy';

import { CustomerList, RolesList, TotalOrgChart } from '../../axios/apis';
import OrgNameDropDOwn from '../UI/OrgNameDropDOwn';
import ReportContext from '../../context/ReportContext';
import ThemeBtn from '../UI/ThemeBtn';
import AddOrganization from './AddOrganization';

const UserManagement = () => {

    const { setReportType } = useContext(ReportContext);
    setReportType("adminIcon");

    const { appReady } = useContext(AppReadyContext);
    const [pageloader, setPageLoader] = useState(false)

    const userRole = localStorage.getItem("user-role")
    const [profileList, setProfileList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [totalOrg, setTotalOrg] = useState([])
    const [orgNameList, setOrgNameList] = useState([])
    const [isAddOrg, setIsAddOrg] = useState(false)

    const { searchText, setSearchText, setSearchEmail, searchOrg, setSearchOrg } = useContext(SearchContext);

    const { setTotalOrgChart, setSingleChart, orgChart, isActiveState, setIsActiveState, setOrgzName } = useContext(AccessContext);


    const fetchData = async () => {
        'use server'
        setPageLoader(false)
        setSingleChart(null);
        setIsActiveState("")
        setSearchOrg({ name: null, id: null })
        setSearchText("");
        setSearchEmail("")
        setOrgzName("")
        try {
            const responseOrg = await TotalOrgChart() //Giving the total Org Chart 
            const resCustomer = await CustomerList() // Gives the Customer name like drayTalk.. etc
            const resProfile = await RolesList() // Gives the Roles List like admin or super admin 
            if (userRole === "Super Admin") {
                setProfileList(resProfile.data.roles)
            } else if (userRole === "Customer Admin") {
                setProfileList(resProfile.data.roles.filter((item) => item !== "Super Admin"))
            }
            setCustomerList(resCustomer.data.customers)
            setTotalOrg(responseOrg.data.completeOrgChart)
            setTotalOrgChart(responseOrg.data.completeOrgChart)
            const arr = []
            responseOrg.data.completeOrgChart.map((item) => {
                arr.push({ organizationName: item.organizationName, organizationId: item.organizationId })
            })
            setOrgNameList(arr)
            setPageLoader(true)
        } catch (error) {
            // console.log(error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [appReady]);


    return (
        <>
            <UserAccessCheck pageName={"Admin"} subPage={"User Management"} />
            {
                appReady && (
                    !pageloader ? <CircleLoader full={true} /> : <>
                        <div className="smy-ctr container-fluid page-content-main">
                            <PageHeader name={"Admin"} subName={"User Management"} />

                            <div className="search-box my-4">
                                <div className='searc-box-field'>
                                    <label>Organization:</label>
                                    <OrgNameDropDOwn title={"Enter Organization Name"} type={"search"} options={totalOrg} />
                                </div>

                                {
                                    searchOrg.id && <div className='searc-box-field'>
                                        <label>Users/Groups:</label>
                                        <SearchNameDropDown title={"Enter Profile Name"} type={"search"} options={orgChart} />
                                    </div>
                                }

                                {
                                    userRole === "Super Admin" && <div className='d-flex justify-content-end org-btn'>
                                        <ThemeBtn name={"Add Organization"} handleClick={() => setIsAddOrg(true)} />
                                    </div>
                                }
                            </div>



                            <Container fluid className="p-0 mt-4">
                                <Row>
                                    <Col lg={6} className='mb-3'>
                                        <TreeView orgList={totalOrg} fetchData={fetchData} />
                                    </Col>
                                    <Col lg={6}>
                                        {
                                            isActiveState === "AddUser" && <AddUser customerList={customerList} profileList={profileList} fetchData={fetchData} />
                                        }
                                        {
                                            isActiveState === "AddGroup" && <AddHierachy fetchData={fetchData} />
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        {
                            isAddOrg && <AddOrganization open={isAddOrg} onClose={() => setIsAddOrg(false)} title={"Add New Organization"} btn={"Add"} fetchDetails={fetchData} />
                        }

                        <PageModalsComp fetchData={fetchData} profileList={profileList} />
                    </>
                )
            }
        </>
    )
}

export default UserManagement
