import React, { useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { InteractionType } from "@azure/msal-browser";
import AppReadyContext from "../../context/AppReadyContext";
import { useNavigate } from "react-router";
import { UNINav } from "../../axios/apis";

function TokenValidityChecker() {
  const { instance, accounts } = useMsal();
  const { appReady, setAppReady } = useContext(AppReadyContext);
  const navigate = useNavigate();
  const checkTokenValidity = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const searchUser = "vidya sagar";
    const userEmail = "vidya.sagar554@live.com";
    const displayName = "Vidya Bandaru";

    const clientId = "cc55937c-d978-404d-ad2d-37e994602200";
    const clientSecret = "EVl8Q~.rziSkUpuwkafbKCUf8cktuMw8hgt4tbSu";
    const tenantId = "c2486751-438c-4293-8e06-063e4e40a672";
    const SCOPE = "https%3A%2F%2Fgraph.microsoft.com%2F.default";
    const MSFT = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
    // secret_value="EVl8Q~.rziSkUpuwkafbKCUf8cktuMw8hgt4tbSu";
    // secretId = "ac7ded16-a566-42f5-bc1f-c74f48fa4550";
    const resource = "https://graph.microsoft.com"; // This is the resource URI for Microsoft Graph

    // const getToken = async () => {
    //   const tenantId = "c2486751-438c-4293-8e06-063e4e40a672";
    //   const clientId = "cc55937c-d978-404d-ad2d-37e994602200";
    //   const clientSecret = "EVl8Q~.rziSkUpuwkafbKCUf8cktuMw8hgt4tbSu";

    //   const tokenEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;

    //   const myHeaders = new Headers();
    //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    //   const urlencoded = new URLSearchParams();
    //   urlencoded.append("grant_type", encodeURIComponent("client_credentials"));
    //   urlencoded.append("client_id", encodeURIComponent(clientId));
    //   urlencoded.append("client_secret", encodeURIComponent(clientSecret));
    //   urlencoded.append(
    //     "scope",
    //     encodeURIComponent("https://graph.microsoft.com/.default")
    //   );

    //   const requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: urlencoded.toString(),
    //     // redirect: "follow",
    //   };

    //   try {
    //     const response = await fetch(tokenEndpoint, requestOptions);
    //     const data = await response.json();

    //     if (response.ok) {
    //       const accessTokenNew = data.access_token;
    //       console.log("Access Token:", accessTokenNew);
    //       return accessTokenNew;
    //     } else {
    //       console.error(
    //         "Error fetching token:",
    //         data.error_description || data.error
    //       );
    //       return null;
    //     }
    //   } catch (error) {
    //     console.error("Error:", error);
    //     return null;
    //   }
    // };

    // const fetchToken = async () => {
    //   const accessTokenNew = await getToken();
    //   console.log(accessTokenNew);
    // };

    // fetchToken();

    if (accessToken) {
      // const response = await axios.get(
      //   `https://graph.microsoft.com/v1.0/users?$filter=startswith(mail, '${userEmail}')`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${accessToken}`,
      //     },
      //   }
      // );
      // console.log("Response:", response);
      // if (response.data.value.length === 0) {
      //   const response2 = await axios.post(
      //     "https://graph.microsoft.com/v1.0/invitations",
      //     {
      //       invitedUserEmailAddress: userEmail,
      //       invitedUserDisplayName: displayName,
      //       inviteRedirectUrl: "http://localhost:3000",
      //       sendInvitationMessage: true,
      //       invitedUserMessageInfo: {
      //         customizedMessageBody:
      //           "You're invited to join our organization!",
      //       },
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${accessToken}`,
      //       },
      //     }
      //   );
      // } else {
      //   console.log("User Exists");
      // }

      // console.log("User added to Azure user list:", response2.data);

      if (accounts.length > 0) {
        try {
          const response = await UNINav();
          if (response.status === 200) {
            const decodedToken = jwtDecode(accessToken);
            const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
            const validityDuration = decodedToken.exp - currentTime;
            //3600 is 1 hour
            if (validityDuration < 360) {
              // console.log("Token validity is less than 1 hour, refreshing...");
              refreshToken();
            } else {
            }
          } else if (response.status === 403) {
            refreshToken();
          } else {
            instance.logout();
          }
        } catch (error) {
          console.error("Error checking token validity:", error);
          refreshToken();
        }
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        account: accounts[0],
        scopes: ["user.read"],
        forceRefresh: true, // Set this to "true" to force a token refresh
      });

      localStorage.setItem("accessToken", response.accessToken);

      // Refresh the page to reflect the new token
      // navigate("/");
      const randomValue = new Date().getTime();
      setAppReady(randomValue);
      // window.location.reload();
    } catch (error) {
      console.error("Token refresh error:", error);

      instance.logout();
    }
  };

  useEffect(() => {
    const handleRedirect = () => {
      checkTokenValidity();
    };

    instance.addEventCallback(handleRedirect);

    // Check token validity when the page loads
    checkTokenValidity();

    // Set up an interval to check token validity every 10 minutes (600,000 milliseconds)
    const intervalId = setInterval(() => {
      checkTokenValidity();
    }, 600000);

    return () => {
      instance.removeEventCallback(handleRedirect);
      clearInterval(intervalId);
    };
  }, [instance, accounts, appReady]);

  return null;
}

export default TokenValidityChecker;
