import React from 'react'
import "./circleloader.css"

const CircleLoader = ({ full = false }) => {
    return (
        <div className={`circle-loader ${full && "full-loader-height "}`}>
            <svg className={`${full ? "size-full" : "size-small"}`} viewBox="25 25 50 50">
                <circle r="20" cy="50" cx="50"></circle>
            </svg>
        </div>
    )
}

export default CircleLoader