import React, { useContext, useEffect, useState } from 'react'
import SearchContext from '../../../context/SearchContext';
import Tree from './Tree';
import { findPathForName } from '../../../utils/helperPages';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ToolTipHover from '../../UI/ToolTipHover';
import AddRootGroup from '../AddRootGroup';

const TreeView = ({ orgList, fetchData }) => {

    const { searchText, setFindList, searchEmail, orgDetails } = useContext(SearchContext);


    useEffect(() => {
        if (searchText && searchEmail) {
            setFindList(findPathForName([orgDetails], searchEmail))
        } else {
            setFindList(findPathForName([orgDetails], searchText))
        }
    }, [searchText, searchEmail])


    return (
        <div className='tree-container'>
            <div className="tree-box">
                <h3 className="tree-heading">
                    Organization Tree
                </h3>
                <div className="tree-box-inside">
                    <ul className="d-flex d-tree-container flex-column">
                        {
                            orgList.map((item) => (
                                <TreeOrg searchTerm={searchText} node={item} fetchData={fetchData} />
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

const TreeOrg = ({ node, fetchData }) => {

    const [isAddGroup, setIsAddGroup] = useState(false)
    const { searchOrg, searchText, searchEmail } = useContext(SearchContext);

    const [childVisible, setChildVisible] = useState(false);

    const hasChild = node.orgChart ? true : false;

    useEffect(() => {
        if (searchOrg.id === node.organizationId) {
            setChildVisible(true)
        }
    }, [searchText, searchEmail])

    return (
        <>
            <li className="d-tree-node border-0">
                <div className="d-flex align-items-center">
                    <div onClick={() => setChildVisible(!childVisible)}>
                        {hasChild && (
                            <>
                                {childVisible ? (
                                    <RemoveIcon className="d-tree-icon" />
                                ) : (
                                    <AddIcon className="d-tree-icon" />
                                )}
                            </>
                        )}
                    </div>

                    <div className='user-head'>
                        <h4 className={`depart-head`}>
                            {node.organizationName}
                        </h4>
                        <div className="depart-icon">
                            {
                                !node.orgChart &&
                                <ToolTipHover title="Add First Group" position="top">
                                    <AddBoxOutlinedIcon onClick={() => setIsAddGroup(true)} />
                                </ToolTipHover>
                            }
                        </div>
                    </div>

                </div>
                {hasChild && childVisible && <>
                    <div className="tree-box-inside">
                        <Tree data={[node.orgChart]} organizationName={node} />
                    </div>
                </>
                }
            </li>

            {
                isAddGroup && <AddRootGroup data={node} open={isAddGroup} onClose={() => setIsAddGroup(false)} title={"Add Root Group"} btn={"Add"} fetchDetails={fetchData} />
            }
        </>
    )
}

export default TreeView