import React, { useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import { Col } from 'react-bootstrap'
import ThemeBtn from '../UI/ThemeBtn'
import { successNotification } from '../../helpers'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import { useDispatch } from 'react-redux'
import { ApproveDeclineUser } from '../../axios/apis'

const ApprovalModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const [submitloader, setSubmitLoader] = useState(false)

    const dispatch = useDispatch();
    const { updateCurrentConversation, addNotifications } = bindActionCreators(
        actionCreators,
        dispatch
    );

    const handleApprove = async () => {
        setSubmitLoader(true)
        const payload = {
            requestId: data.requestId,
            request_Action: btn
        }
        try {
            const response = await ApproveDeclineUser(payload)
            successNotification({
                message: `User ${btn} Successfully`,
                addNotifications,
            });
            fetchDetails("")
            setSubmitLoader(false)
            onClose()
        } catch (error) {
            // console.log(error)
            setSubmitLoader(false)
            onClose()
        }
    }

    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} >
                <Col lg={12}>
                    <div className="input-container">
                        {
                            btn === "Approved" ? <p>To <span style={{ color: "#961B13", fontWeight: 500 }}>Approve</span> the User Request Click on the Approve Button</p> : <p>To <span style={{ color: "#961B13", fontWeight: 500 }}>Decline</span> the User Request Click on the Decline Button</p>
                        }
                    </div>
                </Col>

                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    <ThemeBtn name={btn === "Approved" ? "Approve" : "Decline"} loader={submitloader} handleClick={handleApprove} />
                </div>
            </PopupLayout>
        </>
    )
}

export default ApprovalModal