import React from "react";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

const PageHeader = ({ name, subName, subPath, subName2 }) => {
  let path = "/";
  const userType = localStorage.getItem("userType");
  if (userType == "DrayTalk-DrayTab") {
    path = "/draytab/chat";
  }
  return (
    <div className="breadcrumb-custom">
      <Link to={path} style={{ textDecoration: "none", color: "black" }}>
        <HomeOutlinedIcon />
      </Link>
      &nbsp;
      <KeyboardArrowRightOutlinedIcon /> &nbsp;
      <span className="breadcrumb-text">{name}</span>&nbsp;
      {subName && (
        <>
          &nbsp;
          <Link to={subPath} style={{ textDecoration: "none", color: "black" }}>
            <KeyboardArrowRightOutlinedIcon /> &nbsp;
            <span className="breadcrumb-text">{subName}</span>&nbsp;
          </Link>
        </>
      )}
      {subName2 && (
        <>
          &nbsp;
          <KeyboardArrowRightOutlinedIcon /> &nbsp;
          <span className="breadcrumb-text">{subName2}</span>
        </>
      )}
    </div>
  );
};

export default PageHeader;
