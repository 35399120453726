import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AssignCustomRuleToExistingGroup, AssignRuleToExistingDepartment, CreateRuleSet } from '../../axios/apis'
import { actionCreators } from '../../store'
import AccessContext from '../../context/AccessContext'
import PopupLayout from '../UI/Popup/PopupLayout'
import { Col } from 'react-bootstrap'
import ThemeBtn from '../UI/ThemeBtn'
import RequiredAstrict from '../UI/RequiredAstrict'
import { successNotification, unexpectedErrorNotification } from '../../helpers'
import { generateUniqueValue } from '../../utils/helperPages'

const GroupRuleAddModal = ({ open, onClose, levelData, title, btn, fetchDetails }) => {

    const [submitloader, setSubmitLoader] = useState(false)

    const [departData, setDepartData] = useState(levelData)

    const [isStored, setIsStored] = useState("")
    const [ruleName, setRuleName] = useState("")


    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);

    const { selectedDepartments, selectedUsers, isAddrule, setIsAddRule, orgzName } = useContext(AccessContext);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)

        if (isStored === "yes") {
            const payloadRule = {
                customer: orgzName.organizationName,
                ruleSetName: ruleName,
                departmentIdList: selectedDepartments,
                userIdList: selectedUsers
            }
            try {
                const resRule = await CreateRuleSet(payloadRule)
                if (resRule.status === 200) {
                    try {
                        const resDepart = await AssignRuleToExistingDepartment({ departmentId: levelData.departmentId, newDepartmentName: levelData.departmentName, ruleSetId: resRule.data.ruleSetId })
                        if (resDepart.status === 200) {
                            successNotification({
                                message: "Group Updated Successfully",
                                addNotifications,
                            });
                            setSubmitLoader(false)
                            onClose()
                            fetchDetails("")
                        }
                    } catch (error) {
                        unexpectedErrorNotification(error.response?.data || "An error occurred while Updating the Groups", addNotifications);
                        setSubmitLoader(false)
                        // console.log(error)
                    }
                }
            } catch (error) {
                setSubmitLoader(false)
                onClose()
                unexpectedErrorNotification(error.response?.data || "An error occurred while Updating the Groups", addNotifications);
                // console.log(error)
            }
        } else {
            const payload = {
                departmentId: levelData.departmentId,
                newDepartmentName: levelData.departmentName,
                departmentRuleSet: {
                    customer: orgzName.organizationName,
                    ruleSetName: `Rule_${generateUniqueValue()}`,
                    departmentIdList: selectedDepartments,
                    userIdList: selectedUsers
                }
            }
            try {
                const resDepart = await AssignCustomRuleToExistingGroup(payload)
                if (resDepart.status === 200) {
                    successNotification({
                        message: "Group Updated Successfully",
                        addNotifications,
                    });
                    setSubmitLoader(false)
                    onClose()
                    fetchDetails("")
                }
            } catch (error) {
                setSubmitLoader(false)
                onClose()
                unexpectedErrorNotification(error.response?.data || "An error occurred while Updating the Group", addNotifications);
                // console.log(error)
            }
        }

    }


    return (
        <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit} >

            <Col lg={12}>
                <div className="input-container">
                    <p className='popup-text'>Do you want to Save current selection as Stored Rule?</p>
                    <div className='radio-input'>
                        <input type="radio" name="stored" value="yes" onChange={(e) => setIsStored(e.target.value)} id="" />
                        <span>Yes</span>
                    </div>
                    <div className='radio-input'>
                        <input type="radio" name="stored" value="no" onChange={(e) => setIsStored(e.target.value)} id="" />
                        <span>No</span>
                    </div>
                </div>
            </Col>

            {
                isStored === "yes" && <Col lg={12}>
                    <div className="input-box">
                        <label className='input-label' htmlFor="">Rule Name <RequiredAstrict /></label>
                        <div className="input-field">
                            <input type="text" name="userName" value={ruleName} onChange={(e) => setRuleName(e.target.value)} required />
                        </div>
                    </div>
                </Col>

            }

            <div className='d-flex justify-content-end gap-4'>
                <button className='cancel-btn' onClick={onClose}>Cancel</button>
                <ThemeBtn name={btn} loader={submitloader} type="submit" />
            </div>
        </PopupLayout>
    )
}

export default GroupRuleAddModal