import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Unauthorized() {
  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Box className="unauthorized"
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          {' '}
          <Container maxWidth='sm'>
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='text.primary'
              gutterBottom
            >
              Unauthorized Access
            </Typography>
            <Typography
              variant='h5'
              align='center'
              color='text.secondary'
              paragraph
            >
              You don't have permission to view this page.
              Contact Administrator.
            </Typography>
          </Container>
        </Box>
      </React.Fragment>
    </>
  );
}
