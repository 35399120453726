import React, { useContext, useEffect, useState } from 'react'
import "./view.css"
import Tree from './Tree'
import SearchNameDropDown from '../../UI/SearchNameDropDown'
import SearchContext from '../../../context/SearchContext'
import AccessContext from '../../../context/AccessContext'
import { findPathForName } from '../../../utils/helperPages'

const TreeView = ({ list }) => {

    const { searchText, setFindList, searchEmail } = useContext(SearchContext);
    const { orgChart } = useContext(AccessContext);


    useEffect(() => {
        if (searchText && searchEmail) setFindList(findPathForName(list, searchEmail))
    }, [searchText, searchEmail])

    return (
        <div className='tree-container'>
            <div className="tree-box">
                <h3 className="tree-heading">
                    Organization Tree
                </h3>
                <div className="tree-box-inside">
                    <Tree searchTerm={searchText} data={list} />
                </div>
            </div>
        </div>
    )
}

export default TreeView