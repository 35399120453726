import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from "@mui/material"
import { tableHead, tableRow } from '../../../utils/helperInline'
import RulestableRow from './RulestableRow'

const RulesTable = ({ data, sortBy, sortOrder, page, rowsPerPage, handleSort }) => {

    const tableHeading = [
        { title: "Rule Name", sort: "as" },
        { title: "No of Groups", sort: "qw" },
        { title: "No of User", sort: "ee" },
        // { title: "Created Date", sort: "ew" },
    ]

    return (
        <Table>
            <TableHead sx={tableHead}>
                <TableRow>
                    {
                        tableHeading.map((item) => (
                            <TableCell>
                                <TableSortLabel
                                    active={sortBy === item.sort}
                                    direction={sortOrder}
                                    onClick={() => handleSort(item.sort)}
                                >
                                    {item.title}
                                </TableSortLabel>
                            </TableCell>
                        ))
                    }
                    <TableCell>
                        ACTION
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody className="tableBody" sx={tableRow}>
                {/* {
                    data.length !== 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
                        <RulestableRow data={data} handleSort={handleSort} />
                    )) : <>No Record Found</>
                } */}
                {
                    data.map((item) => (
                        <RulestableRow data={item} handleSort={handleSort} ruleData={data} />
                    ))
                }
            </TableBody>
        </Table>
    )
}

export default RulesTable