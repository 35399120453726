import React, { useContext, useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import AccessContext from '../../../context/AccessContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { unexpectedErrorNotification } from '../../../helpers';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store';

const GroupDropDown = ({ node, organizationName }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);

    const { setSingleChart, setIsActiveState, setOrgzName } = useContext(AccessContext);

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleAdd = (type) => {
        handleClose()
        setIsActiveState(type)
        setSingleChart(node)
        setOrgzName(organizationName)
    }

    const handleDelete = () => {
        handleClose()
        setIsActiveState("DeleteGroup")
        setSingleChart(node)
        setOrgzName(organizationName)
    }

    const handleModify = () => {
        handleClose()
        setIsActiveState("ModifyGroup")
        setOrgzName(organizationName)
        setSingleChart(node)
    }

    const handleMove = () => {
        handleClose()
        setIsActiveState("MoveGroup")
        setOrgzName(organizationName)
        setSingleChart(node)
    }

    const handleShow = () => {
        handleClose()
        if (node.departmentRuleSetId) {
            setIsActiveState("ShowGroupRules")
            setSingleChart(node)
            setOrgzName(organizationName)
        } else {
            unexpectedErrorNotification("Group Rules not exist", addNotifications);
        }
    }


    return (
        <>

            <MoreVertIcon onClick={(e) => setAnchorEl(e.currentTarget)} />
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className='depart-icon'
            >
                <MenuItem onClick={handleShow}>
                    <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                    Group Info
                </MenuItem>
                <MenuItem onClick={() => handleAdd("AddGroup")}>
                    <ListItemIcon><AddBoxOutlinedIcon /></ListItemIcon>
                    Create Group
                </MenuItem>
                <MenuItem onClick={() => handleAdd("AddUser")}>
                    <ListItemIcon><PersonAddAltOutlinedIcon /></ListItemIcon>
                    Create User
                </MenuItem>
                <MenuItem onClick={handleModify}>
                    <ListItemIcon><DriveFileRenameOutlineOutlinedIcon /></ListItemIcon>
                    Modify Group
                </MenuItem>
                {
                    node.treeLevel !== 1 && <>
                        <MenuItem onClick={handleMove}>
                            <ListItemIcon><RepeatOutlinedIcon /></ListItemIcon>
                            Move Group
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>
                            <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
                            Delete Group
                        </MenuItem>
                    </>
                }
            </Menu>
        </>
    )
}

export default GroupDropDown