import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const IncomingCallAlert = ({ userName, onAccept, onReject }) => {
  return (
    <Box className={'IncomingAlert'} sx={{ p: 2, textAlign: 'center' }}>
      <h2>Incoming Call from {userName}</h2>
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={onAccept}>
          Accept
        </Button>
        <Button variant="contained" color="secondary" onClick={onReject} sx={{ ml: 2 }}>
          Reject
        </Button>
      </Box>
    </Box>
  );
};

export default IncomingCallAlert;
