import React, { useEffect, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import { Col } from 'react-bootstrap'
import ThemeBtn from '../UI/ThemeBtn'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import RulesToolTip from '../UI/RulesToolTip'
import { labelConvertRules } from '../../utils/helperPages'
import { DeleteEmptyRule, DeleteReplaceRule, RulesById } from '../../axios/apis'
import RequiredAstrict from '../UI/RequiredAstrict'
import Select from "react-select";
import { successNotification, unexpectedErrorNotification } from '../../helpers'
import CircleLoader from '../UI/CircleLoader'
import DeleteEmptyRuleSet from './DeleteEmptyRuleSet'

const DeleteRuleSet = ({ open, onClose, title, btn, data, fetchDetails, ruleData }) => {

    const [modalLoader, setModalLoader] = useState(true)
    const [submitloader, setSubmitLoader] = useState(false)
    const [isFinal, setIsFinal] = useState(false)

    const [appliedRule, setAppliedRule] = useState({})

    const [rulesList, setRulesList] = useState([])

    const [replaceRule, setReplaceRule] = useState({
        replaceRuleName: "",
        replaceRuleId: ""
    })

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(
        actionCreators,
        dispatch
    );

    const handleReplaceRule = (e) => {
        if (e === null) {
            setReplaceRule({ replaceRuleId: "", replaceRuleName: "" });
        } else {
            setReplaceRule({ replaceRuleId: e.value, replaceRuleName: e.label });
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        try {
            const response = await DeleteReplaceRule({ deleteRuleSetId: data.ruleSetId, replaceRuleSetId: replaceRule.replaceRuleId })
            if (response.status === 200) {
                successNotification({
                    message: `Rule Deleted Successfully`,
                    addNotifications,
                });
                fetchDetails("")
                setSubmitLoader(false)
                onClose()
            }
        } catch (error) {
            unexpectedErrorNotification(error.response?.data || "An error occurred while Deleting the Rule", addNotifications);
            setSubmitLoader(false)
        }
    }


    const handleRemoveExisting = () => {
        const newRule = ruleData.filter((item) => item.ruleSetId !== data.ruleSetId)
        setRulesList(newRule)

    }

    useEffect(() => {
        handleRemoveExisting()
    }, [])


    const fetchRuleDetails = async () => {
        setModalLoader(true)
        try {
            const res = await RulesById(data.ruleSetId)
            setAppliedRule(res.data.ruleAppliedOn)
            setModalLoader(false)
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        fetchRuleDetails()
    }, [])



    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleDelete}>
                {
                    modalLoader ? <CircleLoader /> : <>
                        {
                            appliedRule.departmentList.length === 0 && appliedRule.userList.length === 0 ?
                                <DeleteEmptyRuleSet onClose={onClose} data={data} fetchDetails={fetchDetails} btn={btn} /> : <>
                                    {
                                        !isFinal ? <>
                                            <Col lg={12}>
                                                <div className="input-container">
                                                    <p>Existing rule must be selected to replace the <span style={{ color: "#961B13", fontWeight: 500 }}>{data.ruleSetName} </span>. Click Yes to proceed.
                                                    </p>
                                                </div>
                                            </Col>

                                            <div className='d-flex justify-content-end gap-4'>
                                                <button className='cancel-btn' onClick={onClose}>Cancel</button>
                                                <ThemeBtn name={"Yes"} type='button' handleClick={() => setIsFinal(true)} />
                                            </div>
                                        </> : <>
                                            <Col lg={12}>
                                                <div className="input-box">
                                                    <label className="input-label" htmlFor="">
                                                        Existing Rule <RequiredAstrict />
                                                    </label>
                                                    <Select
                                                        className={`parent-custom`}
                                                        isClearable={true}
                                                        value={replaceRule.replaceRuleId ? { label: replaceRule.replaceRuleName, value: replaceRule.replaceRuleId } : null}
                                                        options={labelConvertRules(rulesList)}
                                                        onChange={handleReplaceRule}
                                                        required
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                    />
                                                    {replaceRule.replaceRuleId !== "Custom" && replaceRule.replaceRuleName &&
                                                        <RulesToolTip list={ruleData} selectedId={replaceRule.replaceRuleId} selectedName={replaceRule.replaceRuleName} />
                                                    }
                                                </div>
                                            </Col>

                                            <div className='d-flex justify-content-end gap-4'>
                                                <button className='cancel-btn' onClick={onClose}>Cancel</button>
                                                <ThemeBtn name={btn} loader={submitloader} type="submit" />
                                            </div>
                                        </>
                                    }
                                </>
                        }
                    </>
                }

            </PopupLayout>
        </>
    )
}

export default DeleteRuleSet