import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { extractNames, labelConvert } from "../../utils/helperPages";
import SearchIcon from "@mui/icons-material/Search";
import SearchContext from "../../context/SearchContext";
import AccessContext from "../../context/AccessContext";

const ContactSearchDropdown = ({ widthFull, options, title, type }) => {

    const { searchText, setSearchText, setSearchEmail, searchEmail, orgDetails } = useContext(SearchContext);
    const { orgChart, setSingleChart, singleChart, } = useContext(AccessContext);

    const handleChange = (e) => {
        if (e === null) {
            setSearchText("");
            setSearchEmail("")
            setSingleChart(null)
        } else {
            setSearchText(e.label);
            setSearchEmail(e.value)
            setSingleChart(e.value)
        }
    };

    const filterOptions = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };



    return (
        <>
            <div className="search-bar">
                <Select
                    className={`select-custom ${widthFull ? "w-100" : "search-width"}`}
                    isClearable={true}
                    value={searchText ? { label: searchText, value: searchEmail } : null}
                    options={extractNames([options[0].orgChart])}
                    onChange={handleChange}
                    required
                    maxMenuHeight={'200px'}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    filterOption={filterOptions}
                />
            </div>
        </>
    )
}

export default ContactSearchDropdown