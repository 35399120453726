import { ModalBody, Box } from "@twilio-paste/core";
import { RefObject, useState } from "react";
import ModalInputField from "./ModalInputField";
import AddParticipantFooter from "./addParticipantFooter";
import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
import SearchName from "../UI/SearchName";
import AccessContext from "../../context/AccessContext";
import { useContext, useEffect } from "react";
import SearchContext from "../../context/SearchContext";
import { ContactDetailsById } from "../../axios/apis";

interface AddChatParticipantModalProps {
  name: string;
  setName: (name: string) => void;
  error: string;
  nameInputRef: RefObject<HTMLInputElement>;
  onBack: () => void;
  action: () => void;
  handleClose: () => void;
  isModalOpen: boolean;
  title: string;
}

interface User {
  // Define the structure of a user
  completeOrgChart: {
    orgChart: any; // Define the type for orgChart
  };
  // Add other properties as needed
}

const AddChatParticipantModal: React.FC<AddChatParticipantModalProps> = (
  props: AddChatParticipantModalProps
) => {
  const { orgChart, setOrgChart, singleChart, setSingleChart } = useContext(AccessContext);
  const { searchText, setFindList, setSearchText } = useContext(SearchContext);

  const [userList, setUserList] = useState<User[]>([]);
  const [dropState, setDropState] = useState({
    label: "",
    value: ""
  })

  useEffect(() => {
    const fetchContactDetails = async () => {
      setSearchText("");
      try {
        const contactDetails = await ContactDetailsById(localStorage.getItem("user"));
        if (contactDetails.data) {
          setUserList([contactDetails.data.completeOrgChart[0].orgChart]);
        } else {
          console.error("Failed to fetch contact details.");
        }
      } catch (error) {
        console.error("Error fetching contact details:", error);
      }
    };

    fetchContactDetails();
  }, []);

  useEffect(() => {
    props.setName(dropState?.value);
  }, [dropState]);

  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            <h3>Add participant</h3>
            <Box
              as="form"
              onKeyPress={async (e) => {
                if (e.key === "Enter") {
                  if (props.action) {
                    e.preventDefault();
                    props.action();
                  }
                }
              }}
            >
              <div className="search-box-add-participant my-4">
                <SearchName
                  selectedDropDown={dropState}
                  setSelectedDropDown={setDropState}
                  widthFull={""}
                  title={"Enter Profile Name"}
                  type={"search"}
                  options={userList}
                />
              </div>

              {/* <ModalInputField
                label="User identity"
                isFocused={true}
                input={props.name}
                placeholder="exampleusername"
                onChange={props.setName}
                error={props.error}
                // error_text="Enter a valid user identity."
                help_text="The identity used by the participant in Conversations."
              /> */}
            </Box>
          </ModalBody>
        }
        modalFooter={
          <AddParticipantFooter
            isSaveDisabled={!dropState.value || !!props.error}
            actionName={ActionName.Save}
            onBack={() => {
              props.onBack();
            }}
            action={props.action}
          />
        }
      />
    </>
  );
};

export default AddChatParticipantModal;
