import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../context/SearchContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AccessContext from "../../../context/AccessContext";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const TreeRight = ({ data = [] }) => {
  return (
    <div className="">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((item, index) => (
          <>
            <TreeNode node={item} index={index} totalNodes={data.length} />
            {
              item.otherContactList && <OtherContactTree node={item.otherContactList} parentIndex={index} totalNodes={item.otherContactList.length} />
            }
          </>
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, index, totalNodes }) => {
  const [childVisible, setChildVisible] = useState(true);

  const hasChild = true;

  const { searchText, findList, searchEmail } = useContext(SearchContext);
  const { setSingleChart, setIsRemoveContact, setSingleUser } = useContext(AccessContext);

  const includesSearchTerm = (text) => {
    text.toLowerCase() === searchText.toLowerCase();
  }


  useEffect(() => {
    // const matchedTitles = searchTitle(searchText)
    findList.map((name) => {
      setChildVisible(true);
    });
  }, []);

  // useEffect(() => {
  //     node.userContactList.map((el) => {
  //         if (el.parentId === 0) setChildVisible(true)
  //     })
  // }, [])

  const handleClick = (name) => {
    // setSingleChart(name)
  };

  const handleRemove = (item) => {
    setSingleUser(item)
    setIsRemoveContact(true)
  }


  return (
    <>
      {
        node.department ? <>
          <li className="d-tree-node border-0">
            <div
              className="d-flex align-items-center"
              onClick={() => setChildVisible(!childVisible)}
            >
              {hasChild && (
                <>
                  {childVisible ? (
                    <RemoveIcon className="d-tree-icon" />
                  ) : (
                    <AddIcon className="d-tree-icon" />
                  )}
                </>
              )}

              <div>
                <h4
                  className={`depart-head ${node.department && includesSearchTerm(node.department) && "highlight"
                    }`}
                >
                  {node.department}
                </h4>
              </div>
            </div>

            {hasChild && childVisible && (
              <div className="d-tree-content">
                <div>
                  <ul className="d-tree-container">
                    {node.userContactList.length !== 0 && node.userContactList.map((item) => (
                      <li className={`d-tree-people`}>
                        <div className="d-people-node">
                          <span
                            onClick={() => handleClick(item.userName)}
                            className={`people-head ${item.userEmailId === searchEmail && "highlight"
                              }`}
                          >
                            {item.userName}
                          </span>
                          <div onClick={() => handleRemove(item)} className='request-icon'>
                            <RemoveCircleOutlineIcon />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <ul className="d-flex d-tree-container flex-column">
                  <TreeRight data={node.children} />
                </ul>
              </div>
            )}
          </li>

          {index !== totalNodes - 1 && <hr className="m-0" />}
        </> : <TreeRight data={node.children} />
      }
    </>
  );
};

const OtherContactTree = ({ node, parentIndex, totalNodes }) => {

  const [childVisible, setChildVisible] = useState(false);
  const hasChild = node ? true : false;
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const token = useSelector((state) => state.token);
  // const { updateCurrentConversation, addNotifications, updateParticipants } =
  //   bindActionCreators(actionCreators, dispatch);
  // const { client, updateConnectionState, updateConversation } = useClient();

  // const { searchText, findList } = useContext(SearchContext);
  const { setSingleChart, setIsRemoveContact, setSingleUser } = useContext(AccessContext);


  // const includesSearchTerm = (text) =>
  //   text.toLowerCase() === searchText.toLowerCase();

  // useEffect(() => {
  //   // const matchedTitles = searchTitle(searchText)
  //   node.map((item) => {
  //     if (item.userName === searchText) {
  //       setChildVisible(true)
  //     }
  //   })
  // }, [searchText]);

  // useEffect(() => {
  //   if (node.treeLevel === 1) setChildVisible(true);
  //   // node.userContactList.map((el) => {
  //   //   if (el.parentId === 0) setChildVisible(true);
  //   // });
  // }, []);

  const handleRemove = (item) => {
    setSingleUser(item)
    setIsRemoveContact(true)
  }

  return (
    <>
      <hr className="m-0" />
      <li className="d-tree-node border-0">
        <div
          className="d-flex align-items-center"
        // onClick={() => setChildVisible(!childVisible)}
        >
          {hasChild && (
            <>
              {childVisible ? (
                <RemoveIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              ) : (
                <AddIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              )}
            </>
          )}

          <div>
            <h4 style={{ cursor: "pointer" }}
              className={`depart-head`}
            >
              <div>
                Other Contact List
              </div>
            </h4>
          </div>
        </div>

        {hasChild && childVisible && (
          <div className="d-tree-content">
            <div>
              <ul className="d-tree-container">
                {node.map((item) => (
                  <li className={`d-tree-people`}>
                    <div className="d-people-node">
                      <span
                        className={`people-head `}
                      >
                        {item.userName}
                      </span>
                      <div onClick={() => handleRemove(item)} className='request-icon'>
                        <RemoveCircleOutlineIcon />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* <ul className="d-flex d-tree-container flex-column">
              <Tree data={node.children} />
            </ul> */}
          </div>
        )}
      </li>

      {/* {parentIndex !== totalNodes - 1 && <hr className="m-0" />} */}
    </>
  )
}

export default TreeRight;
