import { Box } from "@twilio-paste/core";
import { ChatComposer } from "@twilio-paste/core/chat-composer";
import {
  $getRoot,
  ClearEditorPlugin,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_LOW,
  EditorState,
  KEY_ENTER_COMMAND,
  useLexicalComposerContext,
} from "@twilio-paste/lexical-library";
import { useEffect } from "react";
import MessageFile from "./MessageFile";
import { unexpectedErrorNotification } from "../../helpers";
import { MAX_FILE_SIZE } from "../../constants";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";

interface MessageInputProps {
  message: string;
  onChange: (message: string) => void;
  onPaste: () => void;
  onEnterKeyPress: () => void;
  onFileRemove: (file: string) => void;
  assets: File[];
  setAssets: (newAssets: File[]) => void;
  fileSent: boolean;
}

interface EnterKeyPluginProps {
  onEnterKeyPress: () => void;
}

const EnterKeyPlugin = (props: EnterKeyPluginProps) => {
  const { onEnterKeyPress } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      () => {
        onEnterKeyPress();
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor, onEnterKeyPress]);

  return null;
};

// when message gets cleared and given it's a prop passed in to MessageInput
// we need to clear the Lexical editor.
// TODO: there has to be a simpler way of doing a basic binding like this with Lexical

interface MessagePropPluginProps {
  message: string;
}

const MessagePropPlugin = (props: MessagePropPluginProps) => {
  const { message } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (message === undefined || message === null || message.length === 0) {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }
  }, [editor, message]);

  return null;
};

const MessageInput: React.FC<MessageInputProps> = (
  props: MessageInputProps
) => {
  const { onEnterKeyPress, setAssets, assets, fileSent, message } = props;

  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const items = (event.clipboardData || event.clipboardData).items;
    for (const item of items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (!fileSent) {
          if (!file) {
            return
          }
          const uniqueFileName = generateUniqueFileName(file.name);

          // Check if the file size exceeds the maximum limit
          if (file.size >= MAX_FILE_SIZE) {
            unexpectedErrorNotification("File size exceeds the maximum limit of 50MB.", addNotifications);
            return;
          }

          // Add the file to the assets array
          const uniqueFile = new File([file], uniqueFileName, { type: file.type });

          // Add the file to the assets array
          setAssets([...assets, uniqueFile]);

        } else {
          unexpectedErrorNotification("Please wait until file uploads are complete.", addNotifications);
        }
        // Handle the pasted file
        // For example, you can upload the file or process it
      }
    }
  };

  const generateUniqueFileName = (fileName: string): string => {
    const timestamp = Date.now();
    const fileExtension = fileName.split('.').pop(); // Extract file extension
    const baseFileName = fileName.split('.').slice(0, -1).join('.'); // Extract base file name
    return `${baseFileName}_${timestamp}.${fileExtension}`;
  };

  return (
    <Box>

      {props.assets.length > 0 && (
        <Box
          marginLeft={"space40"}
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.assets.map(({ name, size }) => (
            <MessageFile
              key={`${name + "_" + size}`}
              media={{ filename: name, size }}
              onRemove={() => props.onFileRemove(name + "_" + size)}
            />
          ))}
        </Box>
      )}

      <Box marginLeft={"space40"}>
        <ChatComposer
          config={{
            namespace: "message-input",
            onError: (e) => {
              throw e;
            },
          }}
          ariaLabel="A basic chat composer"
          placeholder="Add your message"
          onChange={(editorState: EditorState): void => {
            editorState.read(() => {
              const text = $getRoot().getTextContent().trim();
              props.onChange(text);
            });
          }}
          onPaste={handlePaste}
        >
          <ClearEditorPlugin />
          <MessagePropPlugin message={message} />
          <EnterKeyPlugin onEnterKeyPress={onEnterKeyPress} />
        </ChatComposer>
      </Box>

    </Box>
  );
};

export default MessageInput;
