import React, { useContext, useState } from "react";
import AccessContext from "../../context/AccessContext";
import PopupLayout from "../UI/Popup/PopupLayout";
import ThemeBtn from "../UI/ThemeBtn";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { RemoveUser } from "../../axios/apis";
import { UserCount } from "../../axios/apis";
import {
  successNotification,
  unexpectedErrorNotification,
} from "../../helpers";

const DeleteUser = ({ open, onClose, title, btn, data, fetchDetails }) => {
  const [submitloader, setSubmitLoader] = useState(false);
  const { singleUser, totalOrgChart } = useContext(AccessContext);

  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);

  const handleDelete = async () => {
    setSubmitLoader(true);
    const usersinDept = await UserCount(singleUser.userDepartmentId);
    // console.log(usersinDept);
    // console.log(singleUser.isNoDeleteUser);

    function getTreeLevel(departmentId, orgDataArray) {
      //   console.log(departmentId);
      //   console.log(orgDataArray);
      // Loop through each organization tree
      for (const orgData of orgDataArray) {
        const level = findDepartmentLevel(departmentId, orgData);

        if (level !== -1) {
          //   console.log("returning: ", level);
          return level;
        }
      }

      // Department not found in any tree
      return -1;
    }

    function findDepartmentLevel(departmentId, orgData) {
      //   console.log(orgData);
      //   console.log(departmentId);
      //   console.log(orgData.orgChart?.departmentId);
      //   console.log(orgData.departmentId);
      //   console.log(orgData.treeLevel);
      // Base case: If organization data is empty, return -1 (not found)
      if (!orgData) {
        return false;
      }

      // Check the current department level
      if (orgData.orgChart?.departmentId == departmentId) {
        return orgData.orgChart.treeLevel;
      }
      if (orgData.departmentId == departmentId) {
        return orgData.treeLevel;
      }

      // Recursively search for the department in child departments
      if (orgData.orgChart?.children) {
        for (const child of orgData.orgChart.children) {
          const level = findDepartmentLevel(departmentId, child);
          if (level !== -1) {
            return level;
          }
        }
      }
      if (orgData.children) {
        for (const child of orgData.children) {
          const level = findDepartmentLevel(departmentId, child);
          if (level !== -1) {
            return level;
          }
        }
      }

      // Department not found
      return -1;
    }

    console.log(
      "Level: ",
      getTreeLevel(singleUser.userDepartmentId, totalOrgChart)
    );

    setSubmitLoader(false);

    if (
      getTreeLevel(singleUser.userDepartmentId, totalOrgChart) == 1 &&
      (singleUser.isNoDeleteUser || usersinDept.data.length < 2)
    ) {
      unexpectedErrorNotification(
        "This user cannot be deleted",
        addNotifications
      );
      setSubmitLoader(false);
      onClose();
    } else {
      try {
        const response = await RemoveUser(singleUser.userEmailId);
        successNotification({
          message: `User Deleted Successfully`,
          addNotifications,
        });
        fetchDetails("");
        setSubmitLoader(false);
        onClose();
      } catch (error) {
        unexpectedErrorNotification(
          error.response?.data || "An error occurred while Deleting the User",
          addNotifications
        );
        setSubmitLoader(false);
        onClose();
      }
    }
  };

  return (
    <>
      <PopupLayout open={open} onClose={onClose} title={title}>
        <Col lg={12}>
          <div className="input-container">
            <p>
              To Delete{" "}
              <span style={{ color: "#961B13", fontWeight: 500 }}>
                {singleUser.userName}
              </span>{" "}
              from{" "}
              <span style={{ color: "#961B13", fontWeight: 500 }}>
                {singleUser.userDepartment}
              </span>{" "}
              Group click on Delete Button.
            </p>
          </div>
        </Col>

        <div className="d-flex justify-content-end gap-4">
          <button className="cancel-btn" onClick={onClose}>
            Cancel
          </button>
          <ThemeBtn
            name={btn}
            loader={submitloader}
            handleClick={handleDelete}
          />
        </div>
      </PopupLayout>
    </>
  );
};

export default DeleteUser;
