import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AccessContext from "../../context/AccessContext";
import {
  extractDepartmentName,
  extractLevelNames,
  extractNames,
  getDepartmentPath,
  getTimezoneName,
  labelConvert,
  labelConvertRules,
} from "../../utils/helperPages";
import { CreateUser, CreateUserWithRule, RulesList } from "../../axios/apis";
import ThemeBtn from "../UI/ThemeBtn";
import {
  successNotification,
  unexpectedErrorNotification,
} from "../../helpers";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RulesToolTip from "../UI/RulesToolTip";
import RequiredAstrict from "../UI/RequiredAstrict";
import CreateCustomRule from "./TreeView/CreateCustomRule";
import { optionsStyles } from "../../utils/helperInline";

const AddUser = ({ customerList, profileList, fetchData }) => {

  const [formLoader, setFormLoader] = useState(false)
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);

  const [accessRuleList, setAccessRuleList] = useState([]);
  const [originalRules, setOriginalRules] = useState([])
  const [accessRule, setAccessRule] = useState({ accessRuleId: null, accessRuleName: "" })

  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);

  const { singleChart, setIsActiveState, orgzName, setSelectedDepartments, setSelectedUsers } = useContext(AccessContext);


  const [selectDepartment, setSelectDepartment] = useState({
    departmentName: singleChart?.department,
    departmentId: singleChart?.departmentId,
  });

  const [userDetail, setUserDetail] = useState({
    userName: "",
    userEmailId: "",
    userTimezone: getTimezoneName(Intl.DateTimeFormat().resolvedOptions().timeZone),
    userDepartmentId: selectDepartment?.departmentId,
    userCustomer: orgzName.organizationName,
    userRole: null,
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  const handleAccessRuleChange = (e) => {

    if (e === null) {
      setAccessRule({ accessRuleId: "", accessRuleName: "" });
    } else {
      setAccessRule({ accessRuleId: e.value, accessRuleName: e.label });
      if (e.value === "custom") {
        setSelectedDepartments([]);
        setSelectedUsers([]);
      }
    }
  };


  const handleDepartment = (e) => {

    if (e === null) {
      setSelectDepartment(null);
    } else {
      setSelectDepartment({ departmentName: e.label, departmentId: e.value });
      setUserDetail({ ...userDetail, userDepartmentId: e.value });
    }
  };

  const handleProfile = (e) => {
    if (e === null) {
      setUserDetail({ ...userDetail, userRole: "" });
    } else {
      setUserDetail({ ...userDetail, userRole: e.value });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (accessRule.accessRuleId === "custom") {
      setShowCustomForm(true);
    } else {
      setSubmitLoader(true);
      try {
        const response = await CreateUserWithRule({ ...userDetail, assignExistingRuleId: accessRule.accessRuleId });
        if (response.status === 200) {
          setSubmitLoader(false);
          successNotification({
            message: "User Created Successfully.",
            addNotifications,
          });
          fetchData();
        }
      } catch (error) {
        setSubmitLoader(false);
        unexpectedErrorNotification(error.response.data || "User Not Created Successfully", addNotifications);
      }
    }
  };

  const handleReset = () => {
    setUserDetail({
      userName: "",
      userEmailId: "",
      parentDepartmentId: "",
      userRole: "",
    });
    setSelectDepartment(null);
  };

  useEffect(() => {
    setSelectDepartment({
      departmentName: singleChart?.department,
      departmentId: singleChart?.departmentId,
    });
    setAccessRule({ accessRuleId: null, accessRuleName: "" });
    fetchRulesList()
  }, [singleChart]);


  const fetchRulesList = async () => {
    setFormLoader(true)
    try {
      const response = await RulesList(orgzName.organizationName)
      if (singleChart.departmentRuleSetId) {
        const inheritItem = { ruleSetId: singleChart.departmentRuleSetId, ruleSetName: "Inherit from Parent" };
        const customItem = { ruleSetId: "custom", ruleSetName: "Custom" };
        const currentRuleSets = response.data.ruleSets;

        // Find the index of the "default" item
        const defaultItemIndex = currentRuleSets.findIndex(item => item.ruleSetName === "Default");

        let updatedRuleSets = [];
        updatedRuleSets.push(inheritItem);

        // Insert the "custom" item after the "default" item if found
        if (defaultItemIndex !== -1) {
          updatedRuleSets.push(currentRuleSets[defaultItemIndex]);
          updatedRuleSets.push(customItem);
          updatedRuleSets.push(...currentRuleSets.slice(defaultItemIndex + 1));
        } else {
          // If "default" item not found, insert "custom" item at index 1
          updatedRuleSets.push(customItem);
          updatedRuleSets.push(...currentRuleSets);
        }

        setAccessRuleList(updatedRuleSets);
        setOriginalRules(currentRuleSets);
      } else {
        let updatedRuleSets = response.data.ruleSets;

        // Find the index of the "default" item
        const defaultItemIndex = updatedRuleSets.findIndex(item => item.ruleSetName === "Default");

        // Insert the "custom" item after the "default" item if found
        if (defaultItemIndex !== -1) {
          updatedRuleSets.splice(defaultItemIndex + 1, 0, { ruleSetId: "custom", ruleSetName: "Custom" });
        } else {
          // If "default" item not found, insert "custom" item at index 1
          updatedRuleSets.splice(1, 0, { ruleSetId: "custom", ruleSetName: "Custom" });
        }

        setAccessRuleList(updatedRuleSets);
        setOriginalRules(response.data.ruleSets);
        // setAccessRuleList([...response.data.ruleSets, { ruleSetId: "custom", ruleSetName: "Custom" }])
      }
      setFormLoader(false)
    } catch (error) {
      unexpectedErrorNotification(error.response.data || "Rules List is Not loaded", addNotifications);
      // console.log(error)
    }
  }

  useEffect(() => {
    fetchRulesList()
  }, [])

  const handleBack = () => {
    setShowCustomForm(false);
    setAccessRule({ accessRuleId: null, accessRuleName: "" });
  }

  return (
    <div className="tree-container">
      <div className="tree-box">
        <h3 className="tree-heading tree-head">
          <span>
            Create User {showCustomForm && <><ArrowForwardIcon className="head-arrow-icon" /> Create Custom Rule</>}
          </span>
          <HighlightOffIcon onClick={() => setIsActiveState(false)} />
        </h3>
        <div className="form-container form-tree-box-inside">
          {showCustomForm ?
            <>
              <CreateCustomRule open={showCustomForm} onBack={handleBack} data={userDetail} fetchData={fetchData} modal="User" type="Add" />
            </>
            : (
              <form onSubmit={handleSubmit}>

                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Contact Name <RequiredAstrict />
                  </label>
                  <div className="input-field">
                    <input type="text" value={userDetail.userName} name="userName" onChange={handleChange} required />
                  </div>
                </div>


                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Contact Email <RequiredAstrict />
                  </label>
                  <div className="input-field">
                    <input type="text" value={userDetail.userEmailId} name="userEmailId" onChange={handleChange} placeholder="user@gmail.com" pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" required />
                  </div>
                </div>


                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Group <RequiredAstrict />
                  </label>
                  <Select
                    className={`parent-custom`}
                    isClearable={true}
                    value={selectDepartment ? { label: getDepartmentPath([orgzName.orgChart], selectDepartment.departmentId), value: selectDepartment.departmentId, } : null}
                    // value={selectDepartment ? { label: selectDepartment.departmentName, value: selectDepartment.departmentId, } : null}
                    options={extractDepartmentName([orgzName.orgChart])}
                    onChange={handleDepartment}
                    required
                    // maxMenuHeight={'200px'}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>

                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Organization  <RequiredAstrict />
                  </label>
                  <div className="input-field parent-custom">
                    <input type="text" value={orgzName.organizationName} disabled />
                  </div>
                </div>

                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    User Role <RequiredAstrict />
                  </label>

                  <Select
                    className={`parent-custom`}
                    isClearable={true}
                    value={
                      userDetail.userRole
                        ? { label: userDetail.userRole, value: userDetail.userRole }
                        : null
                    }
                    options={labelConvert(profileList)}
                    onChange={handleProfile}
                    required
                    maxMenuHeight={"200px"}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>

                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Access Rule <RequiredAstrict />
                  </label>

                  <Select
                    className={`parent-custom`}
                    isClearable={true}
                    value={accessRule.accessRuleId ? { label: accessRule.accessRuleName, value: accessRule.accessRuleId } : null}
                    options={labelConvertRules(accessRuleList)}
                    onChange={handleAccessRuleChange}
                    required
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={optionsStyles(accessRule.accessRuleName, accessRule.accessRuleId)}
                  />
                  {accessRule.accessRuleName !== "Custom" && accessRule.accessRuleName &&
                    <RulesToolTip list={originalRules} selectedId={accessRule.accessRuleId} selectedName={accessRule.accessRuleName} />
                  }
                </div>

                <div className="action-btn">
                  <button type="button" onClick={handleReset} className="reset-btn">
                    Reset
                  </button>
                  <ThemeBtn name={accessRule.accessRuleId === "custom" ? "Next" : "Submit"} loader={submitloader} type="submit" />
                </div>
              </form>)}
        </div>
      </div>
    </div>
  );
};

export default AddUser;
