import React, { useContext } from 'react'
import DeleteUser from './DeleteUser';
import DeleteHierachy from './DeleteHierachy';
import UpdateGroup from './UpdateGroup';
import UpdateUserRole from './UpdateUserRole';
import AccessContext from '../../context/AccessContext';
import MoveUserModal from './MoveUserModal';
import ShowUserRules from './ShowUserRules';
import ShowGroupRules from './ShowGroupRules';
import MoveGroupModal from './MoveGroupModal';

const PageModalsComp = ({ fetchData, profileList }) => {

    const { isActiveState, setIsActiveState, } = useContext(AccessContext);

    return (
        <>
            {
                isActiveState === "DeleteUser" && <DeleteUser open={isActiveState} onClose={() => setIsActiveState(false)} title={"Delete User"} btn={"Delete"} fetchDetails={fetchData} />
            }
            {
                isActiveState === "DeleteGroup" && <DeleteHierachy open={isActiveState} onClose={() => setIsActiveState(false)} title={"Delete Group"} btn={"Delete"} fetchDetails={fetchData} />
            }
            {
                isActiveState === "ModifyUser" && <UpdateUserRole open={isActiveState} onClose={() => setIsActiveState(false)} title={"Update User Profile"} profileList={profileList} btn={"Update"} fetchDetails={fetchData} />
            }
            {
                isActiveState === "ModifyGroup" && <UpdateGroup open={isActiveState} onClose={() => setIsActiveState(false)} title={"Update Group"} btn={"Update"} fetchDetails={fetchData} />
            }
            {
                isActiveState === "MoveUser" && <MoveUserModal open={isActiveState} onClose={() => setIsActiveState(false)} title={"Update User Level"} btn={"Update"} fetchDetails={fetchData} />
            }
            {
                isActiveState === "MoveGroup" && <MoveGroupModal open={isActiveState} onClose={() => setIsActiveState(false)} title={"Update Group Level"} btn={"Update"} fetchDetails={fetchData} />
            }
            {
                isActiveState === "ShowRules" && <ShowUserRules open={isActiveState} onClose={() => setIsActiveState(false)} title={"User Rules Details"} btn={false} fetchDetails={fetchData} />
            }
            {
                isActiveState === "ShowGroupRules" && <ShowGroupRules open={isActiveState} onClose={() => setIsActiveState(false)} title={"Group Rules Details"} btn={false} fetchDetails={fetchData} />
            }
        </>
    )
}

export default PageModalsComp