import React, { useContext, useEffect, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import Select from "react-select";
import AccessContext from '../../context/AccessContext';
import PopupTree from './PopupTree';
import { extractNames, findPathForName } from '../../utils/helperPages';
import SearchContext from '../../context/SearchContext';

const RequestModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const { orgChart, setSingleChart, singleChart } = useContext(AccessContext)
    const { orgDetails, searchTextPopup, setSearchTextPopUp, setPopUpFindList, setSearchEmailPopup, searchEmailPopup } = useContext(SearchContext);


    const handleChange = (e) => {
        if (e === null) {
            setSearchTextPopUp("");
            setSearchEmailPopup("")
            setSingleChart(null)
        } else {
            setSearchTextPopUp(e.label);
            setSearchEmailPopup(e.value)
            setSingleChart(e.value)
        }
    };

    useEffect(() => {
        if (searchEmailPopup && searchTextPopup) {
            setPopUpFindList(findPathForName(orgChart, searchEmailPopup))
        } else {
            setPopUpFindList(findPathForName(orgChart, searchTextPopup))
        }
    }, [searchTextPopup, searchEmailPopup])


    useEffect(() => {
        setSearchTextPopUp("")
        setSearchEmailPopup("")
    }, [])


    return (
        <PopupLayout open={open} onClose={onClose} title={title} >
            <div className="search-bar mb-4">
                <Select
                    className={`select-custom w-100`}
                    isClearable={true}
                    value={searchTextPopup ? { label: searchTextPopup, value: singleChart } : null}
                    options={extractNames([orgDetails])}
                    onChange={handleChange}
                    required
                    maxMenuHeight={'200px'}
                    components={{
                        // DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                />
            </div>

            <div className="tree-container" >
                <div className="tree-box">
                    <h3 className="tree-heading">Organization Tree</h3>
                    <div className="tree-box-inside">
                        <PopupTree searchTerm={searchTextPopup} data={[orgDetails]} />
                    </div>
                </div>
            </div>
        </PopupLayout>
    )
}

export default RequestModal