import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  Message,
  Conversation,
  Participant,
  Client,
  ConnectionState,
} from "@twilio/conversations";
import { Box } from "@twilio-paste/core";
import { actionCreators, AppState } from "../store";
import useAppAlert from "../hooks/useAppAlerts";
import stylesheet from "../styles";
import { handlePromiseRejection } from "../helpers";
import AppReadyContext from "../context/AppReadyContext";
import { useClient } from "../context/ClientInfoContext";
import { getToken } from "../api";
import Notifications from "./Notifications";
import { showNotification } from "../firebase-support";

async function loadUnreadMessagesCount(
  convo: Conversation,
  updateUnreadMessages: any
) {
  let count = 0;
  try {
    count =
      (await convo.getUnreadMessagesCount()) ??
      (await convo.getMessagesCount());
  } catch (e) {
    console.error("getUnreadMessagesCount threw an error", e);
  }
  updateUnreadMessages(convo.sid, count);
}

async function handleParticipantsUpdate(
  participant: Participant,
  updateParticipants: any
) {
  const result = await participant.conversation.getParticipants();
  updateParticipants(result, participant.conversation.sid);
}

async function getSubscribedConversations(
  client: Client
): Promise<Conversation[]> {
  let subscribedConversations = await client.getSubscribedConversations();
  let conversations = subscribedConversations.items;
  while (subscribedConversations.hasNextPage) {
    subscribedConversations = await subscribedConversations.nextPage();
    conversations = [...conversations, ...subscribedConversations.items];
  }
  return conversations;
}

const AppContainer: React.FC = () => {
  const [connectionState, setConnectionState] = useState<ConnectionState>();
  const [client, setClient] = useState<Client>();
  const [clientIteration, setClientIteration] = useState(0);
  const token = useSelector((state: AppState) => state.token);
  const conversations = useSelector((state: AppState) => state.convos);
  const sid = useSelector((state: AppState) => state.sid);
  const sidRef = useRef("");
  const [alertsExist, AlertsView] = useAppAlert();
  sidRef.current = sid;

  const username = localStorage.getItem("user");
  const password = localStorage.getItem("password");
  const { setAppReady, appReady } = useContext(AppReadyContext);
  const dispatch = useDispatch();
  const {
    upsertMessages,
    updateLoadingState,
    updateParticipants,
    updateUser,
    updateUnreadMessages,
    startTyping,
    endTyping,
    upsertConversation,
    login,
    removeMessages,
    removeConversation,
    updateCurrentConversation,
    addNotifications,
    logout,
    clearAttachments,
    updateTimeFormat,
  } = bindActionCreators(actionCreators, dispatch);

  const { updateClient, updateConnectionState, updateConversation } =
    useClient();

  const updateTypingIndicator = (
    participant: Participant,
    sid: string,
    callback: (sid: string, user: string) => void
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {attributes: { friendlyName },identity} = participant;
    if (identity === localStorage.getItem("user")) return;
    callback(sid, identity || friendlyName || "");
  };

  useEffect(() => {
    const initClient = async () => {
      if (!token) return;
      const client = new Client(token);
      setClient(client);
      updateClient(client);

      client.on("conversationJoined", (conversation) => {
        upsertConversation(conversation);
        conversation.on("typingStarted", (participant) => {
          handlePromiseRejection(
            () =>
              updateTypingIndicator(participant, conversation.sid, startTyping),
            addNotifications
          );
        });

        conversation.on("typingEnded", async (participant) => {
          await handlePromiseRejection(
            async () =>
              updateTypingIndicator(participant, conversation.sid, endTyping),
            addNotifications
          );
        });

        handlePromiseRejection(async () => {
          if (conversation.status === "joined") {
            const result = await conversation.getParticipants();
            updateParticipants(result, conversation.sid);

            const messages = await conversation.getMessages();
            upsertMessages(conversation.sid, messages.items);
            await loadUnreadMessagesCount(conversation, updateUnreadMessages);
          }
        }, addNotifications);
      });

      client.on("conversationRemoved", async (conversation: Conversation) => {
        updateCurrentConversation("");
        await handlePromiseRejection(async () => {
          removeConversation(conversation.sid);
          updateParticipants([], conversation.sid);
        }, addNotifications);
      });

      client.on("messageAdded", async (message: Message) => {
        await upsertMessage(message, upsertMessages, updateUnreadMessages);
        if (message.author === localStorage.getItem("username")) {
          clearAttachments(message.conversation.sid, "-1");
        }
      });

      client.on("userUpdated", async (event) => {
        await updateUser(event.user);
      });

      client.on("participantLeft", async (participant) => {
        await handlePromiseRejection(
          async () => handleParticipantsUpdate(participant, updateParticipants),
          addNotifications
        );
      });

      client.on("participantUpdated", async (event) => {
        await handlePromiseRejection(
          async () =>
            handleParticipantsUpdate(event.participant, updateParticipants),
          addNotifications
        );
      });

      client.on("participantJoined", async (participant) => {
        await handlePromiseRejection(
          async () => handleParticipantsUpdate(participant, updateParticipants),
          addNotifications
        );
      });

      client.on("conversationUpdated", async ({ conversation }) => {
        await handlePromiseRejection(
          () => upsertConversation(conversation),
          addNotifications
        );
      });

      // if (Notification.permission === "granted") {
      //   showNotification(event);
      // } else {
      //   // console.log("Push notification is skipped", Notification.permission);
      // }

      client.on("messageUpdated", async ({ message }) => {
        await handlePromiseRejection(
          async () =>
            upsertMessage(message, upsertMessages, updateUnreadMessages),
          addNotifications
        );
      });

      client.on("messageRemoved", async (message) => {
        await handlePromiseRejection(
          () => removeMessages(message.conversation.sid, [message]),
          addNotifications
        );
      });

      client.on("tokenAboutToExpire", async () => {
        if (username && password) {
          const token = await getToken(username, password);
          await client.updateToken(token);
          login(token);
        }
      });

      client.on("tokenExpired", async () => {
        if (username && password) {
          const token = await getToken(username, password);
          login(token);
          setClientIteration((x) => x + 1);
        }
      });

      client.on("connectionStateChanged", (state) => {
        setConnectionState(state);
        updateConnectionState(state);
        if (state !== "connected" && state !== "connecting") {
          // initClient();
        }
      });

      updateLoadingState(false);
    };

    initClient();

    return () => {
      client?.removeAllListeners();
    };
  }, [clientIteration, appReady]);

  useEffect(() => {
    const abortController = new AbortController();
    const use24hTimeFormat = localStorage.getItem("use24hTimeFormat");
    if (use24hTimeFormat !== null) {
      updateTimeFormat(true);
    }
    return () => {
      abortController.abort();
    };
  }, []);

  async function upsertMessage(
    message: Message,
    upsertMessages: any,
    updateUnreadMessages: any
  ) {
    await handlePromiseRejection(async () => {
      if (sidRef.current === message.conversation.sid) {
        await message.conversation.advanceLastReadMessageIndex(message.index);
      }
      upsertMessages(message.conversation.sid, [message]);
      await loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
    }, addNotifications);
  }

  const openedConversation = useMemo(
    () => conversations.find((convo) => convo.sid && convo.sid === sid),
    [sid, conversations]
  );
  if (openedConversation) {
    const castedConversation = openedConversation as Conversation;
    updateConversation(castedConversation);
  }

  return (
    <>
      <Box className="AppContainerNotifications">
        <AlertsView />
        <Notifications />
      </Box>
    </>
  );
};

export default AppContainer;
