import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import ApprovalModal from '../ApprovalModal'

const ApprovaltableRow = ({ data, handleSort }) => {

    const { user_Id_From, user_Id_From_Level, user_Id_To, user_Id_To_Level, customer, request_Type, request_Status, request_Date } = data

    const [isApproveUser, setIsApproveUser] = useState(false)
    const [chooseAction, setChooseAction] = useState("")

    const handleChoose = (type) => {
        setIsApproveUser(true)
        setChooseAction(type)
    }

    return (
        <>
            <TableRow className="tableRow" key={user_Id_From}>
                <TableCell>{user_Id_From || "--"}</TableCell>
                <TableCell>{user_Id_From_Level || "--"}</TableCell>
                <TableCell>{user_Id_To || "--"}</TableCell>
                <TableCell>{user_Id_To_Level || "--"}</TableCell>
                <TableCell>{customer || "--"}</TableCell>
                <TableCell>{request_Date || "--"}</TableCell>
                <TableCell>
                    {
                        request_Status === "Pending" ? <>
                            <div className="table-btn">
                                <span onClick={() => handleChoose("Approved")}>Approve</span>
                                <span onClick={() => handleChoose("Declined")}>Decline</span>
                            </div>
                        </> : <div className="table-btn">
                            <span>{request_Status}</span>
                        </div>
                    }
                </TableCell>
            </TableRow>

            {
                isApproveUser && <ApprovalModal open={isApproveUser} onClose={() => setIsApproveUser(false)} data={data} btn={chooseAction} fetchDetails={handleSort} title={`${chooseAction === "Approved" ? "Approval" : "Decline"} Confirmation`} />
            }
        </>
    )
}

export default ApprovaltableRow