import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { components } from "react-select";
import AccessContext from "../../context/AccessContext";
import { extractDepartmentName, getDepartmentPath, labelConvertRules, trimErrorMessage } from "../../utils/helperPages";
import { CreatDepartWithRule, CreateDepartment, RulesList } from "../../axios/apis";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { successNotification, unexpectedErrorNotification } from "../../helpers";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import ThemeBtn from "../UI/ThemeBtn";
import CreateCustomRule from "./TreeView/CreateCustomRule";
import RequiredAstrict from "../UI/RequiredAstrict";
import RulesToolTip from "../UI/RulesToolTip";
import { optionsStyles } from "../../utils/helperInline";

const AddHierachy = ({ fetchData }) => {

  const [formLoader, setFormLoader] = useState(false)
  const [submitloader, setSubmitLoader] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);

  const [accessRuleList, setAccessRuleList] = useState([]);
  const [originalRules, setOriginalRules] = useState([])
  const [accessRule, setAccessRule] = useState({ accessRuleId: null, accessRuleName: "" })

  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);

  const { singleChart, setSelectedDepartments, setSelectedUsers, orgzName, setIsActiveState } = useContext(AccessContext);

  const [selectParent, setSelectParent] = useState({
    parentName: singleChart?.department,
    parentId: singleChart?.departmentId,
  });

  const [levelDetail, setLevelDetail] = useState({
    departmentName: "",
    departmentType: "department_type",
    parentDepartmentId: selectParent ? selectParent.parentId : "",
  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setLevelDetail({ ...levelDetail, [name]: value });
  };

  const handleSelect = (e) => {
    // Select the Group Parent Id and name
    if (e === null) {
      setSelectParent(null);
    } else {
      setSelectParent({ parentName: e.label, parentId: e.value });
      setLevelDetail({ ...levelDetail, parentDepartmentId: e.value });
    }
  };

  const handleAccessRuleChange = (e) => {
    // Slecting the Rules and managing with custom rule
    if (e === null) {
      setAccessRule({ accessRuleId: "", accessRuleName: "" });
    } else {
      setAccessRule({ accessRuleId: e.value, accessRuleName: e.label });
      if (e.value === "custom") {
        setSelectedDepartments([]);
        setSelectedUsers([]);
      }
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (accessRule.accessRuleId === "custom") {
      setShowCustomForm(true);
    } else {
      setSubmitLoader(true);
      try {
        const response = await CreatDepartWithRule({ ...levelDetail, assignExistingRuleId: accessRule?.accessRuleId });
        if (response.status === 200) {
          setSubmitLoader(false);
          successNotification({
            message: "Group Created  ",
            addNotifications,
          });
          fetchData();
        }
      } catch (error) {
        unexpectedErrorNotification(trimErrorMessage(error.response.data) || "Group not Created Successfully", addNotifications);
        setSubmitLoader(false);
        // console.log(error);
      }
    }
  };

  const handleReset = () => {
    setLevelDetail({ departmentName: "", departmentType: "", parentDepartmentId: "" });
    setSelectParent(null);
    setShowCustomForm(false);
  };


  const fetchRulesList = async () => {
    setFormLoader(true)
    try {
      const response = await RulesList(orgzName.organizationName)
      if (singleChart.departmentRuleSetId) {
        const inheritItem = { ruleSetId: singleChart.departmentRuleSetId, ruleSetName: "Inherit from Parent" };
        const customItem = { ruleSetId: "custom", ruleSetName: "Custom" };
        const currentRuleSets = response.data.ruleSets;

        // Find the index of the "default" item
        const defaultItemIndex = currentRuleSets.findIndex(item => item.ruleSetName === "Default");

        let updatedRuleSets = [];
        updatedRuleSets.push(inheritItem);

        // Insert the "custom" item after the "default" item if found
        if (defaultItemIndex !== -1) {
          updatedRuleSets.push(currentRuleSets[defaultItemIndex]);
          updatedRuleSets.push(customItem);
          updatedRuleSets.push(...currentRuleSets.slice(defaultItemIndex + 1));
        } else {
          // If "default" item not found, insert "custom" item at index 1
          updatedRuleSets.push(customItem);
          updatedRuleSets.push(...currentRuleSets);
        }

        setAccessRuleList(updatedRuleSets);
        setOriginalRules(currentRuleSets);
      } else {
        let updatedRuleSets = response.data.ruleSets;

        // Find the index of the "default" item
        const defaultItemIndex = updatedRuleSets.findIndex(item => item.ruleSetName === "Default");

        // Insert the "custom" item after the "default" item if found
        if (defaultItemIndex !== -1) {
          updatedRuleSets.splice(defaultItemIndex + 1, 0, { ruleSetId: "custom", ruleSetName: "Custom" });
        } else {
          // If "default" item not found, insert "custom" item at index 1
          updatedRuleSets.splice(1, 0, { ruleSetId: "custom", ruleSetName: "Custom" });
        }

        setAccessRuleList(updatedRuleSets);
        setOriginalRules(response.data.ruleSets);
      }
      setFormLoader(false)
    } catch (error) {
      unexpectedErrorNotification(error.response.data || "Rules List is Not loaded", addNotifications);
      // console.log(error)
    }
  }


  useEffect(() => {
    setSelectParent({
      parentName: singleChart?.department,
      parentId: singleChart?.departmentId,
    });
    setAccessRule({ accessRuleId: null, accessRuleName: "" });
    fetchRulesList()
  }, [singleChart]);


  useEffect(() => {
    fetchRulesList()
  }, [])


  const handleBack = () => {
    setShowCustomForm(false);
    setAccessRule({ accessRuleId: null, accessRuleName: "" });
  }

  const CustomOption = (props) => {
    const { children, data } = props;

    // Define an array of ruleSetIds that should have custom background colors
    const specialRuleSetIds = [singleChart.departmentRuleSetId, 'custom', 'default'];

    // Check if the current option's value is in the specialRuleSetIds array
    const hasCustomBackground = specialRuleSetIds.includes(data.value);

    return (
      <components.Option {...props}>
        <div style={{ backgroundColor: hasCustomBackground ? 'lightblue' : 'inherit' }}>
          {children}
        </div>
      </components.Option>
    );
  };






  return (
    <div className="tree-container" >
      <div className="tree-box">
        <h3 className="tree-heading tree-head">
          <span>
            Create Group {showCustomForm && <><ArrowForwardIcon className="head-arrow-icon" /> Create Custom Rule</>}
          </span>
          <HighlightOffIcon onClick={() => setIsActiveState(false)} />
        </h3>
        <div className="form-container form-tree-box-inside">
          {showCustomForm ? <CreateCustomRule open={showCustomForm} onBack={handleBack} data={levelDetail} fetchData={fetchData} modal="Department" type="Add" />
            : (
              <form onSubmit={handleSubmit}>

                {/* Add common form fields here */}
                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Group Name <RequiredAstrict />
                  </label>
                  <div className="input-field parent-custom">
                    <input value={levelDetail.departmentName} type="text" name="departmentName" onChange={handleChange} required />
                  </div>
                </div>

                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Parent <RequiredAstrict />
                  </label>
                  <Select
                    className={`parent-custom`}
                    isClearable={true}
                    value={selectParent ? { label: getDepartmentPath([orgzName.orgChart], selectParent.parentId), value: selectParent.parentId, } : null}
                    // value={selectParent ? { label: selectParent.parentName, value: selectParent.parentId } : null}
                    options={extractDepartmentName([orgzName.orgChart])}
                    onChange={handleSelect}
                    required
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>

                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Organization
                  </label>
                  <div className="input-field parent-custom">
                    <input type="text" value={orgzName.organizationName} disabled />
                  </div>
                </div>

                <div className="input-box">
                  <label className="input-label" htmlFor="">
                    Access Rule <RequiredAstrict />
                  </label>
                  <Select
                    className={`parent-custom`}
                    isClearable={true}
                    value={accessRule.accessRuleId ? { label: accessRule.accessRuleName, value: accessRule.accessRuleId } : null}
                    options={labelConvertRules(accessRuleList)}
                    onChange={handleAccessRuleChange}
                    required
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={optionsStyles(accessRule.accessRuleName, accessRule.accessRuleId)}
                  />
                  {accessRule.accessRuleName !== "Custom" && accessRule.accessRuleName &&
                    <RulesToolTip list={originalRules} selectedId={accessRule.accessRuleId} selectedName={accessRule.accessRuleName} />
                  }
                </div>

                <div className="action-btn">
                  <button type="button" onClick={handleReset} className="reset-btn">Reset</button>
                  <ThemeBtn name={accessRule.accessRuleId === "custom" ? "Next" : "Submit"} loader={submitloader} type="submit" />
                </div>
              </form>
            )}
        </div>
      </div>
    </div>
  );
};

export default AddHierachy;
