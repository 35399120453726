import React, { useContext } from 'react'
import Select from "react-select";
import SearchContext from '../../context/SearchContext';

const OrgNameDropDOwn = ({ widthFull, options, title, type }) => {

    const { searchOrg, setSearchOrg, setOrgDetails, orgDetails } = useContext(SearchContext);

    const labelConvert = (list) => {
        let arr = []
        list.map((item) => {
            arr.push({ label: item.organizationName, value: item.organizationId })
        })
        return arr
    }


    const handleChange = (e) => {
        if (e === null) {
            setSearchOrg({ name: null, id: null });
        } else {
            setSearchOrg({ name: e.label, id: e.value });
            const sample = options.find((item) => item.organizationId === e.value).orgChart
            setOrgDetails(sample)
        }
    }




    return (
        <div className="search-bar">
            <Select
                className={`select-custom ${widthFull ? "w-100" : "search-width"}`}
                isClearable={true}
                value={searchOrg.id ? { label: searchOrg.name, value: searchOrg.id } : null}
                options={labelConvert(options)}
                onChange={handleChange}
                required
                maxMenuHeight={'200px'}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
            />
        </div>
    )
}

export default OrgNameDropDOwn