import React, { useContext, useEffect, useRef, useState } from 'react'
import AppReadyContext from '../../context/AppReadyContext';
import { FormControl, InputAdornment, OutlinedInput, Paper, TableContainer, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import "./approve.css"
import { ApprovalDetails } from '../../axios/apis';
import { generateQueryParams } from '../../utils/helperPages';
import ApprovalTable from './ApprovalTable/ApprovalTable';
import PaginationBar from '../UI/PaginationBar';
import UserAccessCheck from '../userAccess/userAccess';
import PageHeader from '../UI/PageHeader';
import Loader from '../UI/Loader';
import ThemeBtn from '../UI/ThemeBtn';
import ReportContext from '../../context/ReportContext';

const Approval = () => {

    const { setReportType } = useContext(ReportContext);
    setReportType("adminIcon");

    const { appReady } = useContext(AppReadyContext);

    const [approvalsData, setApprovalsData] = useState([])

    const [loading, setLoading] = useState(false);
    const [showSearchLimitPopup, setShowSearchLimitPopup] = useState(false);
    const [isReset, setIsReset] = useState(false)
    const searchInputRef = useRef(null);
    const [searchText, setSearchText] = useState("");


    const [sortBy, setSortBy] = useState("appointmentDate"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("desc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };


    // Search by Conatiner IDs
    const handleSearch = () => {
        setLoading(true)

        // Add searchText as containerIds query parameter
        if (searchText.trim() !== "") {
            const containerIds = searchText
                .trim()
                .split(/[,\s]+/)
                .map((id) => id.trim()) // Trim each container ID to remove empty spaces
                .filter((id) => id !== ""); // Filter out empty container IDs

            const sampleObject = {
                PageNumber: pageNo,
                PageSize: rowsPerPage,
                sortingColumn: sortBy,
                sortingOrder: sortOrder,
                searchRequester: containerIds
            }

            if (containerIds.length > 0) {
                if (containerIds.length > 20) {
                    setShowSearchLimitPopup(true);
                    setLoading(false);
                    return;
                }
                approvalCall(generateQueryParams(sampleObject))
            }
        }

    }

    // Handle the Asc and Desc for particular column
    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        const params = generateQueryParams(paramsObject)

        approvalCall(params)
    }


    const fetchApproval = async (pageNumber, pageSize) => {
        setLoading(true)
        const paramsObject = {
            PageNumber: pageNumber,
            PageSize: pageSize,
            // sortingColumn: sortBy,
            // sortingOrder: sortOrder,
        }
        const params = generateQueryParams(paramsObject)
        approvalCall(params)
    }

    const approvalCall = async (params) => {
        try {
            const response = await ApprovalDetails(params)
            setApprovalsData(response.data.contactAccessRequests)
            setTotalRecords(response.data.pageDetails.totalRecords);
            setLoading(false)
            setIsReset(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const clearFilters = () => {
        if (searchText) {
            setLoading(true)
            setSearchText(""); // Clear the search input field
            setRowsPerPage(10);
            setSortBy("containerNumber");
            setSortOrder("asc");
            setPageNo(0);
            setIsReset(true)
        }
    };

    useEffect(() => {
        if (isReset) {
            fetchApproval(1, rowsPerPage)
        }
    }, [isReset])



    useEffect(() => {
        fetchApproval(1, rowsPerPage)
    }, [appReady])


    return (
        <>
            <UserAccessCheck pageName={"Admin"} subPage={"Requests"} />
            {appReady && <>
                <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                    <PageHeader name={"Admin"} subName={"Requests"} />

                    <div className="summary-filters">
                        <div>
                            <FormControl sx={{ width: 400 }} size="small">
                                <OutlinedInput
                                    inputRef={searchInputRef}
                                    id="search-input"
                                    type="text"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder='Search'
                                    onKeyDown={handleKeyDown}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip
                                                title={
                                                    <Typography variant="body2" className="tooltip-title">
                                                        Enter a maximum of 20 Charatcer
                                                    </Typography>
                                                }
                                                placement="top"
                                            >
                                                <InfoIcon fontSize="smaller" />
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <ThemeBtn name="Search" handleClick={handleSearch} disabled={searchText.trim() === ""} />

                        <ThemeBtn name="Reset" handleClick={clearFilters} />
                    </div>

                    <div className="summary-section-3">
                        <TableContainer component={Paper}>
                            {
                                loading ? <Loader /> : <>
                                    <ApprovalTable data={approvalsData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                </>
                            }
                            <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchApproval} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                        </TableContainer>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default Approval