import React, { useContext, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import { Col } from 'react-bootstrap'
import ThemeBtn from '../UI/ThemeBtn'
import AccessContext from '../../context/AccessContext'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import { RemoveDepartment } from '../../axios/apis'
import { successNotification } from '../../helpers'

const DeleteHierachy = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const { singleChart } = useContext(AccessContext)
    const [submitloader, setSubmitLoader] = useState(false)

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(
        actionCreators,
        dispatch
    );

    const validateIsDelete = () => {
        if (singleChart?.children.length !== 0 || singleChart?.userContactList.length !== 0) {
            return true
        } else {
            return false
        }
    }


    const handleDelete = async () => {
        setSubmitLoader(true)
        try {
            const response = await RemoveDepartment({ departmentId: singleChart.departmentId })
            successNotification({
                message: `Group Deleted Successfully`,
                addNotifications,
            });
            fetchDetails("")
            setSubmitLoader(false)
            onClose()
        } catch (error) {
            unexpectedErrorNotification(error.response?.data || "An error occurred while Deleting the Groups", addNotifications);
            setSubmitLoader(false)
            // console.log(error)
        }
    }

    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} >
                <Col lg={12}>
                    {
                        validateIsDelete() ? <div className="input-container">
                            <p><span style={{ color: "#961B13", fontWeight: 500 }}>{singleChart?.department}</span> Group
                                cannot be deleted because Sub Group Or User Exists.
                            </p>
                        </div> : <div className="input-container">
                            <p>To Delete <span style={{ color: "#961B13", fontWeight: 500 }}>{singleChart?.department}</span> Group
                                click on Delete Button.
                            </p>
                        </div>
                    }
                </Col>

                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    {
                        !validateIsDelete() && <ThemeBtn name={btn} loader={submitloader} handleClick={handleDelete} />
                    }
                </div>
            </PopupLayout>
        </>
    )
}

export default DeleteHierachy