import React, { useEffect, useState, useContext, useMemo } from "react";
import axios from "axios";
import "./contacts.css";
import AppReadyContext from "../../context/AppReadyContext";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { useAuth } from "../../context/AuthContext";
import UserAccessCheck from "../userAccess/userAccess";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Box from "@mui/material/Box";
import { addConversation } from "../../api";
import { createConversation } from "../../api";
import { doesConversationExistWithEmail } from "../../api";
import { actionCreators } from "../../store";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useClient } from "../../context/ClientInfoContext";
import { getSdkConversationObject } from "../../conversations-objects";
import { Conversation } from "@twilio/conversations";
import { getContactDetails } from "../../api";
import PageHeader from "../UI/PageHeader";
// import TreeRight from "../AccessManagement/TreeView/TreeRight";
import SearchContext from "../../context/SearchContext";
import AccessContext from "../../context/AccessContext";
import { Container, Row, Col } from "react-bootstrap";
import TreeView from "./ContactsTree";
import SearchNameDropDown from "../UI/SearchNameDropDown";
import { ContactDetailsById, TotalOrgChart } from "../../axios/apis";
import { OrgChart } from "../../axios/apis";
import ThemeBtn from "../UI/ThemeBtn";
import RequestModal from "./RequestModal";
import FinalRequestModal from "./FinalRequestModal";
import ChatLoaderModal from "./ChatLoaderModal";
import ReportContext from "../../context/ReportContext";
import CircleLoader from "../UI/CircleLoader";
import ContactSearchDropdown from "../UI/ContactSearchDropdown";

const Contacts = () => {

  const { appReady } = useContext(AppReadyContext);

  const [pageloader, setPageLoader] = useState(false)

  const { setReportType } = useContext(ReportContext);
  setReportType("contactsIcon");


  const [value, setValue] = React.useState("list");
  const { isRequest, setIsResuest, isFinalRequest, setIsFinalRequest, setOrgChart } = useContext(AccessContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { client, updateConnectionState, updateConversation } = useClient();
  const [convo, setConvo] = useState(null);
  const navigate = useNavigate();

  const sdkConvo = useMemo(() => {
    if (convo) {
      return getSdkConversationObject(convo);
    }
  }, [convo]);

  const { searchText, setFindList, setOrgDetails, setSearchText, setSearchOrg, setSearchTextPopUp, setSearchEmail } = useContext(SearchContext);
  const { singleChart, isChatActive, setIsChatActive } = useContext(AccessContext);
  const [singleList, setSingleList] = useState([]);

  const [contactDetails, setContactDetails] = useState([]);

  const fetchContactDetails = async () => {
    setSearchText("")
    setSearchEmail("")
    setSearchOrg({ name: null, id: null })
    setSearchTextPopUp("")
    setOrgDetails({})
    // setIsChatActive(false)
    try {
      const contactDetails = await ContactDetailsById(localStorage.getItem("user"));
      const responseOrg = await TotalOrgChart()
      if (contactDetails.data) {
        setContactDetails(contactDetails.data.completeOrgChart);
        const filterList = responseOrg.data.completeOrgChart.find((item) => item.organizationName === contactDetails.data.completeOrgChart[0].organizationName)
        setOrgDetails(filterList.orgChart)
        setPageLoader(true)
      } else {
        console.error("Failed to fetch contact details.");
      }
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };


  useEffect(() => {
    fetchContactDetails();
  }, [appReady]);


  return (
    <>

      <UserAccessCheck pageName={"Contacts"} />
      {appReady && (
        !pageloader ? <CircleLoader full={true} /> : <>
          <div className="smy-ctr container-fluid page-content-main">
            <PageHeader name={"Contacts"} />

            <div className="request-btn my-4">
              <ThemeBtn name="Request Contact" handleClick={() => setIsResuest(true)} />
            </div>

            <div className="search-box mb-4">
              <label >Search Contact:</label>
              <ContactSearchDropdown title={"Enter Profile Name"} type={"search"} options={contactDetails} />
            </div>

            <div className="row table-container upload-details">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Container fluid className="p-0 mt-4">
                  <Row>
                    <Col lg={12}>
                      <TreeView singleList={contactDetails} />
                    </Col>
                  </Row>
                </Container>
              </Box>
            </div>
          </div>

          {
            isRequest && <RequestModal open={isRequest} onClose={() => setIsResuest(false)} title={"Request Contact"} btn={"Add"} fetchDetails={fetchContactDetails} />
          }
          {
            isFinalRequest && <FinalRequestModal open={isFinalRequest} onClose={() => setIsFinalRequest(false)} title={"Final Confirmation"} btn={"Add"} fetchDetails={fetchContactDetails} />
          }
          {
            isChatActive && <ChatLoaderModal open={isChatActive} onClose={() => setIsChatActive(true)} />
          }
        </>
      )
      }
    </>
  );
};

export default Contacts;
