import React, { useRef, useEffect, useState, useContext } from "react";
import { firestore, auth, messaging } from "./firebase";
// import firebase from "firebase/compat/app";
import { MessageContext } from "./context/MessageContext";
import { actionCreators } from "./store";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { initializeFCM } from "./firebase";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { onMessage } from "firebase/messaging";
import useVideoContext from "./components/Video-App/hooks/useVideoContext/useVideoContext";

const sendMessage = async (messageBody, recipients, title) => {
  if (title == "Ignore") {
    if (
      localStorage.getItem("accepted") == true ||
      localStorage.getItem("accepted") == "true"
    ) {
      return;
    }
  }
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const uuid = localStorage.getItem("uuid") ? localStorage.getItem("uuid") : "";
  const payload = {
    recepients: recipients,
    title: title,
    body: messageBody,
    fromUid: uuid,
  };
  try {
    const response = await axios.post(
      "https://draytalk-apim.azure-api.net/FireBaseNotification/send-notification",
      payload,
      { headers }
    );
    console.log("Message sent: ", response.data);
  } catch (error) {
    console.error("Error sending message: ", error);
  }
};

const setFcmToken = async (token) => {
  // Set up the request headers
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };

  // const allTokens = await axios.get(
  //   "https://draytalk-apim.azure-api.net/api/FcmToken/v1/GetAllFcmTokens",
  //   { headers }
  // );

  // console.log("All tokens: ", allTokens.data);
  let uuid = localStorage.getItem("uuid");

  if (!uuid) {
    uuid = uuidv4();

    if (!localStorage.getItem("uuid")) {
      localStorage.setItem("uuid", uuid);
    }
  }

  const payload = {
    user: localStorage.getItem("user"),
    uid: uuid,
    token: token,
    status: "online",
  };

  try {
    const setToken = await axios.post(
      "https://draytalk-apim.azure-api.net/api/FcmToken/v1/CreateFcmToken",
      payload,
      { headers }
    );
    // console.log("Token set: ", setToken.data);
  } catch (error) {
    console.error("Error setting token: ", error);
  }
};

function ChatRoom() {
  const {
    message,
    setMessage,
    rejected,
    setRejected,
    rejectType,
    setRejectType,
    incomingMessage,
    setIncomingMessage,
    accepted,
    setAccepted,
    setIsRinging,
    setIsCalling,
    setIsCallingType,
    currentConvo,
    isCallingType
  } = useContext(MessageContext);
  const user = localStorage.getItem("user");
  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);
  const messagesQuery = firestore
    .collection(`users/${user}/messages`)
    .where("Seen", "==", false)
    .orderBy("createdAt")
    .limit(25);

  const { room } = useVideoContext();

  const [messageReceived, setMessageReceived] = useState(null);
  const [notificationId, setNotificationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isCallingWindowOpen, setIsCallingWindowOpen] = useState(false);
  const audioRef = useRef(null);
  let isAcknowledged = false;

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("isWebView") == "false") {
        initializeFCM();
      }
    }, 2000);
  }, []);

  const sendAcknowledgement = async (notificationId) => {
    const payload = {
      notificationId: notificationId,
      deviceId: localStorage.getItem("uuid"),
      Message: "Notification Sent to User!"
    };
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await axios.post(
        "https://draytalk-apim.azure-api.net/FireBaseNotification/acknowledge-notification",
        payload,
        { headers }
      );
      console.log("Acknowledgement sent: ", response);
    } catch (error) {
      console.error("Error sending acknowledgement: ", error);
    }
    // window.alert('Sending acknowledgement')
  };

  useEffect(() => {
    const requestPermission = async () => {
      try {
        await Notification.requestPermission();
        console.log("Notification permission granted.");

        const token = localStorage.getItem("fcmToken");
        console.log("FCM Token:", token);
      } catch (error) {
        console.error("Unable to get permission to notify.", error);
      }
    };

    requestPermission();

    // Handle incoming messages
    onMessage(messaging, (payload) => {
      if (localStorage.getItem("accessToken")) {
        console.log("Message received. ", payload);
        console.log("body : ", JSON.parse(payload.data.data));
        setMessageReceived(JSON.parse(payload.data.data));
        setNotificationId(JSON.parse(payload.data.data).NotificationId);

        if (payload.notification.title === "Acknowledgement") {
          console.log("received acknowledgement");
          setTimeout(() => {
            setIsCallingType("Ringing");
          }, 1500);
        }
        if (payload.notification.title === "Accept") {
          setIsCallingType("Joining");
          console.log("Call Get Accepted");
        }
        if (payload.notification.title === "Call" && !accepted) {
          sendAcknowledgement(
            JSON.parse(payload.data.data).NotificationId
          );
        }
        if (JSON.parse(payload.data.data).Type == "Call") {
          if (accepted) {
            sendMessage(
              {
                text: "Busy",
                sentBy: localStorage.getItem("user"),
                sentTo: [JSON.parse(payload.data.data).SentBy],
                type: "Busy",
                room: JSON.parse(payload.data.data).Room,
                names: [],
                from: localStorage.getItem("user"),
                comment: `${localStorage.getItem("userName")} is busy`,
                seen: false,
                status: "Busy",
              },
              [JSON.parse(payload.data.data).SentBy],
              "Reject"
            );
          } else {
            console.log(
              "received a call: ",
              JSON.parse(payload.data.data)
            );
            setMessage(JSON.parse(payload.data.data));
          }
        }
        if (JSON.parse(payload.data.data).Type == "Reject") {
          console.log("Call Rejected");
          setRejectType("Rejected");
          setRejected(true);
          setIsCallingType(false);
          setAccepted(false);
          setMessage({});
        }
        if (JSON.parse(payload.data.data).Type == "Busy") {
          console.log("User Busy");
          setRejectType("Busy");
          setRejected(true);
          setIsCallingType(false);
          setMessage({});
        }
        if (JSON.parse(payload.data.data).Type == "Ignore") {
          console.log("Call Ignored");
          setRejectType("Ignored");
          setIsCallingType(false);
          setRejected(true);
          setMessage({});
        }
        if (
          JSON.parse(payload.data.data).Type == "Message" &&
          payload.notification.title != "Acknowledgement"
        ) {
          setMessage(JSON.parse(payload.data.data));
          setIncomingMessage(true);
        }
      }
    });
  }, [accepted]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.type === "BACKGROUND_MESSAGE") {
          handleBackgroundMessage(event.data.payload);
          console.log("received");
        }
      });
    }
  }, []);

  const handleBackgroundMessage = (notificationData) => {
    if (localStorage.getItem("accessToken")) {
      console.log("Received Background message");
      if (notificationData.title === "Call") {
        sendAcknowledgement(notificationData.NotificationId);
      }
      if (notificationData.title === "Acknowledgement") {
        console.log("received acknowledgement");
        setTimeout(() => {
          setIsCallingType("Ringing");
        }, 1500);
      }
      if (notificationData.title === "Accept") {
        setIsCallingType("Joining");
      }
      if (notificationData.Type === "Call") {
        setMessage(notificationData);
      } else if (notificationData.Type === "Reject") {
        console.log("Call Rejected");
        setRejectType("Rejected");
        setRejected(true);
        setIsCallingType(false);
        setMessage({});
      } else if (notificationData.Type === "Ignore") {
        console.log("Call Ignored");
        setRejectType("Ignored");
        setRejected(true);
        setIsCallingType(false);
        setMessage({});
      } else if (notificationData.Type === "Busy") {
        console.log("User Busy");
        setRejectType("Busy");
        setRejected(true);
        setIsCallingType(false);
        setMessage({});
      } else if (notificationData.Type === "Message") {
        console.log("Message Received");
        console.log(currentConvo);
        setMessage(notificationData);
        setIncomingMessage(true);
      }
    }
  };

  return <></>;
}

function ChatMessage({ message }) {
  const { text } = message;
  return (
    <div className="message">
      <p>{text}</p>
    </div>
  );
}

export { ChatRoom, sendMessage, setFcmToken };
