export const msalConfig = {
  auth: {
    //PROD credentials
    clientId: '380146ba-8a46-4b3f-b2e8-8a448d199f94',
    authority: 'https://login.microsoftonline.com/52925b26-9cba-4c12-87e6-23007e20f9fa',

    redirectUri: "https://draytalk.com/",
    postLogoutRedirectUri: "https://draytalk.com/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};
