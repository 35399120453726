import React from 'react'
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const ToolTipHover = ({ title, children, userExist = true, position }) => {
    return (
        <>
            {
                userExist ? <Tooltip title={
                    <Typography variant="body2" className="tooltip-title">
                        {title}
                    </Typography>
                } placement={position}>
                    {children}
                </Tooltip> : <>{children}</>
            }
        </>
    )
}

export default ToolTipHover