import React, { useContext, useEffect } from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import { MessageContext } from '../../context/MessageContext';
import CircleLoader from '../UI/CircleLoader';
import EndCallButton from './components/Buttons/EndCallButton/EndCallButton';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function VideoApp() {
  const roomState = useRoomState();
  const { setAccepted, setMessage, setRejectType, setRejected, isCallingType, setIsCallingType } = useContext(MessageContext);


  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  // console.log(roomState)

  useEffect(() => {
    console.log("Changing to ", isCallingType)
    let timeout: any;
    if (isCallingType === "Calling") {
      timeout = setTimeout(() => {
        setMessage({});
        setAccepted(false);
        setRejectType("User Unavailable")
        setIsCallingType(false)
        setRejected(true);
        console.log("Running")
      }, 20000); // 20 seconds
    } else if (isCallingType === "Ringing") {
      timeout = setTimeout(() => {
        setMessage({});
        setAccepted(false);
        setRejectType("No Answer")
        setIsCallingType(false)
        setRejected(true);
      }, 30000); // 30 seconds
    }
    return () => clearTimeout(timeout);
  }, [isCallingType]);



  return (
    <Container >
      {
        isCallingType && isCallingType === "Calling" && <>
          <div style={{ padding: "20px " }}>
            <CircleLoader />
            Calling...
          </div>
        </>
      }

      {
        isCallingType && isCallingType === "Ringing" && <>
          <div style={{ padding: "20px " }}>
            <CircleLoader />
            Ringing...
          </div>
        </>
      }

      {
        (isCallingType && isCallingType === "Joining") && <>
          {(roomState === 'disconnected') ? (
            <PreJoinScreens />
          ) : (
            <Main className='MainContainer'>
              <ReconnectingNotification />
              <RecordingNotifications />
              <MobileTopMenuBar />
              <Room />
              <MenuBar />
            </Main>
          )}
        </>
      }

    </Container >
  );
}
