import axios from "axios";
import {
  Conversation,
  Message,
  Participant,
  Media,
  Client,
  Paginator,
  User,
} from "@twilio/conversations";

import {
  MessageStatus,
  ReduxMessage,
} from "./store/reducers/messageListReducer";
import {
  CONVERSATION_MESSAGES,
  CONVERSATION_PAGE_SIZE,
  PARTICIPANT_MESSAGES,
  USER_PROFILE_MESSAGES,
} from "./constants";
import { NotificationsType } from "./store/reducers/notificationsReducer";
import {
  incomingCallNotification,
  neutralErrorNotification,
  successNotification,
  unexpectedErrorNotification,
} from "./helpers";
import { getSdkMessageObject } from "./conversations-objects";
import { ReduxParticipant } from "./store/reducers/participantsReducer";
import { getSdkConversationObject } from "./conversations-objects";
import { string } from "prop-types";
import UpdateConvoNameContext from "./context/UpdateConvoName";
import { useContext } from "react";

type ParticipantResponse = ReturnType<typeof Conversation.prototype.add>;

type ContactDetails = {
  // Define the structure based on your API response
  // For example:
  name: string;
  email: string;
  // ... other fields
};
// Define the type for the API response
type CreateConversationApiResponse = {
  sid: string;
  participants: string;
  access: boolean;
  active: boolean;
};

// export async function storeToken(token: string) {

// }

export async function addConversation(
  name: string,
  updateParticipants: (participants: Participant[], sid: string) => void,
  client?: Client,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Conversation> {
  if (client === undefined) {
    throw new Error(
      "Client is suddenly undefined, are you sure everything is ok?"
    );
  }

  if (name.length === 0) {
    throw new Error("Conversation name is empty");
  }

  try {
    const conversation = await client.createConversation({
      friendlyName: name,
    });
    await conversation.join();

    const participants = await conversation.getParticipants();
    updateParticipants(participants, conversation.sid);

    // successNotification({
    //   message: CONVERSATION_MESSAGES.CREATED,
    //   addNotifications,
    // });

    return conversation;
  } catch (e) {
    // unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
}

export async function createConversation(
  name: string,
  updateParticipants: (participants: Participant[], sid: string) => void,
  client?: Client,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Conversation | null> {
  try {
    const conversation = await addConversation(
      name + localStorage.getItem("user"),
      updateParticipants,
      client,
      addNotifications
    );
    // console.log("New Conversation: ", conversation);
    // Now that the conversation is created, save conversation in convo table
    const postedDetails = await createConversationDetails(conversation);
    try {
      // Add participants to the conversation
      await addChatParticipant(name, conversation, addNotifications);
    } catch (err) {
      //if any error occured in adding participants, delete the conversation
      await deleteConversation(conversation, addNotifications);
      return null;
    }

    // console.log(postedDetails);
    return conversation;
  } catch (error) {
    console.error("Error creating conversation:", error);
    neutralErrorNotification(
      "User is not registered with DrayTalk",
      addNotifications
    );
    return null;
  }
}

export function fetchName(participant: ReduxParticipant): string {
  let name: string = participant.identity ?? "unknown";
  if (participant.attributes != null) {
    const friendlyName: string | null =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      participant.attributes["friendlyName"];
    if (friendlyName != null) {
      name = friendlyName;
    }
  }
  return name;
}

// Function to create a conversation and send details to the API
export async function createConversationDetails(
  conversation: Conversation
): Promise<CreateConversationApiResponse> {
  try {
    // Get conversation details
    const sid = conversation.sid;
    const participants = await conversation.getParticipants();
    const participantsEmail = [];
    for (let i = 0; i < participants.length; i++) {
      const participant = participants[i];
      const name = fetchName(participant);
      participantsEmail.push(name);
    }

    // Prepare the access array based on participantsEmail
    const access = participantsEmail.map((email) => ({
      participantEmailId: email,
      participantAccess: true,
    }));

    // Prepare the request body
    const requestBody = {
      sid,
      conversationName: "",
      participants: participantsEmail.join(","),
      access,
      active: true,
    };

    // Set up the request headers
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };

    // Make the POST request to the API
    const response = await axios.post(
      "https://draytalk-apim.azure-api.net/api/Conversation/v1/Create",
      requestBody,
      { headers }
    );

    // Return the API response data
    return response.data;
  } catch (error) {
    console.error("Error creating conversation details:", error);
    throw error;
  }
}

export const deleteConversation = async (
  conversation: Conversation,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<void> => {
  try {
    // Remove all participants from the conversation
    const participants = await conversation.getParticipants();
    await Promise.all(
      participants.map((participant) =>
        conversation.removeParticipant(participant)
      )
    );

    // Delete the conversation
    await conversation.delete();

    // successNotification({
    //   message: CONVERSATION_MESSAGES.DELETED,
    //   addNotifications,
    // });
  } catch (e) {
    // unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
};

export async function addChatParticipant(
  name: string,
  convo?: Conversation,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<ParticipantResponse> {
  // const updateConvoNameContext = useContext(UpdateConvoNameContext);
  if (convo === undefined) {
    throw new Error(
      "Conversation is suddenly undefined, are you sure everything is ok?"
    );
  }

  if (name.length === 0) {
    throw new Error("Participant name is empty");
  }

  try {
    const result = await convo.add(name);
    console.log(result)
    // console.log(convo.sid);
    const participants = await convo.getParticipants();
    // console.log(participants);
    const participantsList = [];
    for (let i = 0; i < participants.length; i++) {
      participantsList.push(participants[i].identity);
    }
    // console.log(participantsList);
    // Prepare the access array based on participantsEmail
    const access = participantsList.map((email) => ({
      participantEmailId: email,
      participantAccess: true,
    }));

    const requestBody = {
      sid: convo.sid,
      conversationName: "",
      participants: participantsList.join(","),
      access,
      active: true,
    };

    // Set up the request headers
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };

    // Make the POST request to the API
    const requestURL = process.env.REACT_APP_APIM_SERVICE_URL as string;
    const response = await axios.put(
      `${requestURL}Conversation/v1/Update`,
      requestBody,
      { headers }
    );
    // const randomValue = new Date().getTime();
    // updateConvoNameContext.setConvoName(randomValue);

    // successNotification({
    //   message: PARTICIPANT_MESSAGES.ADDED,
    //   addNotifications,
    // });

    return result;
  } catch (e) {
    neutralErrorNotification(
      "User is not registered with DrayTalk",
      addNotifications
    );
    throw e;
  }
}

export const doesConversationExistWithEmail = async (
  client: Client,
  email: string
): Promise<Conversation | null> => {
  try {
    // Retrieve conversations where the participant has the specified email
    const paginator = await client.getSubscribedConversations();
    const conversations = await paginator.items;

    if (conversations && conversations.length > 0) {
      // Iterate over conversations to check if the email is present in participants
      for (const conversation of conversations) {
        const participants = await conversation.getParticipants();
        const participantWithEmail = participants.find(
          (participant) => participant.identity === email
        );
        if (participantWithEmail) {
          // If the participant with the given email is found, return true
          return conversation;
        }
      }
    }

    // If no conversation with the participant's email was found, return false
    return null;
  } catch (error) {
    console.error("Error checking conversation existence:", error);
    return null; // Return false in case of an error
  }
};

export const doesConversationExistWithEmails = async (
  client: Client,
  items: any[]
): Promise<Conversation | null> => {
  try {
    // Retrieve conversations where the participants have the specified emails
    const paginator = await client.getSubscribedConversations();
    const conversations = await paginator.items;
    // console.log(conversations);
    // console.log(items);
    if (conversations && conversations.length > 0) {
      // Iterate over conversations to check if all emails are present in participants
      for (const conversation of conversations) {
        const participants = await conversation.getParticipants();

        // Find and remove participant with your email (localStorage.getItem('user'))
        const filteredParticipants = participants.filter(
          (participant) => participant.identity !== localStorage.getItem("user")
        );
        // console.log("fltered Participants: ", filteredParticipants);
        // Check if the conversation has the same number of participants as emails
        if (filteredParticipants.length === items.length) {
          const participantEmails = filteredParticipants.map(
            (participant) => participant.identity
          );
          // console.log("participant email in convo: ", participantEmails);

          // Check if the emails in items exactly match the participant's identity
          const allMatch = items.every((i) => {
            // console.log(i.userEmailId);
            // console.log(participantEmails.includes(i.userEmailId));
            return participantEmails.includes(i.userEmailId);
          });

          if (allMatch) {
            // console.log(
            //   "Conversation exists with the same people -  ",
            //   conversation
            // );
            return conversation;
          } else {
            // console.log(
            //   "No conversation exists with the same people - create new "
            // );
          }
        } else {
          // console.log(
          //   "No conversation exists with the same people - create new "
          // );
        }
      }
    }

    // If no conversation with the specified participants was found, return null
    return null;
  } catch (error) {
    console.error("Error checking conversation existence:", error);
    return null; // Return null in case of an error
  }
};

export async function createGroupConversation(
  names: [any],
  updateParticipants: (participants: Participant[], sid: string) => void,
  client?: Client,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Conversation | null> {
  try {
    const fName = [];
    for (let i = 0; i < names.length; i++) {
      try {
        fName.push(names[i].userEmailId);
      } catch (error) {
        // console.log(error);
      }
    }
    const conversation = await addConversation(
      "Group Chat",
      updateParticipants,
      client,
      addNotifications
    );
    // Now that the conversation is created, you can add a participant to it
    try {
      for (let i = 0; i < names.length; i++) {
        try {
          await addChatParticipant(
            names[i].userEmailId,
            conversation,
            addNotifications
          );
        } catch (error) {
          // console.log(error);
          unexpectedErrorNotification(
            "Couldn't add participant",
            addNotifications
          );
        }
      }
    } catch (err) {
      await deleteConversation(conversation, addNotifications);
      return null;
    }
    const postedDetails = await createConversationDetails(conversation);
    return conversation;
  } catch (error) {
    console.error("Error creating conversation:", error);

    neutralErrorNotification(
      "User is not registered with DrayTalk",
      addNotifications
    );
    return null;
  }
}

export async function addNonChatParticipant(
  number: string,
  proxyNumber: string,
  convo?: Conversation,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<ParticipantResponse> {
  if (convo === undefined) {
    throw new Error(
      "Conversation is suddenly undefined, are you sure everything is ok?"
    );
  }

  if (number.length === 0 || proxyNumber.length === 0) {
    throw new Error(
      "Both participant number and proxy number must be specified"
    );
  }

  try {
    const result = await convo.addNonChatParticipant(proxyNumber, number, {
      friendlyName: number,
    });
    successNotification({
      message: PARTICIPANT_MESSAGES.ADDED,
      addNotifications,
    });

    return result;
  } catch (e) {
    // unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
}

export async function addCallNotification(
  name: string,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<any> {
  try {
    incomingCallNotification(`Incoming call from ${name}`, addNotifications);
  } catch (error) {
    // unexpectedErrorNotification(error.message, addNotifications);
  }
}

export async function readUserProfile(
  identity: string,
  client?: Client
): Promise<User | undefined> {
  try {
    return await client?.getUser(identity);
  } catch (e) {
    // unexpectedErrorNotification(e.message);
    throw e;
  }
}

export async function updateFriendlyName(
  friendlyName: string,
  user?: User
): Promise<User | undefined> {
  try {
    const result = await user?.updateFriendlyName(friendlyName);
    successNotification({
      message: USER_PROFILE_MESSAGES.FRIENDLY_NAME_UPDATED,
    });
    return result;
  } catch (e) {
    // unexpectedErrorNotification(e.message);
    throw e;
  }
}

export async function getToken(
  username: string,
  password: string
): Promise<string> {
  const requestAddress = process.env
    .REACT_APP_ACCESS_TOKEN_SERVICE_URL as string;
  if (!requestAddress) {
    throw new Error(
      "REACT_APP_ACCESS_TOKEN_SERVICE_URL is not configured, cannot login"
    );
  }

  try {
    const response = await axios.get(requestAddress, {
      params: { identity: username },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      throw new Error(error.response.data ?? "Authentication error.");
    }

    console.error(`ERROR received from ${requestAddress}: ${error}\n`);
    throw new Error(`ERROR received from ${requestAddress}: ${error}\n`);
  }
}

export const getContactDetails = async (
  userId: string,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<ContactDetails | null> => {
  try {
    const requestURL = process.env.REACT_APP_APIM_SERVICE_URL as string;
    const response = await axios.get(`${requestURL}UserContact/v1/Details`, {
      params: { emailId: userId },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    // Assuming the API response contains the contact details
    const contactDetails: ContactDetails = response.data;

    return contactDetails;
  } catch (error) {
    // unexpectedErrorNotification(error.message, addNotifications);
    return null;
  }
};

export async function getTitle(sid: string): Promise<string | null> {
  try {
    const requestURL = process.env.REACT_APP_APIM_SERVICE_URL as string;
    const response: any = await axios.get(
      `${requestURL}Conversation/v1/Detail`,
      {
        params: { sid },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    // Ensure participants is an array, default to an empty array if undefined
    const participants = response.data.participants || [];
    // const participants2 = await conversation.getParticipants();

    // Filter out the userId from participants
    const filteredParticipants = participants.filter(
      (participant: string) => participant !== localStorage.getItem("user")
    );

    return filteredParticipants;
  } catch (error) {
    // unexpectedErrorNotification(error.message);
    console.error("Unable to read conversation from DB: ", error);
    return null;
  }
}

export async function getMessageStatus(
  message: ReduxMessage,
  channelParticipants: ReduxParticipant[]
): Promise<{
  [MessageStatus.Delivered]?: number;
  [MessageStatus.Read]?: number;
  [MessageStatus.Failed]?: number;
  [MessageStatus.Sending]?: number;
}> {
  // FIXME should be: return statuses[message.sid];
  // after this modification:
  // message.on("updated", ({ message, updateReasons }) => {
  // if reason includes "deliveryReceipt" {
  //   // paginate detailed receipts
  //   const receipts = await message.getDetailedDeliveryReceipts(); // paginated backend query every time
  // }
  // });

  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }

  channelParticipants.forEach((participant) => {
    if (
      participant.identity == localStorage.getItem("user") ||
      participant.type !== "chat"
    ) {
      return;
    }

    if (
      participant.lastReadMessageIndex &&
      participant.lastReadMessageIndex >= message.index
    ) {
      statuses[MessageStatus.Read] += 1;
    } else if (participant.lastReadMessageIndex !== -1) {
      statuses[MessageStatus.Delivered] += 1;
    }
  });

  if (message.aggregatedDeliveryReceipt) {
    const sdkMessage = getSdkMessageObject(message);
    const receipts = await sdkMessage.getDetailedDeliveryReceipts(); // paginated backend query every time

    receipts.forEach((receipt) => {
      if (receipt.status === "read") {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === "delivered") {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === "failed" || receipt.status === "undelivered") {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === "sent" || receipt.status === "queued") {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}

// export const removeParticipant = async (
//   conversation: Conversation,
//   sid: any,
//   participant: Participant,
//   addNotifications?: (notifications: NotificationsType) => void
// ): Promise<void> => {
//   try {
//     await conversation.removeParticipant(participant);

//     const requestURL = process.env.REACT_APP_APIM_SERVICE_URL as string;
//     const response: any = await axios.get(
//       `${requestURL}Conversation/v1/Detail`,
//       {
//         params: { sid },
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//         },
//       }
//     );

//     successNotification({
//       message: PARTICIPANT_MESSAGES.REMOVED,
//       addNotifications,
//     });
//   } catch (e) {
//     unexpectedErrorNotification(e.message, addNotifications);
//     throw e;
//   }
// };

export const removeParticipant = async (
  conversation: Conversation,
  sid: any,
  participant: Participant,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<void> => {
  try {
    // Remove participant in conversation on twilio

    await conversation.removeParticipant(participant);

    // Remove participant locally
    const requestURL = process.env.REACT_APP_APIM_SERVICE_URL as string;
    const response: any = await axios.get(
      `${requestURL}Conversation/v1/Detail`,
      {
        params: { sid },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    // Find and remove the participant from the local data
    const updatedAccess = response.data.access.filter(
      (access: any) => access.participantEmailId !== participant.identity
    );

    const updatedParticipants = response.data.participants.filter(
      (p: any) => p !== participant.identity
    );

    // Create payload for the update API
    const updatePayload = {
      sid: sid,
      conversationName: response.data.conversationName,
      participants: updatedParticipants.map((p: any) => p).join(","),
      access: updatedAccess,
    };

    // Make the update API call
    await axios.put(
      `${requestURL}Conversation/v1/Update?Sid=${sid}`,
      updatePayload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    successNotification({
      message: PARTICIPANT_MESSAGES.REMOVED,
      addNotifications,
    });
  } catch (e) {
    // unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
};

export const getBlobFile = async (
  media: Media,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Blob> => {
  try {
    const url = await getFileUrl(media);
    const response = await fetch(url);
    return response.blob();
  } catch (e) {
    // unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
};

export const getFileUrl = async (media: Media): Promise<string> => {
  return await media.getContentTemporaryUrl().then();
};

export const getMessages = async (
  conversation: Conversation
): Promise<Paginator<Message>> =>
  await conversation.getMessages(CONVERSATION_PAGE_SIZE);
