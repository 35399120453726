import * as React from "react";
import { useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import CallIcon from "@mui/icons-material/Call";
import VideoApp from "../Video-App/App";
import IncomingCallAlert from "./IncomingCallAlert";
import { MessageContext } from "../../context/MessageContext";
import useParticipants from "../Video-App/hooks/useParticipants/useParticipants";

const CallingWindow = () => {
  const { message, setMessage, accepted, setAccepted, menuOpen, setMenuOpen } =
    useContext(MessageContext);
  const participants = useParticipants();

  const [open, setOpen] = React.useState(true);
  const popUpRef = React.useRef(null);

  // console.log(participants)

  useEffect(() => {
    if (message && !message.IsSeen) {
      // console.log("message received: ", message);
      setOpen(true);
    }
  }, [menuOpen, message, accepted]);

  const handleClick = () => {
    setOpen(!open);
  };

  const onClickOutside = (elementRef, callback) => {
    document.addEventListener("click", (e) => {
      if (!elementRef.current || !elementRef.current.contains(e.target)) {
        callback();
      }
    });
  };

  const handleMenuItemClick = (event) => {
    // Prevent the menu from closing
    event.stopPropagation();
  };


  return (
    <>
      {accepted && (
        <>
          <div ref={popUpRef}>
            <Box
              className="CallingWindowBox"
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Account Details" position={"bottom"}>
                <CallIcon
                  id="account-menu-anchor"
                  onClick={handleClick}
                  style={{ fontSize: "35px", color: "red", cursor: "pointer" }}
                />
              </Tooltip>
            </Box>

            <div className={`call-window-popup ${open ? "visible" : "d-none"}`}>
              <VideoApp />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CallingWindow;
