import {
  Anchor,
  Box,
  ModalBody,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
  Button,
} from "@twilio-paste/core";
import { MenuButton, Menu, MenuItem, useMenuState } from "@twilio-paste/menu";
import { Text } from "@twilio-paste/text";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { Avatar } from "../Avatar";
import { UserIcon } from "@twilio-paste/icons/cjs/UserIcon";
import ConvoModal from "./ConvoModal";
import { Content } from "../../types";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { RelationshipById } from "../../axios/apis";
import { useState, useEffect, useContext } from "react";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { AccessFlags } from "../../axios/apis";
import { updateFlags } from "../../axios/apis";
import { readUserProfile } from "../../api";
import { Client } from "@twilio/conversations";
import AccessContext from "../../context/AccessContext";

interface ManageParticipantsModalProps {
  convoSid: string;
  participantsCount: number;
  handleClose: () => void;
  isModalOpen: boolean;
  title: string;
  onClick: (content: Content) => void;
  participantsList: ReduxParticipant[];
  client?: Client;
  accessData: string;
  onParticipantRemove: (participant: ReduxParticipant) => void;
}

const ManageParticipantsModal: React.FC<ManageParticipantsModalProps> = (
  props: ManageParticipantsModalProps
) => {
  const menu = useMenuState({ placement: "bottom-start" });
  interface ParticipantRelation {
    emailId: string;
    relation: string;
  }
  interface AccessFlags {
    participantEmailId: string;
    participantAccess: boolean;
  }
  const [participantRelationships, setParticipantRelationships] = useState<
    ParticipantRelation[]
  >([]);


  const [accessFlags, setAccessFlags] = useState<AccessFlags[]>([]);
  const [convoDetails, setConvoDetails] = useState<any>(null);
  const { orgChart, isChatAccess } = useContext(AccessContext);

  interface UserContact {
    id: string;
    userName: string;
    userEmailId: string;
    userJobRole: string;
    userDepartment: string;
    parentId: number;
  }
  interface TreeNode {
    department: string;
    userContactList: UserContact[];
    children: TreeNode[];
  }
  const findUserByEmail = (
    tree: TreeNode | null,
    email: string
  ): any | null => {
    if (!tree) return null;
    // Check the current level of the tree
    const user = tree.userContactList.find(
      (user) => user.userEmailId === email
    );
    if (user) {
      return user.userName; // Found the user at the current level
    }

    // If not found at the current level, recursively search in children
    for (const child of tree.children) {
      const result = findUserByEmail(child, email);
      if (result) {
        return result; // Found in the children
      }
    }

    return null; // Not found in the current branch
  };

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const { participantsList } = props;
        const participantEmails = participantsList.map((participant) => {
          // Check if 'attributes' exists before accessing 'friendlyName'
          return participant.attributes ? participant.identity : null;
        });

        // Call the API
        const response = await RelationshipById(
          localStorage.getItem("user"),
          participantEmails
        );

        // Update the state with the API response
        setParticipantRelationships(response.data.participants);
      } catch (error) {
        console.error("Error fetching participant relationships:", error);
      }
    };
    const getAccessInfo = async () => {
      try {
        const access = await AccessFlags(props.convoSid);
        setConvoDetails(access.data);
        setAccessFlags(access.data.access);
      } catch (error) {
        console.error("Error fetching access flags:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
    getAccessInfo();
  }, [props.participantsList]);

  // const handleIconClick = async (userIdentity: string| null) => {
  //   try {
  //     // Find the index of the user in the participantsList
  //     const userIndex = props.participantsList.findIndex(
  //       (participant) => participant.identity === userIdentity
  //     );

  //     // Toggle the access flag
  //     const updatedAccessFlags = [...accessFlags];
  //     for(let i=0; i< updatedAccessFlags.length; i++){
  //       if(updatedAccessFlags[i].participantEmailId==userIdentity){
  //         updatedAccessFlags[i].participantAccess = !updatedAccessFlags[i].participantAccess
  //       }
  //     }
  //     updatedAccessFlags[userIndex].participantAccess = updatedAccessFlags[
  //       userIndex
  //     ].participantAccess;

  //     // Prepare the API request body
  //     const requestBody = {
  //       sid: props.convoSid,
  //       conversationName:convoDetails.conversationName,
  //       participants: props.participantsList
  //         .map((participant) => participant.identity)
  //         .join(","),
  //       access: updatedAccessFlags.map((access) => ({
  //         participantEmailId: access.participantEmailId,
  //         participantAccess: access.participantAccess,
  //       })),
  //       active: true,
  //     };

  //     // Make the API call to update accessFlags
  //     const response = await updateFlags(props.convoSid, requestBody);
  //     // Handle the response
  //     if (response) {
  //       // Update the state with the updated accessFlags
  //       setAccessFlags(updatedAccessFlags);
  //     } else {
  //       // Handle error response
  //       console.error("Error updating accessFlags:", response);
  //     }
  //   } catch (error) {
  //     console.error("Error updating accessFlags:", error);
  //   }
  // };

  const handleIconClick = async (userIdentity: string | null) => {
    try {
      // Find the index of the user in the participantsList
      const userIndex = props.participantsList.findIndex(
        (participant) => participant.identity === userIdentity
      );


      // Find the corresponding participant in accessFlags based on identity
      const accessIndex = accessFlags.findIndex(
        (access) => access.participantEmailId === userIdentity
      );
      const updatedFlags = [];
      for (let i = 0; i < accessFlags.length; i++) {
        if (accessFlags[i].participantEmailId == userIdentity) {
          updatedFlags.push({
            participantEmailId: userIdentity,
            participantAccess: !accessFlags[i].participantAccess,
          });
        } else {
          updatedFlags.push(accessFlags[i]);
        }
      }

      // Prepare the API request body
      const requestBody = {
        sid: props.convoSid,
        conversationName: convoDetails.conversationName,
        participants: props.participantsList
          .map((participant) => participant.identity)
          .join(","),
        access: updatedFlags,
        active: true,
      };

      // Make the API call to update accessFlags
      const response = await updateFlags(props.convoSid, requestBody);
      // Handle the response
      if (response) {
        // Update the state with the updated accessFlags
        setAccessFlags(updatedFlags);
      } else {
        // Handle error response
        console.error("Error updating accessFlags:", response);
      }
    } catch (error) {
      console.error("Error updating accessFlags:", error);
    }
  };

  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "6px",
              }}
            >
              <Box
                fontFamily="fontFamilyText"
                fontWeight="fontWeightBold"
                fontSize="fontSize30"
                lineHeight="lineHeight60"
              >
                Participants ({props.participantsCount})
              </Box>
              {
                props.participantsCount <= 2 ? <Button
                  variant="secondary"
                  onClick={() => props.onClick(Content.AddChat)}
                >
                  Add Participant
                </Button> : props.accessData.toLowerCase() === localStorage.getItem("user")?.toLowerCase() && <Button
                  variant="secondary"
                  onClick={() => props.onClick(Content.AddChat)}
                >
                  Add Participant
                </Button>
              }
              {/* <MenuButton {...menu} variant="secondary">
                Add Participant <ChevronDownIcon decorative size="sizeIcon10" />
              </MenuButton>

              <Menu {...menu} aria-label="Preferences">
                <MenuItem
                  {...menu}
                  onClick={() => props.onClick(Content.AddSMS)}
                >
                  SMS Participant
                </MenuItem>
                <MenuItem
                  {...menu}
                  onClick={() => {
                    props.onClick(Content.AddWhatsApp);
                  }}
                >
                  WhatsApp Participant
                </MenuItem>
                <MenuItem
                  {...menu}
                  onClick={() => {
                    props.onClick(Content.AddChat);
                  }}
                >
                  Chat Participant
                </MenuItem>
              </Menu> */}
            </Box>
            <Box
              style={{
                marginTop: "12px",
                overflow: "hidden",
                overflowY: "auto",
                maxHeight: "500px",
              }}
            >
              <Table>
                <THead hidden={true}>
                  <Tr>
                    <Th width="size10" />
                    <Th width="size40" textAlign="left" />
                    <Th textAlign="right" />
                  </Tr>
                </THead>
                <TBody>
                  {props.participantsList.length ? (
                    props.participantsList.map((user, index) => (
                      <Tr key={user.sid}>
                        <Td width="size20">
                          <Avatar
                            size="sizeIcon80"
                            name={
                              (user.identity ||
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                user.attributes["friendlyName"]) ??
                              "unknown"
                            }
                          />
                        </Td>
                        <Td textAlign="left">
                          <Text as="span" textAlign="left">
                            {user.type == "chat"
                              ? findUserByEmail(orgChart[0], user.identity ? user.identity : '')
                              : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              (user.attributes["friendlyName"] as string) ??
                              "unknown"}
                          </Text>
                        </Td>

                        {/* <Td textAlign="right">
                          {Array.isArray(participantRelationships) &&
                          participantRelationships.length > 0
                            ? participantRelationships.find(
                                (relation) => relation.emailId === user.identity
                              )?.relation
                            : ""}
                        </Td> */}

                        <Td textAlign="right">
                          {Array.isArray(participantRelationships) &&
                            participantRelationships.length > 0
                            ? (() => {
                              const relation = participantRelationships.find(
                                (relation) =>
                                  relation.emailId === user.identity
                              )?.relation;

                              // Check if the relation is 'child' and render the image
                              if (relation === "Child") {
                                return (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    onClick={() =>
                                      handleIconClick(user.identity)
                                    }
                                  >
                                    {accessFlags.find(
                                      (access) =>
                                        access.participantEmailId ===
                                        user.identity
                                    )?.participantAccess ? (
                                      <LinkIcon />
                                    ) : (
                                      <LinkOffIcon />
                                    )}
                                  </Box>
                                );
                              }
                              return null;
                            })()
                            : ""}
                        </Td>
                        <Td textAlign="right">
                          {accessFlags[index]?.participantAccess}
                        </Td>

                        {
                          props.accessData.toLowerCase() === localStorage.getItem("user")?.toLowerCase() ? <>
                            <Td textAlign="right">
                              {user.identity !== localStorage.getItem("user") ? (
                                <>
                                  {
                                    <Anchor
                                      href="#"
                                      onClick={() => props.onParticipantRemove(user)}
                                    >
                                      Remove
                                    </Anchor>
                                  }
                                </>
                              ) : null}
                            </Td>
                          </> : <Td textAlign="right">
                            {user.identity === props.accessData ? (
                              <>
                                <span className="group-admin">ADMIN</span>
                              </>
                            ) : null}
                          </Td>
                        }
                      </Tr>
                    ))
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "400px",
                      }}
                    >
                      <Box
                        style={{
                          color: "#606B85",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "12px",
                          }}
                        >
                          <UserIcon
                            decorative={false}
                            title="No participants"
                            size="sizeIcon40"
                            style={{
                              color: "#606B85",
                            }}
                          />
                        </Box>
                        <Text
                          as="p"
                          fontSize="fontSize40"
                          style={{
                            color: "#606B85",
                          }}
                        >
                          No participants
                        </Text>
                      </Box>
                    </Box>
                  )}
                </TBody>
              </Table>
            </Box>
          </ModalBody>
        }
      />
    </>
  );
};

export default ManageParticipantsModal;
