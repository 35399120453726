import axios from "axios";

const API = axios.create({ baseURL: "https://draytalk-apim.azure-api.net/api/" })

API.interceptors.request.use((req) => {
    // Interceptor is use the add value in the Header globally in the API call
    req.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`
    return req;
})

// Normal - For All
export const UNINav = () => API.get('Navigation/v1/Details')
export const UserDetails = () => API.get('User/v1/Details')
export const UserRole = () => API.get('User/v1/Roles')
export const TreeData = (emailId) => API.get(`UserContact/v2/Details?emailId=${emailId}`)
export const UserCount = (departmentID) => API.get(`UserContact/v1/GroupUsersDetail?departmentId=${departmentID}`)

// export const TreeData = () => API.get('UserContact/v1/Details')
export const OrgChart = () => API.get('UserContact/v1/OrgChart')
export const TotalOrgChart = () => API.get('UserContact/v1/TotalOrgChart')
export const ContactDetailsById = (emailId) => API.get(`UserContact/v2/Details?emailId=${emailId}`)
export const RelationshipById = (email, emails) => API.post('UserContact/v1/Relationship', { emailId: email, participants: emails })
export const AccessFlags = (sid) => API.get(`Conversation/v1/Detail?Sid=${sid}`)
export const updateFlags = (sid, payload) => API.put(`Conversation/v1/Update?Sid=${sid}`, payload)

export const ApprovalDetails = (params) => API.get(`AccessManagement/v1/Requests?${params.join("&")}`)
export const SendContactRequest = (data) => API.post('UserContact/v1/Request', data)
export const ApproveDeclineUser = (data) => API.post('AccessManagement/v1/PerformContactRequestAccess', data)

export const CreateOrganization = (data) => API.post('UserManagement/v2/CreateOrganization', data)
export const CreateRootGroup = (data) => API.post('UserManagement/v2/CreateFirstDepartment', data)

export const CreateDepartment = (data) => API.post('UserManagement/v1/CreateDepartment', data)
export const CreateUser = (data) => API.post('UserManagement/v1/CreateUser', data)

export const RemoveUser = (userEmailId) => API.post(`UserManagement/v1/DeleteUser?userEmailId=${userEmailId}`)
export const RemoveDepartment = (data) => API.post(`UserManagement/v1/DeleteDepartment`, data)

export const UpdateRole = (data) => API.post('UserManagement/v1/UpdateUserRole', data)
export const MoveUserLevel = (data) => API.post('UserManagement/v1/UpdateUserDepartment', data)
export const MoveGroupLevel = (data) => API.post('UserManagement/v2/MoveDepartment', data)


export const CustomerList = () => API.get('UserManagement/v1/Customers')
export const RolesList = () => API.get('UserManagement/v1/Roles')
export const RecentCalls = (email) => API.get(`https://draytalk-apim.azure-api.net/FireBaseNotification/v1/GetLast30DaysNotificaitonsByEmailId?userEmailId=${email}`)



// Rules API
export const RulesList = (customerName) => API.get(`UserContact/v2/CustomerRuleSet?customerName=${customerName}`)
export const CreateRuleSet = (data) => API.post('UserContact/v2/RuleSet', data)
export const RulesById = (id) => API.get(`UserContact/v2/RuleSetDetails?RuleId=${id}`)
export const UpdateRuleDetails = (data) => API.post('UserContact/v2/EditRuleSet', data)
export const DeleteReplaceRule = (data) => API.post('UserManagement/v2/DeleteAndReplaceRule', data)
export const DeleteEmptyRule = (data) => API.post('UserManagement/v2/DeleteRule', data)

export const CreateUserWithRule = (data) => API.post('UserManagement/v2/CreateUser', data)
export const CreatDepartWithRule = (data) => API.post('UserManagement/v2/CreateDepartment', data)

export const AssignRuleToExistingUser = (data) => API.post('UserManagement/v2/AssignRuleToExistingUser', data)
export const AssignRuleToExistingDepartment = (data) => API.post('UserManagement/v2/AssignRuleToExistingDepartment', data)

export const AssignCustomRuleToExistingUser = (data) => API.post('UserManagement/v2/AssignCustomRuleToExistingUser', data)
export const AssignCustomRuleToExistingGroup = (data) => API.post('UserManagement/v2/AssignCustomRuleToExistingDepartment', data)