import React, { useContext, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import { Col } from 'react-bootstrap'
import ThemeBtn from '../UI/ThemeBtn'
import SearchContext from '../../context/SearchContext'
import AccessContext from '../../context/AccessContext'
import { successNotification, unexpectedErrorNotification } from '../../helpers'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import { ApproveDeclineUser, SendContactRequest } from '../../axios/apis'

const RemoveContactModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const { searchText } = useContext(SearchContext);
    const { singleChart, singleUser, setSingleChart, setIsRefreshTree } = useContext(AccessContext);

    const [submitloader, setSubmitLoader] = useState(false)

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(
        actionCreators,
        dispatch
    );


    const handleRemove = async (type) => {
        setSubmitLoader(true)
        const payload = {
            userEmailId_From: singleChart,
            userEmailId_To: singleUser.userEmailId,
            request_Type: type
        }
        try {
            const response = await SendContactRequest(payload)
            if (response.status === 200) {
                try {
                    const resApprove = await ApproveDeclineUser({ requestId: response.data.requestId, request_Action: "Approved" })
                    successNotification({
                        message: `User Removed Successfully`,
                        addNotifications,
                    });
                    onClose()
                    // fetchDetails("")
                    setSubmitLoader(false)
                    setIsRefreshTree(true)
                    setTimeout(() => {
                        setIsRefreshTree(false)
                    }, 1000);
                } catch (err) {
                    onClose()
                    setSubmitLoader(false)
                }
            }
        } catch (error) {
            onClose()
            setSubmitLoader(false)
            unexpectedErrorNotification(`${searchText} is not exist the Contact List`, addNotifications)
        }
    }

    return (
        <PopupLayout open={open} onClose={onClose} title={title}>

            <Col lg={12}>
                <div className="input-container">
                    <p>To Remove <span style={{ color: "#961B13", fontWeight: 500 }}>{singleUser.userName}</span> from <span style={{ color: "#961B13", fontWeight: 500 }}>{searchText}</span> Contact click on Remove Button .</p>
                </div>
            </Col>

            <div className='d-flex justify-content-end gap-4'>
                <button className='cancel-btn' onClick={onClose}>Cancel</button>
                <ThemeBtn name="Remove" loader={submitloader} handleClick={() => handleRemove("remove")} />
            </div>
        </PopupLayout>
    )
}

export default RemoveContactModal