import React, { useEffect, useState } from 'react'
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { RulesById } from '../../axios/apis';

const RulesToolTip = ({ list, selectedId, selectedName = "", type = "add" }) => {

    const [loader, setLoader] = useState(true)
    const [rulesDetail, setRuleDetails] = useState({})

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 300,
            fontSize: theme.typography.pxToRem(11),
            border: "1px solid #dadde9",
        },
    }));

    const getRules = (ruleData) => {
        const filterData = list.find((rule) => rule.ruleSetId === selectedId)
        if (ruleData === "name") {
            return filterData?.ruleSetName
        } else if (ruleData === "selection") {
            console.log({ groups: filterData.departmentIdList, users: filterData.userIdList })
            const selection = { groups: filterData.departmentIdList, users: filterData.userIdList }
            return JSON.stringify(selection)
        }
    }


    const isRuleName = () => {
        const isMatch = list.some((e) => e.ruleSetId === selectedId);

        if (isMatch) {
            return true
        } else {
            return false
        }
    }

    const fetchRule = async () => {
        setLoader(true)
        const isMatchRule = isRuleName()
        try {
            const resRule = await RulesById(selectedId)
            if (resRule.status === 200) {
                setRuleDetails({
                    name: isMatchRule ? resRule.data.ruleSetName : "Custom",
                    selection: {
                        groups: resRule.data.ruleSetName === "Default" ? "All" : resRule.data.departmentList.map((item) => item.departmentName),
                        users: resRule.data.ruleSetName === "Default" ? "All" : resRule.data.userList.map((item) => item.userName)
                    }
                })
                setLoader(false)
            }
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        fetchRule()
    }, [selectedId])



    return (
        <>
            {
                !loader && <HtmlTooltip
                    title={
                        <Typography color="inherit">
                            <span>
                                Rule Name: {rulesDetail?.name}
                                <br />
                                Group :
                                {rulesDetail?.name !== "Default" ? rulesDetail?.selection.groups.map((el) => (
                                    <>{el}, </>
                                )) : <>All</>}
                                <br />
                                User :
                                {
                                    rulesDetail?.name !== "Default" ? rulesDetail?.selection.users.map((el) => (
                                        <>{el}, </>
                                    )) : <>All</>}
                            </span>
                        </Typography>
                    }
                >
                    <InfoIcon
                        className={`${type === "add" ? "access-rule-info-icon" : "access-rule-info-icon-update"}`}
                        data-for="accessRuleTooltip"
                    />
                </HtmlTooltip>
            }

        </>
    )
}

export default RulesToolTip