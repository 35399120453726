import React from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

const LogoutButton = ({ isLogout }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  const handleLogout = () => {
    if (userType !== "DrayTalk-DrayTab") {
      const currentAccount = instance.getAccountByHomeId(
        "d48a4204-6e51-43f8-a445-f33f4073bb77"
      );
      instance
        .logoutRedirect({
          onRedirectNavigate: () => {
            // Return false to stop navigation after local logout
            return false;
          },
        })
        .then(() => {
          // Clear local storage and cookies
          localStorage.clear();
          navigate("/logout")
          // After clearing storage and cookies, navigate to the front-channel logout URL
          // window.location.href = "https://draytrace-ui.azurewebsites.net/";
        })
        .catch((error) => {
          console.error("Logout error:", error);
        });
    } else {
      localStorage.removeItem("accessToken");
      navigate("/draytab/logout"); // Ensure navigation is done here
    }
  };

  // Call handleLogout when islogout changes
  React.useEffect(() => {
    if (isLogout) {
      handleLogout();
    }
  }, [isLogout]);

  return <div>Logout</div>;
};

export default LogoutButton;
