import React, { useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import RequiredAstrict from '../UI/RequiredAstrict'
import { Col } from 'react-bootstrap'
import ThemeBtn from '../UI/ThemeBtn'
import { CreateOrganization } from '../../axios/apis'
import { successNotification, unexpectedErrorNotification } from '../../helpers'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'

const AddOrganization = ({ open, onClose, title, btn, fetchDetails }) => {

    const [submitloader, setSubmitLoader] = useState(false);
    const [orgName, setOrgname] = useState('')

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);


    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true);
        try {
            const response = await CreateOrganization({ organizationName: orgName })
            if (response.status === 200) {
                setSubmitLoader(false);
                successNotification({
                    message: "Organization is Created Successfully",
                    addNotifications,
                });
                onClose()
                fetchDetails("");
            }
        } catch (error) {
            unexpectedErrorNotification(error.response.data || "Organization Not Created Successfully", addNotifications);
            setSubmitLoader(false);
        }
    }

    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>
                <Col lg={12}>
                    <div className="input-box">
                        <label className="input-label" htmlFor="">
                            Organization Name <RequiredAstrict />
                        </label>
                        <div className="input-field parent-custom">
                            <input value={orgName} onChange={(e) => setOrgname(e.target.value)} type="text" />
                        </div>
                    </div>
                </Col>

                <div className="action-btn">
                    <button className="cancel-btn" onClick={onClose}>
                        Cancel
                    </button>
                    <ThemeBtn name={"Submit"} loader={submitloader} type="submit" />
                </div>
            </PopupLayout>
        </>
    )
}

export default AddOrganization