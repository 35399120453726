import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchContext from "../../../context/SearchContext";
import AccessContext from "../../../context/AccessContext";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';


const Tree = ({ data = [] }) => {
  return (
    <div className="">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((item, index) => (
          <TreeNode node={item} parentIndex={index} totalNodes={data.length} />
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, parentIndex, totalNodes }) => {
  const [childVisible, setChildVisible] = useState(false);

  const hasChild = node.children ? true : false;

  const { searchText, searchEmail, setSearchText, findList } = useContext(SearchContext);
  const { setSingleChart, setSingleUser, setIsAddContact } = useContext(AccessContext);

  const includesSearchTerm = (text) =>
    text?.toLowerCase() === searchText?.toLowerCase();

  useEffect(() => {
    findList.map((name) => {
      if (name === node.department) {
        setChildVisible(true);
      }
    });
  }, [searchText, searchEmail, findList, node]);

  useEffect(() => {
    if (node.treeLevel === 1) setChildVisible(true);
    // node.userContactList.map((el) => {
    //   if (el.parentId === 0) setChildVisible(true);
    // });
  }, []);

  const handleClick = (name, email) => {
    setSearchText(name)
    setSingleChart(email)
  }

  const handleAdd = (item) => {
    setSingleUser(item)
    setIsAddContact(true)
  }

  return (
    <>
      <li className="d-tree-node border-0">
        <div
          className="d-flex align-items-center"
          onClick={() => setChildVisible(!childVisible)}
        >
          {hasChild && (
            <>
              {childVisible ? (
                <RemoveIcon className="d-tree-icon" />
              ) : (
                <AddIcon className="d-tree-icon" />
              )}
            </>
          )}

          <div>
            <h4 className={`depart-head ${includesSearchTerm(node.department) && "highlight"}`}>
              {node.department}
            </h4>
          </div>
        </div>



        {hasChild && childVisible && (
          <div className="d-tree-content">
            <div>
              <ul className="d-tree-container">
                {node.userContactList.map((item, index) => (
                  <>
                    <li className={`d-tree-people`}>
                      <div className="d-people-node">
                        <span onClick={() => handleClick(item.userName, item.userEmailId)}
                          className={`people-head ${item.userEmailId === searchEmail && "highlight"}`}>
                          {item.userName}
                        </span>
                        {
                          searchEmail && searchEmail !== item.userEmailId && <div onClick={() => handleAdd(item)} className='request-icon'>
                            <AddCircleOutlineOutlinedIcon />
                          </div>
                        }
                      </div>
                    </li>
                    {(index === node.userContactList.length - 1 && node.children.length !== 0) && <hr className="m-0" />}
                  </>
                ))}
              </ul>
            </div>
            <ul className="d-flex d-tree-container flex-column">
              <Tree data={node.children} />
            </ul>
          </div>
        )}
      </li>

      {parentIndex !== totalNodes - 1 && <hr className="m-0" />}

    </>
  );
};

export default Tree;
