import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { setFcmToken } from "./firebase-config";
import icon from "../src/assets/logo-new.png";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCDlEusBHtycjWYZ-jW-srdNQGRjbx8Auk",
  authDomain: "twilio-notification-47e5a.firebaseapp.com",
  projectId: "twilio-notification-47e5a",
  storageBucket: "twilio-notification-47e5a.appspot.com",
  messagingSenderId: "673323869380",
  appId: "1:673323869380:web:62af8aad328cc984855591",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firestore = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const messaging = getMessaging(firebaseApp);

export const initializeFCM = async () => {
  try {
    if (localStorage.getItem("isWebView") == "true") {
      console.log("The app is running inside a WebView.");
      // Sign in the user anonymously when Firebase is initialized
      auth
        .signInAnonymously()
        .then(() => {
          console.log("User signed in anonymously");
        })
        .catch((error) => {
          console.error("Error signing in anonymously:", error);
        });
    } else {
      console.log("The app is not running inside a WebView.");
      // Register Service Worker
      const serviceWorkerRegistration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );

      const currentToken = await getToken(messaging, {
        vapidKey:
          "BAsYvLzhGkoToj7BGgAd3z_MmJ8o4SGWY0Zeo7Ry4TX0RM4t5anaftBuly1Rc1U63ahRA379yfFbFU_TwDSi84s",
        serviceWorkerRegistration,
      });

      if (currentToken) {
        localStorage.setItem("fcmToken", currentToken);
        setFcmToken(currentToken);
        console.log("FCM Token: ", currentToken);
      } else {
        location.reload();
      }
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

export default firebaseApp;
